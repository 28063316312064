import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { Case } from '../classes/Case';

@Injectable({
  providedIn: 'root',
})
export class CaseService {
  constructor(private _api: ApiService, private _http: HttpClient) {}

  get(caseId: string) {
    return this._http.get<Case>(this._api.getUri('cases/' + caseId));
  }

  list(archived: boolean = false) {
    return this._http.get<Case[]>(this._api.getUri(`cases`), {
      params: { archived },
    });
  }

  add(newCase: Case): Observable<Case> {
    return this._http
      .post<{ acknowledged: boolean; insertedId?: string }>(
        this._api.getUri('cases'),
        newCase
      )
      .pipe(
        map((response) => {
          const addedCase = new Case(
            newCase.name,
            newCase.code,
            response.insertedId
          );
          addedCase.owner_id = newCase.owner_id;
          return addedCase;
        })
      );
  }

  archive(caseId: string, archive: boolean) {
    const partial = { archived: archive };
    return this._http.post(this._api.getUri(`cases/${caseId}`), {
      $set: partial,
    });
  }

  update(caseId: string, partial: Partial<Case>) {
    return this._http.post(this._api.getUri(`cases/${caseId}`), {
      $set: partial,
    });
  }
}
