import { Pipe, PipeTransform } from '@angular/core';
import { Media, MediaType } from '../classes/Media';

@Pipe({
  name: 'mediaSearch',
})
export class MediaSearchPipe implements PipeTransform {
  transform(value: Media<MediaType>[], search: string): Media<MediaType>[] {
    const result = search.length
      ? value.filter((v) =>
          v.name?.toLowerCase().includes(search.toLowerCase())
        )
      : value;

    return result;
  }
}
