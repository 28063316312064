<div class="properties-panel" *ngIf="media">
  <div class="heading">
    {{ media.name }}
    <div class="close-button">
      <fa-icon
        (click)="closePanel()"
        [icon]="faX"
        size="lg"
        tippy="Close"
        placement="top"
        [delay]="[500, null]"
      ></fa-icon>
    </div>
  </div>
  <div class="tabs">
    <div
      class="tab"
      [ngClass]="{
        'current-tab': tab === 'project'
      }"
      (click)="handleTabClick('project', null)"
    >
      Project Info
    </div>
    <div
      class="tab"
      [ngClass]="{
        'current-tab': tab === segment.label
      }"
      *ngFor="let segment of media.metadata; let i = index"
      (click)="handleTabClick(segment.label, i)"
    >
      <span *ngIf="segment.label">
        {{ segment.label[0].toUpperCase() + segment.label.slice(1) }}
      </span>
    </div>
  </div>
  <div class="content" *ngIf="this.tab === 'project'">
    <div class="thumbnail">
      <a
        [routerLink]="['../', media.type, media.uuid]"
        [queryParams]="{ project: true }"
        routerLinkActive="active"
      >
        <img [src]="thumbnailUrl" alt="Media Thumbnail" />
      </a>
    </div>
    <p>Media Type: {{ media.type[0].toUpperCase() + media.type.slice(1) }}</p>
    <hr />
    <p>Media Caption:</p>
    <p
      id="caption-{{ media.uuid }}"
      class="caption"
      contenteditable="true"
      (input)="updateCaption($event)"
      (blur)="editCaption()"
    >
      {{ media.caption }}
    </p>
    <div class="map">
      <app-map
        *ngIf="media.geolocation"
        width="300px"
        height="200px"
        [links]="mapMarkers"
        [inset]="true"
      ></app-map>
    </div>
    <div class="media-info">
      <div class="geolocation">
        <p *ngIf="!media.geolocation">
          Show on Map:
          <label
            class="switch"
            tippy="Not Geolocated"
            placement="top"
            [delay]="[500, null]"
          >
            <span class="slider inactive round"></span>
          </label>
        </p>
        <p *ngIf="media.geolocation">
          Show on Map:
          <label
            class="switch"
            [tippy]="showOnMap ? 'Remove from map' : 'Show on map'"
            placement="top"
            [delay]="[500, null]"
          >
            <input
              type="checkbox"
              [(ngModel)]="showOnMap"
              (change)="updatShowOnMap()"
            />
            <span class="slider round"></span>
          </label>
        </p>
        <p *ngIf="media.manual_date">
          Manual Date: {{ media.manual_date | date : 'MMM d, y - HH:mm' }}
        </p>
        <p *ngIf="media.created_at">
          Created On: {{ media.created_at | date : 'MMM d, y - HH:mm' }}
        </p>
        <p *ngIf="media.uploaded_at">
          Uploaded On: {{ media.uploaded_at | date : 'MMM d, y - HH:mm' }}
        </p>
        <p *ngIf="media.modified_at">
          Modified On: {{ media.modified_at | date : 'MMM d, y - HH:mm' }}
        </p>
      </div>
    </div>
  </div>
  <div class="metadata" *ngIf="media.metadata && tabIndex !== null">
    <div class="segment">
      <p *ngFor="let item of media.metadata[tabIndex].values">
        {{ item.label }}: {{ item.data }}
      </p>
    </div>
  </div>
</div>
