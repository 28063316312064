<div class="form-section">
  <p>Select a file</p>
  <input
    type="file"
    accept="application/pdf"
    name="photos"
    id="photos"
    required="required"
    (change)="fileChanged($event)"
  />
  <div
    class="current-file"
    *ngIf="this.currentFiles.length && !this.files.length"
  >
    <p>Current pdf: - {{ this.currentFiles[0] }}</p>
  </div>
  <div class="preview-images">
    <div class="caption">
      <p><label for="caption">Caption</label></p>
      <input
        type="text"
        name="caption"
        id="caption"
        placeholder="Caption"
        [(ngModel)]="caption"
        (change)="onCaptionChange()"
        maxlength="30"
      />
      <p class="error" *ngIf="caption.length === 30">Maximum 30 characters</p>
    </div>
  </div>
</div>
