import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  faImage,
  faVideo,
  faMap,
  faFilePdf,
  faFile,
  faEarth,
  faXmark,
  faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import { MediaType } from 'src/app/classes/Media';
import { StoryService } from '../story.service';

@Component({
  selector: 'app-story-view',
  templateUrl: './story-view.component.html',
  styleUrls: ['./story-view.component.scss', '../story.component.scss'],
})
export class StoryViewComponent implements OnInit, AfterViewInit {
  @ViewChild('PanelList') panelList?: ElementRef<HTMLDivElement>;
  @Output() togglePanel: EventEmitter<void> = new EventEmitter<void>();

  faXmark = faXmark;
  faArrowLeft = faArrowLeft;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    public _stories: StoryService
  ) {}

  ngOnInit(): void {
    this._stories.changeViewPanelIdx.subscribe((panelIdx) => {
      this.setActivePanel(panelIdx);
    });
  }

  ngAfterViewInit() {
    if (this._stories.viewPanelIdx !== null) this.scrollToPanel();
  }
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (this._stories.viewPanelIdx === null) return;
    switch (event.key) {
      case 'ArrowDown':
      case 'ArrowRight':
        this._stories.increaseViewPanelIdx();
        break;
      case 'ArrowLeft':
      case 'ArrowUp':
        this._stories.decreaseViewPanelIdx();
        break;
    }
  }

  setActivePanel(panelIdx: number) {
    if (this._stories.storyComponentMode.secondary === 'view-list') return;
    this._router.navigate(
      [this._stories.selectedStory?.panels[panelIdx].mediaUrl],
      {
        relativeTo: this._route,
        queryParams: this._stories.selectedStory?.panels[panelIdx].queryParams,
      }
    );
    this._stories.viewPanelIdx = panelIdx;
    // The panel needs to expand before scrolling, otherwise panel ends up cut off
    setTimeout(() => {
      this.scrollToPanel();
    }, 200);
  }

  scrollToPanel() {
    //TODO: fix error where story-panel-active not applied to first element
    if (this._stories.viewPanelIdx === null) return;
    const el = this.panelList?.nativeElement.getElementsByClassName(
      'story-panel'
    )[this._stories.viewPanelIdx] as HTMLDivElement;
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  openActiveStory(activeStoryId: string) {
    this._stories.viewPanelIdx = null;
    this._stories.selectedStory =
      this._stories.stories?.find((story) => story.storyId === activeStoryId) ??
      undefined;
    if (this._stories.selectedStory) {
      this._stories.storyComponentMode.secondary = 'view-active';
    }
  }
  closeActiveStory() {
    this._stories.storyComponentMode.secondary = 'view-list';
  }
  openAddNewStory() {
    this._stories.storyComponentMode.primary = 'edit';
    this._stories.setStoryEditMode('edit-new-story');
  }

  getPanelIcon(mediaUrl: string) {
    const mediaType = mediaUrl.split('/')[0] as MediaType | 'map';

    switch (mediaType) {
      case 'image':
        return faImage;
      case 'video':
        return faVideo;
      case 'map':
        return faMap;
      case 'pdf':
        return faFilePdf;
      case 'panorama':
        return faEarth;
      default:
        return faFile;
    }
  }
  showMedia() {
    this.togglePanel.emit();
  }
}
