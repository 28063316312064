import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Project } from 'src/app/classes/Project';
import { ProjectService } from '../project.service';
import {
  faTrashCan,
  faBoxArchive,
  faBoxOpen,
  faPencil,
  faCopy,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-project-list-item',
  templateUrl: './project-list-item.component.html',
  styleUrls: ['./project-list-item.component.scss'],
})
export class ProjectListItemComponent {
  @Input() project?: Project;
  @Output() deleteProjectEvent = new EventEmitter<string>();
  @Output() archiveProjectEvent = new EventEmitter<string>();
  @Output() restoreProjectEvent = new EventEmitter<string>();
  @Output() editProjectEvent = new EventEmitter<Project>();
  @Output() copiedProjectEvent = new EventEmitter<Project>();
  duplicateProject?: Project;
  showModal: boolean = false;
  showEditModal: boolean = false;
  showCopyModal: boolean = false;
  newName: string = '';
  faTrashCan = faTrashCan;
  faBoxArchive = faBoxArchive;
  faBoxOpen = faBoxOpen;
  faPencil = faPencil;
  faCopy = faCopy;

  constructor(private _project: ProjectService) {}

  openModal() {
    this.showModal = true;
  }
  cancel() {
    this.showModal = false;
    this.showEditModal = false;
    this.showCopyModal = false;
    this.newName = '';
    delete this.duplicateProject;
  }

  openEditModal() {
    this.showEditModal = true;
  }

  openCopyModal() {
    this.duplicateProject = JSON.parse(JSON.stringify(this.project));
    if (this.duplicateProject) {
      this.duplicateProject.name = '';
      this.duplicateProject.owner_id = '';
      this.duplicateProject.shared = null;
      this.showCopyModal = true;
    }
  }

  deleteItem(id: string | undefined) {
    if (id)
      this._project.delete(id).subscribe(() => {
        this.deleteProjectEvent.emit(id);
      });
    this.showModal = false;
  }

  archiveItem(id: string | undefined) {
    if (id)
      this._project.archive(id, true).subscribe(() => {
        this.archiveProjectEvent.emit(id);
      });
    this.showModal = false;
  }

  restoreItem(id: string | undefined) {
    if (id)
      this._project.archive(id, false).subscribe(() => {
        this.restoreProjectEvent.emit(id);
      });
    this.showModal = false;
  }

  editProject(project: Project) {
    const partial = {
      name: project.name,
      owner_id: project.owner_id,
    };

    if (project._id) {
      this._project.update(project._id, partial).subscribe(() => {
        this.editProjectEvent.emit(project);
      });
    }
    this.cancel();
  }

  copyProject(project: Project) {
    this._project.copyProject(project).subscribe((res) => {
      console.log(res);
      console.log(res.insertedId);
      project._id = res.insertedId;
      this.copiedProjectEvent.emit(project);
    });
    this.cancel();
  }
}
