import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Project } from 'src/app/classes/Project';
import { ProjectService } from 'src/app/projects/project.service';

@Component({
  selector: 'app-project-view-image',
  templateUrl: './project-view-image.component.html',
  styleUrls: ['./project-view-image.component.scss'],
})
export class ProjectViewImageComponent implements OnInit {
  project?: Project;
  dataUrl: string = '';
  mediaId: string = '';
  constructor(
    private _project: ProjectService,
    private _route: ActivatedRoute
  ) {}

  async ngOnInit(): Promise<void> {
    this._route.params.subscribe(async (params) => {
      this.mediaId = params['mediaId'];
      this.setDataUrl();
    });
    if (this._project.currentProject) this.setDataUrl();
  }

  async setDataUrl(): Promise<void> {
    if (this._project.currentProject) {
      const url =
        this._project.currentProject.media.find(
          (item) => item.uuid === this.mediaId
        )?.file?.url || '';
      this.dataUrl = await this._project.getMediaUrl(
        this._project.currentProject,
        url
      );
    }
  }
}
