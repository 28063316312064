import { HttpClient } from '@angular/common/http';
import { ErrorHandler, EventEmitter, Injectable } from '@angular/core';
import { map } from 'rxjs';
import { ApiService } from '../api.service';
import { AuthLogin } from '../classes/AuthLogin';
import * as Sentry from '@sentry/angular';
import { MatomoTracker } from '@ngx-matomo/tracker';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  role: string = '';
  name: string = '';
  jwt: string = '';
  email: string = '';
  loggedIn: EventEmitter<boolean> = new EventEmitter(true);
  storage: Storage = sessionStorage;

  constructor(
    private _api: ApiService,
    private _http: HttpClient,
    private _err: ErrorHandler,
    private _tracker: MatomoTracker
  ) {
    if (localStorage.getItem('jwt')) {
      this.setUser(localStorage);
      this.storage = localStorage;
    } else if (sessionStorage.getItem('jwt')) {
      this.setUser(sessionStorage);
    }
  }

  public verifyEmail(email: string, remember: boolean, redirect: string) {
    return this._http.post<void>(this._api.getUri('auth'), {
      email,
      remember,
      redirect,
    });
  }

  public login(token: string) {
    return this._http
      .post<AuthLogin>(this._api.getUri('auth/login'), { token })
      .pipe(
        map((authUser) => {
          this.saveUserToStorage(authUser);
        })
      );
  }

  public saveUserToStorage(authUser: AuthLogin) {
    // const storage = authUser.remember ? localStorage : sessionStorage;
    const storage = localStorage;
    this.storage = storage;
    storage.setItem('role', authUser.role);
    storage.setItem('name', authUser.name);
    storage.setItem('jwt', authUser.jwt);
    storage.setItem('email', authUser.email);
    this.setUser(storage);
  }

  public logout(): void {
    this.removeUserFromStorage(localStorage);
    this.removeUserFromStorage(sessionStorage);
    this.jwt = '';
    this.name = '';
    this.email = '';
    this.role = '';
    this.loggedIn.emit(false);
  }

  private removeUserFromStorage(storage: Storage): void {
    storage.removeItem('role');
    storage.removeItem('name');
    storage.removeItem('jwt');
    storage.removeItem('email');
  }

  private setUser(storage: Storage) {
    this.role = String(storage.getItem('role'));
    this.name = String(storage.getItem('name'));
    this.jwt = String(storage.getItem('jwt'));
    this.email = String(storage.getItem('email'));
    Sentry.setUser({
      email: this.email,
      username: this.name,
    });

    this._tracker.setUserId(this.email);
    this.loggedIn.emit(true);
  }
}
