import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Feature, FeatureCollection, Point } from '@turf/helpers';
import { Extent } from 'ol/extent';
import { Project } from 'src/app/classes/Project';
import { ProjectService } from '../project.service';

@Component({
  selector: 'app-project-view-map',
  templateUrl: './project-view-map.component.html',
  styleUrls: ['./project-view-map.component.scss'],
})
export class ProjectViewMapComponent implements OnInit {
  project?: Project;
  links: Array<{
    text: string;
    icon: string;
    uuid: string;
    url: string;
    feature: Feature<Point>;
  }> = [];
  geojsonFeatures: Array<{
    name: string;
    visible: boolean;
    featureCollection: FeatureCollection;
  }> = [];
  highlight?: string;

  constructor(
    private _project: ProjectService,
    private _route: ActivatedRoute,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this._route.parent?.paramMap.subscribe(async (params) => {
      const projectId = params.get('projectId');
      if (this._project.currentProject?._id === projectId) {
        this.project = this._project.currentProject;
        this.setMarkers(this.project);
        this.geojsonFeatures = this.project.map_features || [];
      } else {
        this._project.get(projectId || '').subscribe((project) => {
          this.project = project;
          this.setMarkers(project);
          this.geojsonFeatures = project.map_features || [];
        });
      }
    });

    this._route.queryParamMap.subscribe((qParams) => {
      this.highlight = qParams.get('highlight') ?? '';
    });
  }

  setMarkers(project: Project): void {
    this.links = this._project.generateMapMarkers(project);
  }

  mapMove(extent: Extent): void {
    this._router.navigate(['.'], {
      queryParams: { extent: JSON.stringify(extent) },
      queryParamsHandling: 'merge',
      relativeTo: this._route,
    });
  }
}
