<app-modal *ngIf="showModal && !case?.archived" heading="Confirm archive case?">
  <div class="question">
    <p>
      Are you sure you want to archive <b>{{ case?.name }}</b
      >?
    </p>
  </div>
  <div class="actions">
    <button type="button" (click)="cancel()">Cancel</button>
    <button type="button" (click)="archiveItem(case?._id)">Archive</button>
  </div>
</app-modal>
<app-modal *ngIf="showModal && case?.archived" heading="Confirm restore case?">
  <div class="question">
    <p>
      Are you sure you want to restore <b>{{ case?.name }}</b
      >?
    </p>
  </div>
  <div class="actions">
    <button type="button" (click)="cancel()">Cancel</button>
    <button type="button" (click)="restoreItem(case?._id)">Restore</button>
  </div>
</app-modal>
<app-modal [(show)]="showEditModal" *ngIf="showEditModal" heading="Edit Case">
  <app-case-form
    (caseAdded)="editCase($event)"
    (cancel)="cancel()"
    [case]="case"
  ></app-case-form>
</app-modal>
<div class="list-item">
  <div class="name">
    <a
      *ngIf="case"
      [routerLink]="case._id"
      [ngClass]="{ archived: case.archived }"
    >
      <div class="case-item">
        <div class="case-number">
          <b>{{ case.code }}</b>
        </div>
        <div class="case-name">
          {{ case.name }}<span *ngIf="case.archived"> - (archived)</span>
        </div>
      </div>
    </a>
  </div>
  <div class="buttons">
    <div
      class="rename"
      (click)="showEditModal = true"
      *ngIf="!case?.archived"
      tippy="Edit name"
      placement="left"
      [delay]="[500, null]"
    >
      <div><fa-icon [icon]="faPencil"></fa-icon></div>
    </div>
    <div
      class="archive"
      (click)="openModal()"
      *ngIf="!case?.archived"
      tippy="Archive"
      placement="right"
      [delay]="[500, null]"
    >
      <div><fa-icon [icon]="faBoxArchive"></fa-icon></div>
    </div>
    <div
      class="archive"
      (click)="openModal()"
      *ngIf="case?.archived"
      tippy="Restore"
      placement="right"
      [delay]="[500, null]"
    >
      <div><fa-icon [icon]="faBoxOpen"></fa-icon></div>
    </div>
  </div>
</div>
