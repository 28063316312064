import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-editor-window-text',
  templateUrl: './editor-window-text.component.html',
  styleUrls: ['./editor-window-text.component.scss'],
})
export class EditorWindowTextComponent {
  @Output() newCaptionEvent = new EventEmitter<string>();
  @Output() newContentEvent = new EventEmitter<string>();
  @Input() caption: string = '';
  @Input() content: string = '';

  onCaptionChange() {
    return this.newCaptionEvent.emit(this.caption);
  }

  onContentChange() {
    return this.newContentEvent.emit(this.content);
  }
}
