<div
  id="card-{{ media.uuid }}"
  *ngIf="media"
  (mouseup)="onMouseUp($event)"
  (mousedown)="onMouseDown($event)"
>
  <div class="thumbnail">
    <div class="select">
      <input
        type="checkbox"
        id="{{ media.uuid }}"
        [(ngModel)]="selected"
        (change)="handleSelection()"
        onmousedown="event.stopPropagation()"
      />
    </div>
    <img [src]="thumbnailUrl" alt="Media Thumbnail" />
  </div>
  <div class="info">
    <div
      class="textbox"
      [ngClass]="{
        'display-properties-panel': propertiesPanelId === media.uuid
      }"
      (keydown)="handleKeydown($event)"
      (focus)="setName()"
      (input)="updateName($event)"
      (blur)="edit()"
      id="id-{{ media.uuid }}"
    >
      {{ media.name }}
    </div>
    <p>Media Type: {{ media.type[0].toUpperCase() + media.type.slice(1) }}</p>
    <div class="geolocation">
      <p *ngIf="!media.geolocation">
        Show on Map:
        <label
          class="switch"
          tippy="Not Geolocated"
          placement="top"
          [delay]="[500, null]"
        >
          <span class="slider inactive round"></span>
        </label>
      </p>
      <p *ngIf="media.geolocation">
        Show on Map:
        <label
          class="switch"
          [tippy]="showOnMap ? 'Remove from map' : 'Show on map'"
          placement="top"
          [delay]="[500, null]"
        >
          <input
            onmousedown="event.stopPropagation()"
            type="checkbox"
            [(ngModel)]="showOnMap"
            (change)="updatShowOnMap()"
          />
          <span
            class="slider round"
            onmousedown="event.stopPropagation()"
          ></span>
        </label>
      </p>
    </div>
    <div *ngIf="!editDateTime">
      <p *ngIf="media.manual_date">
        {{ media.manual_date | date : 'MMM d, y - HH:mm' }}
      </p>
      <p *ngIf="media.created_at && !media.manual_date">
        {{ media.created_at | date : 'MMM d, y - HH:mm' }}
      </p>
      <p *ngIf="!media.created_at && !media.manual_date">No date or time</p>
    </div>
    <input
      *ngIf="editDateTime"
      class="datetime"
      autofocus
      id="date-time-{{ media.uuid }}"
      type="datetime-local"
      [(ngModel)]="updatedDateTime"
      (blur)="updateDate()"
    />
  </div>
</div>
<app-context-menu
  *ngIf="displayContextMenu"
  type="galleryGridView"
  (contextMenuItemClick)="handleMenuItemClick($event)"
  [ngStyle]="getRightClickMenuStyle()"
  [allowGeolocation]="!media?.geolocation"
></app-context-menu>

<app-modal-geolocation
  *ngIf="showGeolocationModal"
  [(show)]="showGeolocationModal"
  [media]="media"
  (mediaChange)="updateMedia()"
></app-modal-geolocation>
