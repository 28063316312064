<!-- no stories available-->
<div
  *ngIf="
    _stories.storyComponentMode.primary === 'edit' &&
    _stories.stories &&
    _stories.stories.length <= 0
  "
  style="margin: 2em auto"
>
  <p>No stories available</p>
</div>

<!-- story selector -->
<div
  *ngIf="_stories.storyComponentMode.secondary === 'edit-list'"
  class="flex-row"
  style="width: 100%; margin: 0; align-items: center"
>
  <select
    class="story-select"
    [disabled]="!_stories.stories || _stories.stories.length < 1"
    *ngIf="
      _stories.stories &&
      _stories.stories.length > 0 &&
      _stories.storyComponentMode.primary === 'edit' &&
      _stories.storyComponentMode.secondary === 'edit-list'
    "
    name="storySelector"
    [ngModel]="_stories.selectedStoryId"
    (ngModelChange)="_stories.selectedStoryChange($event)"
  >
    <option *ngFor="let story of _stories.stories" [value]="story.storyId">
      {{ story.name }}
    </option>
  </select>
  <div
    class="flex-row"
    style="
      width: 20%;
      align-items: center;
      justify-content: flex-end;
      gap: 0.25em;
      transition: all 300ms ease-in-out;
    "
    *ngIf="_stories.storyComponentMode.primary === 'edit'"
  >
    <button tippy="Create Story" style="padding: 0.5em; margin: 0">
      <fa-icon
        [icon]="faPlus"
        (click)="_stories.setStoryEditMode('edit-new-story')"
      ></fa-icon>
    </button>
    <button tippy="Delete Story" style="padding: 0.5em; margin: 0">
      <fa-icon [icon]="faTrashCan" (click)="openConfirmDeleteStory()"></fa-icon>
    </button>
  </div>
</div>

<!-- confirm delete story -->
<div
  style="margin: 1em 0; width: 100%"
  class="flex-col"
  *ngIf="
    _stories.selectedStory &&
    _stories.storyComponentMode.secondary === 'edit-confirm-delete-story'
  "
>
  <p>
    Are you sure you want to delete the story:
    <span style="font-weight: bold">{{ _stories.selectedStory.name }}</span
    >?
  </p>
  <div class="flex-row">
    <button (click)="closeEditPanel()" class="bold-btn">Cancel</button>
    <button (click)="submitDeleteStory()" class="bold-btn">Delete</button>
  </div>
</div>

<!--add new story form-->
<form
  style="margin: 1em 0; width: 100%"
  class="flex-col"
  *ngIf="
    _stories.storyComponentMode.secondary === 'edit-new-story' && storyForm
  "
  [formGroup]="storyForm"
  (ngSubmit)="submitAddNewStory()"
>
  <h3 style="margin: 0; margin-top: 1em">Story Name</h3>
  <input type="text" placeholder="Story Name" formControlName="name" />
  <span
    *ngIf="storyFormName?.errors?.['required'] && storyFormName?.touched"
    class="error-message"
  >
    Name is required
  </span>
  <h3 style="margin: 0; margin-top: 1em">Story Description</h3>
  <textarea
    placeholder="Story Description"
    formControlName="description"
  ></textarea>
  <span
    *ngIf="storyFormDescription?.errors?.['required'] && storyFormDescription?.touched"
    class="error-message"
  >
    Description is required
  </span>

  <div
    class="flex-row"
    style="margin: 1em 0; width: 100%; justify-content: flex-end"
  >
    <button (click)="closeEditStory()" class="bold-btn">Cancel</button>
    <button
      [disabled]="storyForm.invalid"
      class="bold-btn"
      [style]="
        storyForm.invalid
          ? {
              transform: 'none',
              'background-color': 'var(--secondary-color-faded)'
            }
          : ''
      "
      type="submit"
    >
      Add new story
    </button>
  </div>
</form>

<!-- story details -->
<div
  *ngIf="
    _stories.storyComponentMode.secondary === 'edit-list' &&
    _stories.selectedStory
  "
  style="
    padding-bottom: 1em;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  "
  class="flex-row"
>
  <div class="flex-col">
    <h2>{{ _stories.selectedStory.name }}</h2>
    <p>{{ _stories.selectedStory.description }}</p>
  </div>
  <button
    tippy="Edit story details"
    style="padding: 0.5em; margin: 0"
    (click)="openEditSelectedStory()"
  >
    <fa-icon [icon]="faPencil"></fa-icon>
  </button>
</div>

<!-- panel editor -->
<form
  style="margin: 1em 0; width: 100%"
  class="flex-col"
  *ngIf="
    _stories.selectedStory &&
    _stories.storyComponentMode.secondary === 'edit-story-details' &&
    storyForm
  "
  [formGroup]="storyForm"
  (ngSubmit)="submitEditSelectedStory()"
>
  <h3 style="margin: 0; margin-top: 1em">Story Name</h3>
  <input
    formControlName="name"
    type="text"
    style="border-radius: 1em; margin: 0 1em"
  />
  <span
    *ngIf="storyFormName?.errors?.['required'] && storyFormName?.touched"
    class="error-message"
  >
    Name is required
  </span>
  <h3 style="margin: 0; margin-top: 1em">Story Description</h3>
  <textarea
    formControlName="description"
    style="border-radius: 1em; margin: 0 1em"
  ></textarea>
  <span
    *ngIf="storyFormDescription?.errors?.['required'] && storyFormDescription?.touched"
    class="error-message"
  >
    Description is required
  </span>
  <div
    class="flex-row"
    style="margin: 1em 0; width: 100%; justify-content: flex-end"
  >
    <button (click)="closeEditPanel()" class="bold-btn" type="button">
      Cancel
    </button>
    <button
      class="bold-btn"
      [disabled]="!storyForm.dirty || storyForm.invalid"
      [style]="
        !storyForm.dirty || storyForm.invalid
          ? {
              backgroundColor: 'var(--secondary-color-faded)',
              transform: 'none'
            }
          : {}
      "
      type="submit"
    >
      Apply Edit
    </button>
  </div>
</form>

<!-- panel list -->
<div
  class="panel-list"
  *ngIf="
    _stories.storyComponentMode.secondary === 'edit-list' &&
    _stories.selectedStory &&
    _stories.selectedStory.panels.length > 0
  "
>
  <div
    *ngFor="let panel of _stories.selectedStory.panels; let i = index"
    class="story-panel"
    [ngClass]="_stories.editPanelIdx === i ? 'story-panel-active' : ''"
  >
    <div
      class="flex-row"
      style="
        align-items: center;
        justify-content: space-between;
        padding: 0 0.5em;
      "
    >
      <div class="flex-row" style="gap: 0.5em; align-items: center">
        <fa-icon
          [icon]="faChevronRight"
          *ngIf="_stories.editPanelIdx !== i"
          (click)="setExpandedPanel(i)"
          tippy="Show Panel Content"
        ></fa-icon>
        <fa-icon
          [icon]="faChevronDown"
          *ngIf="_stories.editPanelIdx === i"
          (click)="setExpandedPanel(-1)"
          tippy="Close Panel Content"
        ></fa-icon>
        <a (click)="openPanel(i)"
          ><h3>{{ panel.heading }}</h3></a
        >
      </div>
      <div class="flex-row" style="gap: 1em; justify-content: flex-end">
        <button
          class="edit-card-btn"
          *ngIf="
            _stories.selectedStory &&
            _stories.selectedStory.panels &&
            i !== _stories.selectedStory.panels.length - 1
          "
          (click)="movePanelDown(i)"
          tippy="Move panel down"
        >
          <fa-icon [icon]="faArrowDown"></fa-icon>
        </button>
        <button
          class="edit-card-btn"
          *ngIf="i !== 0"
          (click)="movePanelUp(i)"
          tippy="Move panel up"
        >
          <fa-icon [icon]="faArrowUp"></fa-icon>
        </button>

        <button
          class="edit-card-btn"
          (click)="removePanel(i)"
          tippy="Remove panel"
        >
          <fa-icon [icon]="faTrashCan"></fa-icon>
        </button>
        <button
          class="edit-card-btn"
          (click)="openEditPanel(panel.panelId)"
          tippy="Edit panel"
        >
          <fa-icon [icon]="faPencil"></fa-icon>
        </button>
      </div>
    </div>
    <p *ngIf="_stories.editPanelIdx === i" class="panel-content">
      {{ panel.content }}
    </p>
  </div>
</div>

<!--panel list footer-->
<div
  class="panel-list-footer"
  *ngIf="_stories.storyComponentMode.secondary === 'edit-list'"
>
  <button
    (click)="_stories.setStoryEditMode('edit-add-panel')"
    class="bold-btn"
    style="margin-left: auto"
  >
    Add panel
  </button>
</div>

<!-- add new panel -->
<div *ngIf="_stories.storyComponentMode.secondary === 'edit-add-panel'">
  <h3>Add a new panel</h3>
  <form
    *ngIf="panelForm"
    class="flex-col"
    style="gap: 1em"
    [formGroup]="panelForm"
    (ngSubmit)="addNewPanel()"
  >
    <input placeholder="Heading" type="text" formControlName="heading" />
    <span
      *ngIf="panelFormHeading?.errors?.['required'] && panelFormHeading?.touched"
      class="error-message"
    >
      Heading is required
    </span>
    <textarea
      placeholder="Content"
      formControlName="content"
      rows="16"
    ></textarea>
    <span
      *ngIf="panelFormContent?.errors?.['required'] && panelFormContent?.touched"
      class="error-message"
    >
      Some content is required
    </span>
    <span
      *ngIf="panelFormContent?.errors?.['maxlength'] && panelFormContent?.touched"
      class="error-message"
    >
      Content is too long,
      {{
        panelFormContent?.errors?.['maxlength'].actualLength
      }}
      /
      {{
        panelFormContent?.errors?.['maxlength'].requiredLength
      }}
      chars.
    </span>
    <div class="flex-row" style="justify-content: space-between">
      <button (click)="setCurrentView()" class="bold-btn" type="button">
        Set current view
      </button>
      <fa-icon
        [icon]="faCheck"
        *ngIf="this.panelForm.get('mediaUrl')?.value"
      ></fa-icon>
    </div>
    <div class="flex-row" style="margin-left: auto">
      <button (click)="closeEditPanel()" class="bold-btn" type="button">
        Cancel
      </button>
      <button
        type="submit"
        class="bold-btn"
        [disabled]="panelForm.invalid || !panelForm.get('mediaUrl')?.value"
        [style]="
          panelForm.invalid || !panelForm.get('mediaUrl')?.value
            ? {
                transform: 'none',
                'background-color': 'var(--secondary-color-faded)'
              }
            : ''
        "
      >
        Add to story
      </button>
    </div>
  </form>
</div>

<!-- edit panel -->
<div *ngIf="_stories.storyComponentMode.secondary === 'edit-panel'">
  <form
    *ngIf="panelForm"
    class="flex-col"
    style="gap: 1em"
    (ngSubmit)="applyPanelEdit()"
    [formGroup]="panelForm"
  >
    <h2>Edit Panel</h2>
    <input placeholder="Heading" type="text" formControlName="heading" />
    <span
      *ngIf="panelFormHeading?.errors?.['required'] && panelFormHeading?.touched"
      class="error-message"
    >
      Heading is required
    </span>
    <textarea
      placeholder="Content"
      formControlName="content"
      rows="16"
    ></textarea>
    <span
      *ngIf="panelFormContent?.errors?.['required'] && panelFormContent?.touched"
      class="error-message"
    >
      Some content is required
    </span>
    <span *ngIf="panelFormContent?.errors?.['maxlength']" class="error-message">
      Content is too long,
      {{ 
        panelFormContent?.errors?.['maxlength'].actualLength
      }}
      /
      {{ 
        panelFormContent?.errors?.['maxlength'].requiredLength
      }}
      chars.
    </span>
    <div class="flexRow">
      <button (click)="setCurrentView()" class="bold-btn" type="button">
        Update current view
      </button>
      <fa-icon [icon]="faCheck" *ngIf="viewSet"></fa-icon>
    </div>

    <div class="flex-row">
      <button (click)="closeEditPanel()" class="bold-btn" type="button">
        Cancel
      </button>
      <button
        class="bold-btn"
        type="submit"
        [disabled]="!panelForm.dirty || panelForm.invalid"
        [style]="
          !panelForm.dirty || panelForm.invalid
            ? {
                backgroundColor: 'var(--secondary-color-faded)',
                transform: 'none'
              }
            : {}
        "
      >
        Apply Edit
      </button>
    </div>
  </form>
</div>
