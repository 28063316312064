import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProjectService } from 'src/app/projects/project.service';

@Component({
  selector: 'app-modal-markers-video',
  templateUrl: './modal-markers-video.component.html',
  styleUrls: ['./modal-markers-video.component.scss'],
})
export class ModalMarkersVideoComponent implements OnInit {
  @Input() heading: string = '';
  @Input() show: boolean = false;
  @Input() url: string = '';
  @Output() showChange: EventEmitter<boolean> = new EventEmitter();
  @Input() mediaId = '';
  dataUrl = '';

  constructor(private _project: ProjectService) {}

  async ngOnInit(): Promise<void> {
    if (this._project.currentProject) {
      this.dataUrl = await this._project.getMediaUrl(
        this._project.currentProject,
        this.url
      );
    }
  }

  closeModal() {
    this.showChange.emit(false);
  }
}
