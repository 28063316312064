import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { Project } from 'src/app/classes/Project';
import { ProjectService } from '../project.service';

@Component({
  selector: 'app-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.scss'],
})
export class ProjectFormComponent implements OnInit {
  @Input() caseId: string = '';
  @Input() project?: Project;
  @Output() projectAdded: EventEmitter<Project> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();
  projectName: string = '';
  projectOwner?: string;
  isAdmin: boolean = false;
  isUser: boolean = false;
  displayError: boolean = false;

  constructor(private _project: ProjectService, private _auth: AuthService) {}

  ngOnInit(): void {
    this.isAdmin = this._auth.role === 'admin';
    this.isUser = this._auth.role === 'user';
    if (this.project) {
      this.projectName = this.project.name;
      this.projectOwner = this.project.owner_id;
      this.caseId = this.project.case_id;
    }
  }

  saveProject() {
    if (this.project) {
      this.project.name = this.projectName;
      this.project.owner_id = this.projectOwner;
      this.projectAdded.emit(this.project);
    } else {
      if (this.projectName) {
        if (this.caseId) {
          this._project
            .add(this.caseId, this.projectName)
            .subscribe((addedProject) => {
              this.projectAdded.emit(addedProject);
              this.projectName = '';
            });
        }
      } else {
        this.displayError = true;
      }
    }
  }

  closeModal() {
    this.projectName = '';
    this.displayError = false;
    this.cancel.emit();
  }

  clearOwner() {
    this.projectOwner = '';
  }
}
