<ng-container *ngIf="user">
  <div class="user-row">
    <div class="user-type">
      <b *ngIf="user.role === 'admin'">Admin:</b>
      <b *ngIf="user.role === 'user'">User:</b>
    </div>
    <div class="user-name">
      {{ user.name }}
    </div>
    <div class="user-email">
      {{ user.email }}
    </div>
  </div>
</ng-container>
