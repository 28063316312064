import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';
import { Media, MediaType } from 'src/app/classes/Media';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-gallery-list',
  templateUrl: './gallery-list.component.html',
  styleUrls: ['./gallery-list.component.scss'],
})
export class GalleryListComponent implements OnInit {
  @Input() mediaList?: Media<MediaType>[] = [];
  @Output() mediaListChange: EventEmitter<Media<MediaType>[]> =
    new EventEmitter();
  @Input() selection: string[] = [];
  @Output() selectionChange = new EventEmitter<string[]>();
  @Output() propertiesPanelChange: EventEmitter<string> = new EventEmitter();
  @Input() propertiesPanelId?: string;
  @Input() searchText: string = '';
  @Input() sortParameters?: { sortBy: string; sortDescending: boolean };
  @Output() sortParametersChange: EventEmitter<{
    sortBy: string;
    sortDescending: boolean;
  }> = new EventEmitter();
  isEditingGridCard: boolean = false;
  editNextGridCard?: string;
  sortBy: string = '';
  sortDescending: boolean = false;
  faSortUp = faSortUp;
  faSortDown = faSortDown;

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEventkeydown(event: KeyboardEvent): void {
    if (this.propertiesPanelId && this.mediaList && !this.isEditingGridCard) {
      let currentTabIndex, nextEditIndex, element;
      switch (event.code) {
        case 'ArrowUp':
        case 'ArrowLeft':
          event.preventDefault();
          currentTabIndex = this.mediaList?.findIndex(
            (item) => item.uuid === this.propertiesPanelId
          );
          nextEditIndex =
            currentTabIndex === 0
              ? this.mediaList.length - 1
              : currentTabIndex - 1;
          this.propertiesPanelId = this.mediaList[nextEditIndex].uuid;
          this.propertiesPanelChange.emit(this.propertiesPanelId);
          element = document.getElementById(`row-${this.propertiesPanelId}`);
          if (element)
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'nearest',
            });
          break;
        case 'Tab':
        case 'ArrowRight':
        case 'ArrowDown':
          event.preventDefault();
          currentTabIndex = this.mediaList?.findIndex(
            (item) => item.uuid === this.propertiesPanelId
          );
          nextEditIndex =
            currentTabIndex === this.mediaList.length - 1
              ? 0
              : currentTabIndex + 1;
          this.propertiesPanelId = this.mediaList[nextEditIndex].uuid;
          this.propertiesPanelChange.emit(this.propertiesPanelId);
          element = document.getElementById(`row-${this.propertiesPanelId}`);
          if (element)
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'nearest',
            });
          break;
        default:
          break;
      }
    }
  }

  ngOnInit(): void {
    if (this.sortParameters) {
      this.sortBy = this.sortParameters.sortBy;
      this.sortDescending = this.sortParameters.sortDescending;
    }
  }

  updateSelected(uuid: string) {
    if (this.selection.includes(uuid)) {
      this.selection.splice(
        this.selection.findIndex((id) => id === uuid),
        1
      );
    } else {
      this.selection.push(uuid);
    }
    this.selectionChange.emit(this.selection);
  }

  setSort(sortBy: string): void {
    if (this.sortBy !== sortBy) this.sortDescending = false;
    this.sortBy = sortBy;
    this.sortDescending = !this.sortDescending;
    this.sortParametersChange.emit({
      sortBy: this.sortBy,
      sortDescending: this.sortDescending,
    });
  }

  updatePropertiesPanel(mediaId: string): void {
    this.propertiesPanelChange.emit(mediaId);
  }

  updateIsEditingGridCard(editing: boolean): void {
    this.isEditingGridCard = editing;
  }

  tabToEditNextGridCard(currentCardId: string): void {
    if (currentCardId === '') {
      this.editNextGridCard = '';
      return;
    }
    if (this.mediaList) {
      const currentEditIndex = this.mediaList?.findIndex(
        (item) => item.uuid === currentCardId
      );
      const nextEditIndex =
        currentEditIndex === this.mediaList.length - 1
          ? 0
          : currentEditIndex + 1;
      this.editNextGridCard = this.mediaList[nextEditIndex].uuid;
    }
  }
}
