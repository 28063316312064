<div class="header">
  <div class="navbar">
    <img
      src="../../../assets/logos/logo-full-trans-small.png"
      alt="Waves Group Logo"
    />
    <h2 *ngIf="!this.showProjectTitle" class="title">WAVEFRONT</h2>
    <h2 *ngIf="this.showProjectTitle">{{ this.projectName }}</h2>
    <app-header-menu></app-header-menu>
  </div>
</div>
