import { Component, EventEmitter, Output } from '@angular/core';
import {
  faInfo,
  faLayerGroup,
  faShareAlt,
  faUpload,
  faExpand,
  faBook,
  faMapLocation,
} from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-panorama-sidebar',
  templateUrl: './panorama-sidebar.component.html',
  styleUrls: ['./panorama-sidebar.component.scss'],
})
export class PanoramaSidebarComponent {
  @Output() clicked: EventEmitter<string> = new EventEmitter();
  faI = faInfo;
  faShare = faShareAlt;
  faLayerGroup = faLayerGroup;
  faMap = faMapLocation;
  faUpload = faUpload;
  faExpand = faExpand;
  faBook = faBook;
  readOnly: boolean = true;

  constructor(private _auth: AuthService) {
    this.readOnly = this._auth.role !== 'admin' && this._auth.role !== 'user';
  }

  clickEvent(type: string) {
    this.clicked.emit(type);
  }
}
