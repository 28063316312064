import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { environment } from 'src/environments/environment';
import Map from 'ol/Map';
import View from 'ol/View';
import OSM from 'ol/source/OSM';
import BingMaps from 'ol/source/BingMaps.js';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { toLonLat, transform } from 'ol/proj';
import {
  Feature as geojsonFeature,
  Point as geojsonPoint,
  FeatureCollection,
} from '@turf/helpers';
import GeoJSON from 'ol/format/GeoJSON';
import Icon from 'ol/style/Icon';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ScaleLine, Control, MousePosition } from 'ol/control.js';
import { MatomoTracker } from '@ngx-matomo/tracker';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProjectService } from 'src/app/projects/project.service';
import { Feature, Graticule, MapBrowserEvent, MapEvent, Overlay } from 'ol';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import TileSource from 'ol/source/Tile';
import { Stroke, Style } from 'ol/style';
import { getMediaById } from 'src/app/classes/Project';
import { Coordinate } from 'ol/coordinate';
import { Extent } from 'ol/extent';
import * as olExtent from 'ol/extent';
import { MapFeaturesService } from 'src/app/services/map-features.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() width: string = '100%';
  @Input() height: string = '300px';
  @Input() links: Array<{
    text: string;
    icon: string;
    url: string;
    uuid: string;
    feature: geojsonFeature<geojsonPoint>;
  }> = [];
  @Input() highlight?: string = '';
  @Input() inset: boolean = false;
  @Input() geojsonFeatures: Array<{
    name: string;
    visible: boolean;
    featureCollection: FeatureCollection;
  }> = [];
  @Output() mapMove: EventEmitter<Extent> = new EventEmitter();
  @ViewChild('map') mapElement?: ElementRef;
  @ViewChild('tooltipContainer') tooltipContainer?: ElementRef;
  tooltipContent?: string;
  displayContextMenu: boolean = false;
  showMapSettings: boolean = false;
  displayEditFeature: boolean = false;
  showGraticules: boolean = false;
  featureId: string = '';
  originalFeatures: Array<{
    name: string;
    visible: boolean;
    featureCollection: FeatureCollection;
  }> = [];
  selectedFeature?: Feature;
  contextMenuType: string = '';
  coordinateString: string = '';
  rightClickMenuPositionX: number = 0;
  rightClickMenuPositionY: number = 0;
  movingMarker: boolean = false;
  selectedMarkerIndex?: number;
  selectedMarkerName: string = '';
  newName: string = '';
  showEditMarkerModal: boolean = false;
  showDeleteMarkerModal: boolean = false;
  mapType: string = 'openStreetMap';
  drawType: string = 'Cancel';
  featureCollectionIndex: number = 0;
  showEditMapLayers: boolean = false;
  markerLayer: VectorLayer<VectorSource> = new VectorLayer();
  geoJsonLayer: VectorLayer<VectorSource> = new VectorLayer();
  geoJsonSource = new VectorSource();
  baseLayer: TileLayer<TileSource> = new TileLayer();
  bingLayer = new BingMaps({
    key: environment.BING_API_KEY,
    imagerySet: 'Aerial',
    maxZoom: 19,
  });
  osmLayer = new OSM();
  map?: Map;
  view: View = new View({
    center: [0, 0],
    zoom: 2,
  });
  faLayerGroup = faLayerGroup;

  constructor(
    private _project: ProjectService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _tracker: MatomoTracker,
    private _mapFeatures: MapFeaturesService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['links'] && !changes['links'].isFirstChange()) {
      this.setLinks();
    }
    if (
      changes['geojsonFeatures'] &&
      !changes['geojsonFeatures'].isFirstChange()
    ) {
      this.setGeoJson();
      this.addDragAndDrop();
    }

    if (changes['highlight'] && !changes['highlight'].isFirstChange) {
      this.showTooltip(changes['highlight'].currentValue);
    }
  }
  ngOnDestroy(): void {
    this.map?.dispose();
    delete this.map;
  }

  ngAfterViewInit(): void {
    this.map = new Map({ target: this.mapElement?.nativeElement });
    this.map.setView(this.view);
    if (this._route.snapshot.queryParamMap.has('extent')) {
      const extentArray: Extent = JSON.parse(
        this._route.snapshot.queryParamMap.get('extent') || '[0,0,0,0]'
      );
      const extent: Extent = olExtent.createOrUpdate(
        extentArray[0],
        extentArray[1],
        extentArray[2],
        extentArray[3]
      );
      this.view.fit(extent);
    }

    this._route.queryParamMap.subscribe((queryParamMap) => {
      this.onQueryParamUpdate(queryParamMap);
    });

    if (!this.inset) {
      this.map.addControl(this.scaleControl());
      this.map.addControl(this.mousePositionControl());
    }

    this.mapType = this._route.snapshot.queryParamMap.has('base')
      ? this._route.snapshot.queryParamMap.get('base') || ''
      : localStorage.getItem('mapType') || 'openStreetMap';

    this.map.addLayer(this.baseLayer);
    if (!this.inset) this.map.addLayer(this.geoJsonLayer);
    this.map.addLayer(this.markerLayer);
    this.changeMapLayer(this.mapType);

    this.map.on('click', (event) => {
      this.onMapClick(event);
    });

    this.map.on('loadend', () => {
      this.map?.on('moveend', (event: MapEvent) => {
        this.onMapMoveEnd(event);
      });
    });

    this.map.getViewport().addEventListener('contextmenu', (event) => {
      event.preventDefault();
      const pixel = [event.clientX - 60, event.clientY];
      const feature = this.map?.forEachFeatureAtPixel(
        pixel,
        function (feature) {
          return feature;
        }
      );
      if (!this.inset) {
        const position = this.map?.getEventCoordinate(event);
        if (position) {
          const coords = toLonLat(position, 'EPSG:3857');
          this.coordinateString = this.toStringHDM(coords);
        }
        if (feature && feature.getProperties()['uuid']) {
          this.contextMenuType = 'map-marker';
          this.displayContextMenu = true;
          const props = feature.getProperties();
          this.selectedMarkerIndex = this.links.findIndex(
            (link) => link.uuid === props['uuid']
          );
          const mediaIndex = this._project.currentProject?.media?.findIndex(
            (item) => item.uuid === props['uuid']
          );
          if (mediaIndex) {
            this.selectedMarkerName =
              this._project.currentProject?.media[mediaIndex].name ?? '';
          }
        } else if (
          feature &&
          feature instanceof Feature &&
          feature.getProperties()['visible']
        ) {
          if (feature.getProperties()['id']) {
            this.originalFeatures = JSON.parse(
              JSON.stringify(this.geojsonFeatures)
            );
            this.featureId = feature.getProperties()['id'];
            this.contextMenuType = 'map-geojson';
            this.displayContextMenu = true;
            this.selectedFeature = feature as Feature;
          }
        } else {
          this.contextMenuType = 'map';
          this.displayContextMenu = true;
        }
      }
    });
    this.setLinks();
    this.setGeoJson();
    this.addDragAndDrop();
  }

  onQueryParamUpdate(paramMap: ParamMap): void {
    if (paramMap.has('extent')) {
      try {
        this.view.fit(
          JSON.parse(paramMap.get('extent') || '[0,0,0,0]') as Extent,
          { duration: 1000 }
        );
      } catch (err) {
        console.error('Error parsing map extent query parameter', err);
      }
    }
    if (paramMap.has('base')) {
      this.changeMapLayer(paramMap.get('base') || '');
    }
  }

  onMapClick(event: MapBrowserEvent<MouseEvent>): void {
    if (this.movingMarker) {
      this.moveMarker(event);
    }
    const feature = this.map?.forEachFeatureAtPixel(
      event.pixel,
      function (feature) {
        return feature;
      }
    );

    if (feature) {
      const featureId = feature.getId() ?? feature.getProperties()['id'];
      this._router.navigate(['.'], {
        relativeTo: this._route,
        queryParams: { highlight: featureId },
        queryParamsHandling: 'merge',
      });

      if (feature.getProperties()['url']) {
        const props = feature.getProperties();
        this._router.navigateByUrl(props['url']);
      }
    }
  }

  onMapMoveEnd(event: MapEvent): void {
    const view = event.map.getView();
    const extent = view.calculateExtent(event.map.getSize());
    this.mapMove.emit(extent);
  }

  moveGeojsonFeature() {
    if (this.map && this.selectedFeature) {
      this._mapFeatures.moveGeojsonFeature(
        this.map,
        this.geojsonFeatures,
        this.selectedFeature
      );
    }
  }

  moveGeojsonLayer() {
    if (this.map && this.selectedFeature) {
      this._mapFeatures.moveGeojsonLayer(
        this.map,
        this.geojsonFeatures,
        this.selectedFeature,
        this.geoJsonLayer
      );
    }
  }

  editGeojsonCoordinates() {
    if (this.map && this.selectedFeature) {
      this._mapFeatures.editFeatureCoordinates(
        this.map,
        this.geojsonFeatures,
        this.selectedFeature
      );
    }
  }

  deleteGeojsonFeature() {
    if (this.map && this.selectedFeature) {
      this._mapFeatures.deleteFeature(
        this.geojsonFeatures,
        this.selectedFeature
      );
      this.setGeoJson();
    }
  }

  downloadGeojsonFeature() {
    if (!this.selectedFeature) throw new Error('no selected feature');
    this._mapFeatures.downloadGeojsonFeature(this.selectedFeature);
  }

  async addDragAndDrop() {
    if (this.map) {
      const newMapFeatures = await this._mapFeatures.addDragAndDrop(
        this.map,
        this.geojsonFeatures
      );
      this.geojsonFeatures = newMapFeatures;
      this.setGeoJson();
      this.setTooltips();
      this.addDragAndDrop();
    }
  }

  async addDraw(drawType: string) {
    if (this.map) {
      await this._mapFeatures.addDraw(
        this.map,
        this.geojsonFeatures,
        this.geoJsonLayer,
        this.featureCollectionIndex,
        drawType
      );
      this.setGeoJson();
      this.drawType = 'Cancel';
    }
  }

  getRightClickMenuStyle() {
    return {
      position: 'fixed',
      left: `${this.rightClickMenuPositionX}px`,
      top: `${this.rightClickMenuPositionY}px`,
    };
  }

  onMouseDown($event: PointerEvent) {
    if (this.map?.getOverlayById('tooltip'))
      this.map?.getOverlayById('tooltip').setPosition(undefined);
    this.displayContextMenu = false;
    this.contextMenuType = '';
    this.coordinateString = '';
    this.rightClickMenuPositionX = $event.clientX;
    this.rightClickMenuPositionY = $event.clientY;
    if (this.displayEditFeature) {
      this.closeEditStyleWindon('cancel');
    }
  }

  handleMenuItemClick(event: { event: MouseEvent; data: string }) {
    switch (event.data) {
      case 'Move':
        this.movingMarker = true;
        this._tracker.trackEvent('Map Context Menu', 'Click', 'Move');
        break;
      case 'Edit':
        this.showEditMarkerModal = true;
        break;
      case 'Delete':
        this.showDeleteMarkerModal = true;
        break;
      case 'Copy Coordinates':
        navigator.clipboard.writeText(this.coordinateString);
        break;
      case 'Show Layers':
        this.showEditMapLayers = true;
        break;
      case 'Move Feature':
        this.moveGeojsonFeature();
        break;
      case 'Move Layer':
        this.moveGeojsonLayer();
        break;
      case 'Edit Coordinates':
        this.editGeojsonCoordinates();
        break;
      case 'Edit Style':
        this.displayEditFeature = true;
        break;
      case 'Delete Feature':
        this.deleteGeojsonFeature();
        break;
      case 'Download Feature':
        this.downloadGeojsonFeature();
        break;
      case 'Show/Hide Title':
        this.showFeatureLabel();
        break;
      default:
        break;
    }
  }

  showFeatureLabel() {
    const props = this.selectedFeature?.getProperties() ?? {};
    const showTitle = props['showTitle'] ?? false;
    const id = String(this.selectedFeature?.getId());
    this.selectedFeature?.setProperties({ showTitle: !showTitle });
    this.selectedFeature?.setStyle(
      this._mapFeatures.generateGeojsonStyle(this.selectedFeature)
    );
    this.geojsonFeatures = this._mapFeatures.updateFeatureProperties(
      this.geojsonFeatures,
      id,
      { showTitle: !showTitle }
    );
  }

  toggleGraticules(show: boolean) {
    if (show) {
      const graticules = new Graticule({
        strokeStyle: new Stroke({
          color: 'rgba(0, 0, 0, 1)',
          width: 1,
        }),
        showLabels: true,
        lonLabelFormatter: (degrees) => this.degreesToStringHDM('EW', degrees),
        latLabelFormatter: (degrees) => this.degreesToStringHDM('NS', degrees),
        opacity: 0.5,
      });
      this.map?.addLayer(graticules);
    } else {
      this.map
        ?.getLayers()
        .getArray()
        .forEach((layer) => {
          if (layer instanceof Graticule) {
            this.map?.removeLayer(layer);
          }
        });
    }
  }

  scaleControl(): Control {
    return new ScaleLine({
      units: 'metric',
      minWidth: 100,
    });
  }

  mousePositionControl() {
    return new MousePosition({
      coordinateFormat: (coordinate) => {
        return this.toStringHDM(coordinate || [0, 0]);
      },
      projection: 'EPSG:4326',
      className: 'custom-mouse-position',
    });
  }

  toStringHDM(coordinate: number[]) {
    if (coordinate) {
      return `${this.degreesToStringHDM(
        'NS',
        coordinate[1]
      )} ${this.degreesToStringHDM('EW', coordinate[0])}`;
    } else {
      return '';
    }
  }

  degreesToStringHDM(hemispheres: string, degrees: number) {
    const normalizedDegrees = ((degrees + 180) % 360) - 180;
    const x = Math.abs(3600 * normalizedDegrees);
    let deg = Math.floor(x / 3600);
    let min = (x - deg * 3600) / 60;
    if (min >= 60) {
      min = 0;
      deg += 1;
    }
    return `${deg}\u00b0 ${min.toFixed(3)}\u2032 ${
      normalizedDegrees == 0
        ? ''
        : hemispheres.charAt(normalizedDegrees < 0 ? 1 : 0)
    }`;
  }

  setGeoJson() {
    this._mapFeatures.setGeoJson(
      this.geojsonFeatures,
      this.geoJsonSource,
      this.geoJsonLayer
    );
    if (this.highlight) {
      this.showTooltip(this.highlight);
    }
  }

  setLinks() {
    const newSource = new VectorSource();
    const geoJsonFormatter = new GeoJSON({
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857',
    });
    let newFeature;
    for (const link of this.links) {
      if (link.feature.type) {
        newFeature = geoJsonFormatter.readFeature(link.feature);

        newFeature.setProperties(link);
        newFeature.setId(link.uuid);
        const isHighlighted =
          this.highlight === '' ||
          this.links.length === 1 ||
          link.uuid === this.highlight;
        newFeature.setStyle(
          new Style({
            image: new Icon({
              src: link.icon,
              opacity: isHighlighted ? 1 : 0.5,
              anchor: [0.5, 1],
            }),
          })
        );
        newSource.addFeature(newFeature);
      }
    }

    this.setTooltips();

    this.markerLayer.setSource(newSource);
    if (
      newFeature &&
      !this.movingMarker &&
      !this.showDeleteMarkerModal &&
      !this.showEditMarkerModal &&
      !this._route.snapshot.queryParamMap.has('extent')
    ) {
      this.view.fit(newSource.getExtent(), {
        maxZoom: 18,
        padding: [20, 20, 20, 20],
      });
    }
  }

  setHighlight(uuid: string) {
    const source = this.markerLayer.getSource();
    this.showTooltip(uuid);
    source?.forEachFeature((feature) => {
      feature.setStyle(
        new Style({
          image: new Icon({
            src: '/assets/link.png',
            opacity: feature.getId() === uuid ? 1 : 0.5,
            anchor: [0.5, 1],
          }),
        })
      );
    });
  }

  setTooltips() {
    if (!this.map) throw new Error('No map');
    this.map.on('pointermove', (event) => {
      const feature = this.map?.forEachFeatureAtPixel(
        event.pixel,
        function (feature) {
          return feature;
        }
      );
      if (feature) {
        this.showTooltip(String(feature.getId() || ''), event);
      } else {
        if (this.highlight) {
          this.showTooltip(this.highlight);
        } else {
          this.tooltipContent = '';
        }
      }
    });
  }

  showTooltip(featureId: string, event?: MapBrowserEvent<PointerEvent>) {
    const feature =
      this.geoJsonLayer.getSource()?.getFeatureById(featureId) ??
      this.markerLayer.getSource()?.getFeatureById(featureId);
    const tooltip = this.tooltipContainer
      ? new Overlay({
          element: this.tooltipContainer.nativeElement,
          id: 'tooltip',
        })
      : null;
    if (!this.map) throw new Error('No map');
    tooltip && this.map.addOverlay(tooltip);
    if (
      feature &&
      (feature.getProperties()['uuid'] || feature.getProperties()['title']) &&
      !this.inset &&
      !this.displayContextMenu
    ) {
      let tooltipMessage = '';
      if (feature.getProperties()['uuid']) {
        tooltipMessage =
          getMediaById(
            this._project.currentProject,
            feature.getProperties()['uuid']
          )?.name ?? '';
      } else if (feature.getProperties()['title']) {
        tooltipMessage = feature.getProperties()['title'];
      }
      const coordinates =
        event?.coordinate ??
        olExtent.getCenter(feature.getGeometry()?.getExtent() ?? []);
      this.tooltipContent = `<p>${tooltipMessage}</p>`;
      tooltip?.setPosition(coordinates);
    } else {
      if (!this.highlight) {
        tooltip?.setPosition(undefined);
      }
    }
  }

  moveMarker(event: MapBrowserEvent<MouseEvent>) {
    const media = this._project.currentProject?.media;
    const newLocation = toLonLat(event.coordinate, 'EPSG:3857');
    const projectId = this._project.currentProject?._id;

    if (
      projectId &&
      media &&
      newLocation &&
      this.selectedMarkerIndex !== undefined
    ) {
      const index = this.selectedMarkerIndex;
      const selectedMedia = media.filter(
        (media) => media.uuid === this.links[index].uuid
      )[0];
      if (selectedMedia && selectedMedia.geolocation) {
        selectedMedia.geolocation.geometry.coordinates = newLocation;
      }
      const partial = { media };
      this._project.update(projectId, partial).subscribe(() => {
        if (this.selectedMarkerIndex !== undefined && this.markerLayer) {
          this.links[this.selectedMarkerIndex].feature.geometry.coordinates =
            newLocation;
          if (this.map) {
            this.map.removeLayer(this.markerLayer);
            this.setLinks();
            this.map.addLayer(this.markerLayer);
            this.movingMarker = false;
          }
        }
      });
    }
  }

  deleteMarker() {
    if (!this.selectedMarkerIndex) return;
    const media = this._project.currentProject?.media;
    const projectId = this._project.currentProject?._id;
    const uuid = this.links[this.selectedMarkerIndex].uuid;
    const mediaIndex = media?.findIndex((media) => media.uuid === uuid);
    media?.forEach((item) => {
      if (item.type === 'panorama') {
        item.markers = item.markers.filter((marker) => marker.mediaId !== uuid);
      }
    });
    if (media && mediaIndex !== undefined && projectId) {
      media.splice(mediaIndex, 1);
      const partial = { media };
      this._project.update(projectId, partial).subscribe(() => {
        this.links = this._project.generateMapMarkers(
          this._project.currentProject
        );
        if (!this.map) return;
        this.map.removeLayer(this.markerLayer);
        this.setLinks();
        this.map.addLayer(this.markerLayer);
        this.cancelDelete();
      });
    }
  }

  cancelDelete() {
    this.selectedMarkerIndex = undefined;
    this.selectedMarkerName = '';
    this.showDeleteMarkerModal = false;
  }

  editMarker() {
    const media = this._project.currentProject?.media;
    const projectId = this._project.currentProject?._id;

    const markerIndex = this.selectedMarkerIndex;
    if (media && projectId && markerIndex) {
      const mediaIndex = media.findIndex(
        (media) => media.uuid === this.links[markerIndex].uuid
      );
      media[mediaIndex].name = this.newName;
      const partial = { media };
      this._project.update(projectId, partial).subscribe(() => {
        if (!this.map) return;
        this.map.removeLayer(this.markerLayer);
        this.setLinks();
        this.map.addLayer(this.markerLayer);
        this.cancelEdit();
      });
    }
  }

  cancelEdit() {
    this.selectedMarkerIndex = undefined;
    this.selectedMarkerName = '';
    this.newName = '';
    this.showEditMarkerModal = false;
  }

  closeEditStyleWindon(event: string) {
    this.displayEditFeature = false;
    if (event === 'cancel') {
      this.geojsonFeatures = this.originalFeatures;
    }
    this.setGeoJson();
  }

  centerMap(coordinates: Coordinate) {
    this.map
      ?.getView()
      .setCenter(transform(coordinates, 'EPSG:4326', 'EPSG:3857'));
  }

  changeMapLayer(layer: string) {
    this.mapType = layer;
    localStorage.setItem('mapType', layer);
    switch (layer) {
      case 'openStreetMap':
        this.baseLayer.setSource(this.osmLayer);
        break;
      case 'bingSatellite':
        this.baseLayer.setSource(this.bingLayer);
        break;
      default:
        break;
    }
    this._route.firstChild?.paramMap.subscribe((params) => {
      if (!params.has('mediaId')) {
        this._router.navigate(['.'], {
          queryParams: { base: layer },
          queryParamsHandling: 'merge',
          relativeTo: this._route,
        });
      }
    });
  }
}
