import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
//@ts-ignore below import as no type
import Editor from 'ckeditor/build/ckeditor';
import { marked } from 'marked';

@Component({
  selector: 'app-markdown',
  templateUrl: './markdown.component.html',
  styleUrls: ['./markdown.component.scss'],
})
export class MarkdownComponent implements OnChanges {
  @Input() markdown: string = '';
  @Output() markdownChange: EventEmitter<string> = new EventEmitter();
  parsed: string = '';

  @Input() edit: boolean = false;
  @Output() editChange: EventEmitter<boolean> = new EventEmitter();

  @Input() readonly: boolean = false;
  @Input() autoSave: boolean = false;

  public config = { link: { defaultProtocol: 'http://' } };
  public Editor = Editor;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['markdown']) {
      this.parsed = marked.parse(changes['markdown'].currentValue);
    }
    this.parsed = this.parsed
      .split('<a href=')
      .join('<a target="_blank" rel="noopener noreferrer" href=');
  }

  public activateEdit() {
    this.editChange.emit(true);
  }

  public onEditorChange({ editor }: ChangeEvent) {
    const data = editor.getData();

    this.markdown = data;
    if (this.autoSave) {
      this.markdownChange.emit(this.markdown);
    }
  }

  public save() {
    this.markdownChange.emit(this.markdown);
  }
}
