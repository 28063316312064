<app-modal
  *ngIf="showModal && !project?.archived"
  heading="Confirm archive project?"
>
  <div class="question">
    <p>
      Are you sure you want to archive <b>{{ project?.name }}</b
      >?
    </p>
  </div>
  <div class="actions">
    <button type="button" (click)="cancel()">Cancel</button>
    <button type="button" (click)="archiveItem(project?._id)">Archive</button>
  </div>
</app-modal>
<app-modal
  *ngIf="showModal && project?.archived"
  heading="Confirm restore project?"
>
  <div class="question">
    <p>
      Are you sure you want to restore <b>{{ project?.name }}</b
      >?
    </p>
  </div>
  <div class="actions">
    <button type="button" (click)="cancel()">Cancel</button>
    <button type="button" (click)="restoreItem(project?._id)">Restore</button>
  </div>
</app-modal>
<app-modal
  *ngIf="showEditModal"
  heading="Edit Project"
  [(show)]="showEditModal"
>
  <app-project-form
    [project]="project"
    (cancel)="cancel()"
    (projectAdded)="editProject($event)"
  ></app-project-form>
</app-modal>
<app-modal
  *ngIf="showCopyModal"
  heading="Duplicate Project"
  [(show)]="showCopyModal"
>
  <app-project-form
    [project]="duplicateProject"
    (cancel)="cancel()"
    (projectAdded)="copyProject($event)"
  ></app-project-form>
</app-modal>
<div class="list-item">
  <div class="name">
    <a
      *ngIf="project"
      [routerLink]="['./', project._id]"
      [ngClass]="{ archived: project.archived }"
      >{{ project.name }}<span *ngIf="project.archived"> - (archived)</span></a
    >
  </div>
  <div class="buttons">
    <div
      class="rename"
      (click)="openCopyModal()"
      *ngIf="!project?.archived"
      tippy="Copy Project"
      placement="left"
      [delay]="[500, null]"
    >
      <div><fa-icon [icon]="faCopy"></fa-icon></div>
    </div>
    <div
      class="rename"
      (click)="openEditModal()"
      *ngIf="!project?.archived"
      tippy="Edit Name"
      placement="left"
      [delay]="[500, null]"
    >
      <div><fa-icon [icon]="faPencil"></fa-icon></div>
    </div>
    <div
      class="archive"
      (click)="openModal()"
      *ngIf="!project?.archived"
      tippy="Archive Project"
      placement="left"
      [delay]="[500, null]"
    >
      <div><fa-icon [icon]="faBoxArchive"></fa-icon></div>
    </div>
    <div
      class="archive"
      (click)="openModal()"
      *ngIf="project?.archived"
      tippy="Restore Project"
      placement="left"
      [delay]="[500, null]"
    >
      <div><fa-icon [icon]="faBoxOpen"></fa-icon></div>
    </div>
  </div>
</div>
