<div class="login">
  <div class="login-pannel">
    <div class="logo">
      <img src="../../../assets/logos/logo-full.jpg" alt="Waves-Group" />
    </div>
    <h1>WAVEFRONT</h1>
    <div *ngIf="showForm; else loggingIn">
      <app-login-form></app-login-form>
    </div>
    <ng-template #loggingIn>Verifying user.</ng-template>
  </div>
  <div class="hero-image">
    <img [src]="imageSrc" alt="hero-images" />
    <div class="credit">
      <div *ngIf="this.heroCounter === 1">
        Photo by
        <a
          href="https://unsplash.com/ja/@william07?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
          >william william</a
        >
        on
        <a
          href="https://unsplash.com/s/photos/ship?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
          >Unsplash</a
        >
      </div>
      <div *ngIf="this.heroCounter === 2">
        Photo by
        <a
          href="https://unsplash.com/fr/@michael75?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
          >Michael</a
        >
        on
        <a
          href="https://unsplash.com/s/photos/ship?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
          >Unsplash</a
        >
      </div>
      <div *ngIf="this.heroCounter === 3">
        Photo by
        <a
          href="https://unsplash.com/@craig_bradford?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
          >Craig Bradford</a
        >
        on
        <a
          href="https://unsplash.com/s/photos/ship?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
          >Unsplash</a
        >
      </div>
      <div *ngIf="this.heroCounter === 4">
        Photo by
        <a
          href="https://unsplash.com/@philipp9894?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
          >Phil S</a
        >
        on
        <a
          href="https://unsplash.com/s/photos/ship?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
          >Unsplash</a
        >
      </div>
      <div *ngIf="this.heroCounter === 5">
        Photo by
        <a
          href="https://unsplash.com/@ilangamuwa?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
          >Nilantha Ilangamuwa</a
        >
        on
        <a
          href="https://unsplash.com/s/photos/ship?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
          >Unsplash</a
        >
      </div>
      <div *ngIf="this.heroCounter === 6">
        Photo by
        <a
          href="https://unsplash.com/@mandacreatespretty?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
          >Manda Hansen</a
        >
        on
        <a
          href="https://unsplash.com/s/photos/ship?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
          >Unsplash</a
        >
      </div>
      <div *ngIf="this.heroCounter === 7">
        Photo by
        <a
          href="https://unsplash.com/@fredrickfilix93?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
          >Fredrick Filix</a
        >
        on
        <a
          href="https://unsplash.com/s/photos/ship?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
          >Unsplash</a
        >
      </div>
      <div *ngIf="this.heroCounter === 8">
        Photo by
        <a
          href="https://unsplash.com/@kerensa1?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
          >Kerensa Pickett</a
        >
        on
        <a
          href="https://unsplash.com/s/photos/ship?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
          >Unsplash</a
        >
      </div>
      <div *ngIf="this.heroCounter === 9">
        Photo by
        <a
          href="https://unsplash.com/@exdigy?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
          >Dominik Lückmann</a
        >
        on
        <a
          href="https://unsplash.com/s/photos/ship?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
          >Unsplash</a
        >
      </div>
      <div *ngIf="this.heroCounter === 10">
        Photo by
        <a
          href="https://unsplash.com/@andylid0?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
          >Andy Li</a
        >
        on
        <a
          href="https://unsplash.com/s/photos/ship?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
          >Unsplash</a
        >
      </div>
    </div>
  </div>
</div>
