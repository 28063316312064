import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { catchError, Observable } from 'rxjs';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  email: string = '';
  remember: boolean = false;
  redirect: string = '/';
  error: string = '';
  success: boolean = false;
  showModal: boolean = false;

  constructor(private _auth: AuthService, private _route: ActivatedRoute) {}

  ngOnInit(): void {
    this._route.queryParamMap.subscribe((queryParamMap) => {
      this.redirect = queryParamMap.get('redirect') || '/';
    });
  }

  login() {
    this._auth
      .verifyEmail(this.email, this.remember, this.redirect)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            this.error = 'User not found';
          } else {
            this.error =
              'An error occurred, please try again or contact support.';
          }
          return new Observable();
        })
      )
      .subscribe(() => {
        this.success = true;
        this.showModal = true;
      });
  }

  closeModal() {
    this.showModal = false;
  }
}
