<div class="editor">
  <div>
    <div class="title">
      <h4>Map Layers</h4>
    </div>
    <div class="layers">
      <ul>
        <div
          class="map-layer"
          *ngFor="let layer of geojsonFeatures; let i = index"
          (click)="selectLayer(i)"
        >
          <li>
            <div class="list-item">
              <div
                class="textbox"
                [ngClass]="{
                  editing: i === selectedLayerIndex && editLayer,
                  selected: i === selectedLayerIndex
                }"
                (focus)="setLayerName()"
                (blur)="edit()"
                (input)="updateLayerName($event)"
                [contentEditable]="editLayer"
              >
                {{ layer.name }}
              </div>
              <div class="icons">
                <div
                  class="icon fa-xs"
                  *ngIf="!layer.visible"
                  (click)="showHideLayer(i, true)"
                  tippy="Show"
                  placement="bottom"
                  [delay]="[500, null]"
                >
                  <fa-icon [icon]="faEye"></fa-icon>
                </div>
                <div
                  class="icon fa-xs"
                  *ngIf="layer.visible"
                  (click)="showHideLayer(i, false)"
                  tippy="Hide"
                  placement="bottom"
                  [delay]="[500, null]"
                >
                  <fa-icon [icon]="faEyeSlash"></fa-icon>
                </div>
                <div
                  class="icon fa-xs"
                  (click)="downloadFeatureCollection(i)"
                  tippy="Download"
                  placement="bottom"
                  [delay]="[500, null]"
                >
                  <fa-icon [icon]="faDownload"></fa-icon>
                </div>
                <div
                  class="icon fa-xs"
                  [ngClass]="{
                    'icon-editing': i === selectedLayerIndex && editLayer
                  }"
                  (click)="renameLayer(i)"
                  tippy="Edit"
                  placement="bottom"
                  [delay]="[500, null]"
                >
                  <fa-icon [icon]="faPencil"></fa-icon>
                </div>
                <div
                  class="icon fa-xs"
                  (click)="deleteLayer(i)"
                  tippy="Delete"
                  placement="bottom"
                  [delay]="[500, null]"
                >
                  <fa-icon [icon]="faTrash"></fa-icon>
                </div>
              </div>
            </div>
          </li>
        </div>
      </ul>
    </div>
    <hr />
    <button
      type="click"
      (click)="addLayer()"
      tippy="Add new Layer"
      placement="bottom"
      [delay]="[500, null]"
    >
      New Layer
    </button>
    <button
      type="click"
      (click)="downloadAllFeatures()"
      tippy="Download All Features"
      placement="bottom"
      [delay]="[500, null]"
    >
      Download
    </button>
    <hr />
    <div class="drawing-types">
      <label for="type">Select a drawing tool</label>
      <br />
      <div class="selector">
        <label tippy="Cursor" placement="bottom" [delay]="[500, null]">
          <input
            type="radio"
            name="type"
            value="Cancel"
            (change)="selectDrawType('Cancel')"
            [checked]="drawType === 'Cancel'"
          />
          <fa-icon [icon]="faArrowPointer"></fa-icon>
        </label>
        <label tippy="Point" placement="bottom" [delay]="[500, null]">
          <input
            type="radio"
            name="type"
            value="Point"
            (change)="selectDrawType('Point')"
            [checked]="drawType === 'Point'"
          />
          <fa-icon [icon]="faLocationDot"></fa-icon>
        </label>
        <label tippy="Line" placement="bottom" [delay]="[500, null]">
          <input
            type="radio"
            name="type"
            value="LineString"
            (change)="selectDrawType('LineString')"
            [checked]="drawType === 'LineString'"
          />
          <fa-icon [icon]="faRoute"></fa-icon>
        </label>
        <label tippy="Polygon" placement="bottom" [delay]="[500, null]">
          <input
            type="radio"
            name="type"
            value="Polygon"
            (change)="selectDrawType('Polygon')"
            [checked]="drawType === 'Polygon'"
          />
          <fa-icon [icon]="faDrawPolygon"></fa-icon>
        </label>
      </div>
    </div>
    <hr />
    <div class="title">
      <h4>Features</h4>
    </div>
    <div class="features">
      <ul
        *ngIf="selectedLayerIndex >= 0 && geojsonFeatures![selectedLayerIndex]"
      >
        <div
          class="layer-features"
          *ngFor="
            let feature of geojsonFeatures![selectedLayerIndex]
              .featureCollection.features;
            let i = index
          "
        >
          <li>
            <div class="list-item">
              <div
                class="textbox"
                (click)="selectFeature(i, true)"
                [ngClass]="{
                  selected: i === selectedFeatureIndex,
                  editing: i === selectedFeatureIndex && editFeature
                }"
                (focus)="setFeatureName()"
                (blur)="edit()"
                (input)="updateFeatureName($event)"
                [contentEditable]="editFeature"
              >
                {{ feature.properties!['title'] || '' }}
              </div>
              <div class="icons">
                <div
                  class="icon fa-xs"
                  *ngIf="!feature.properties!['visible']"
                  (click)="showHideFeature(i, true)"
                  tippy="Show"
                  placement="bottom"
                  [delay]="[500, null]"
                >
                  <fa-icon [icon]="faEye"></fa-icon>
                </div>
                <div
                  class="icon fa-xs"
                  *ngIf="feature.properties!['visible']"
                  (click)="showHideFeature(i, false)"
                  tippy="Hide"
                  placement="bottom"
                  [delay]="[500, null]"
                >
                  <fa-icon [icon]="faEyeSlash"></fa-icon>
                </div>
                <div
                  class="icon fa-xs"
                  (click)="downloadFeature(i)"
                  tippy="Download"
                  placement="bottom"
                  [delay]="[500, null]"
                >
                  <fa-icon [icon]="faDownload"></fa-icon>
                </div>
                <div
                  class="icon fa-xs"
                  (click)="renameFeature(i)"
                  tippy="Edit"
                  placement="bottom"
                  [delay]="[500, null]"
                >
                  <fa-icon [icon]="faPencil"></fa-icon>
                </div>
                <div
                  class="icon fa-xs"
                  (click)="deleteFeature(i)"
                  tippy="Delete"
                  placement="bottom"
                  [delay]="[500, null]"
                >
                  <fa-icon [icon]="faTrash"></fa-icon>
                </div>
              </div>
            </div>
          </li>
        </div>
      </ul>
    </div>
  </div>
  <hr />
  <div class="actions">
    <button type="button" (click)="close()">Close</button>
  </div>
</div>
