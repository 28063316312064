<div
  @overlayInOut
  class="overlay"
  #overlay
  id="modal-overlay"
  (click)="overlayCloseModal($event)"
>
  <div #modalWindow class="modal" [ngClass]="{ large: type === 'large' }">
    <div class="heading">
      {{ heading }}
      <button *ngIf="!isFullscreen" (click)="fullscreen()" class="fullscreen">
        <fa-icon [icon]="faMaximize"></fa-icon>
      </button>
      <button (click)="closeModal()" class="close">
        <fa-icon [icon]="faClose" size="xl"></fa-icon>
      </button>
    </div>
    <div class="modal-body">
      <ng-content></ng-content>
    </div>
  </div>
</div>
