<div
  #panoramaViewer
  class="pano-component"
  (mousedown)="onMouseDown($event)"
></div>

<app-modal-markers
  [(show)]="showModal"
  [marker]="selectedMarker"
  *ngIf="showModal"
  (showChange)="clearModal()"
  [key]="mediaId"
  [mediaOptions]="panorama?.options"
>
</app-modal-markers>

<app-modal-markers-delete
  [(show)]="showDeleteModal"
  [marker]="selectedMarker"
  *ngIf="showDeleteModal"
  [key]="mediaId"
>
</app-modal-markers-delete>

<app-context-menu
  *ngIf="isDisplayContextMenu && !readOnly"
  type="{{ contextMenuType }}"
  id="{{ markerId }}"
  [ngStyle]="getRightClickMenuStyle()"
  (contextMenuItemClick)="handleMenuItemClick($event)"
></app-context-menu>

<div class="wrapper">
  <div class="move-info" *ngIf="movingMarker">
    <p>Select a new postion.</p>
  </div>
</div>
<div class="wrapper" *ngIf="showEditingHorizon">
  <div class="move-info">
    <app-edit-horizon></app-edit-horizon>
  </div>
</div>
