import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  HostListener,
} from '@angular/core';
import { getMediaById, ProjectMedia } from 'src/app/classes/Project';
import { ClickData, Position, Viewer } from 'photo-sphere-viewer';
import {
  MarkersPlugin,
  MarkerProperties,
  Marker,
} from 'photo-sphere-viewer/dist/plugins/markers';
import { ProjectService } from 'src/app/projects/project.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatomoTracker } from '@ngx-matomo/tracker';
import {
  Media,
  MediaMarker,
  MediaType,
  MEDIATYPES,
} from 'src/app/classes/Media';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-panorama',
  templateUrl: './panorama.component.html',
  styleUrls: ['./panorama.component.scss'],
})
export class PanoramaComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() panorama?: Media<typeof MEDIATYPES.PANORAMA>;
  @Input() projectUpdated: boolean = false;
  @ViewChild('panoramaViewer') panoramaViewerElement!: ElementRef;
  @Output() panoramaSelected: EventEmitter<string> = new EventEmitter();
  viewer?: Viewer;
  showModal: boolean = false;
  showDeleteModal: boolean = false;
  showEditingHorizon: boolean = false;
  selectedMarker?: Marker;
  isDisplayContextMenu: boolean = false;
  contextMenuType: string = '';
  rightClickMenuPositionX: number = 0;
  rightClickMenuPositionY: number = 0;
  movingMarker: boolean = false;
  markerId: string = '';
  urlPosition: Position = { latitude: 0, longitude: 0 };
  urlZoom: number = 0;
  params?: Params;
  mediaId: string = '';
  readOnly: boolean = true;
  timeout: number = 0;

  constructor(
    private _project: ProjectService,
    private _auth: AuthService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _tracker: MatomoTracker
  ) {
    this.readOnly = this._auth.role !== 'admin' && this._auth.role !== 'user';
  }

  ngOnInit(): void {
    this._project.projectUpdated.subscribe(() => {
      this.addFeatures();
    });
    this._route.paramMap.subscribe((params) => {
      const mediaId = params.get('mediaId');
      if (mediaId) {
        this.mediaId = mediaId;
        this.panorama = this._project.currentProject?.media.find(
          (media) => media.uuid === mediaId
        ) as Media<typeof MEDIATYPES.PANORAMA>;
      }
    });
    this._project.horizonCorrectionEvent.subscribe((data) => {
      this.changeHorizon(data);
    });
    this._project.toggleEditHorizonModal.subscribe((show) => {
      this.showEditingHorizon = show;
    });

    this._route.queryParams.subscribe((params) => {
      this.params = params;
    });
  }

  ngOnChanges() {
    this._route.queryParams.subscribe((params) => {
      this.params = params;
    });
    this.setPanorama();
  }

  changeHorizon(data: { tilt: number; roll: number }) {
    this.viewer?.setOption('sphereCorrection', data);
  }

  async ngAfterViewInit(): Promise<void> {
    this.viewer = new Viewer({
      container: this.panoramaViewerElement.nativeElement,
      panorama: await this.getUrl(),
      caption: this.panorama?.name || '',
      navbar: ['autorotate', 'zoom', 'move', 'caption'],
      plugins: [MarkersPlugin],
      minFov: 15,
      sphereCorrection: {
        tilt: this.getDefaultTilt(),
        roll: this.getDefaultRoll(),
      },
      loadingImg: '../../../../assets/logos/logo-full-trans.png',
    });

    this.viewer.on('click', (_, data) => {
      if (this.movingMarker) {
        this.moveMarker(data);
      }

      this._project.panoClick(data);

      if (data.rightclick && !data.marker) {
        this.displayAddMarkerContextMenu();
      }
    });

    this.viewer.on('position-updated', (_, position) => {
      this._project.updateCurrentPosition(position);
      this.urlPosition = position;
      this.debounceUrl();
    });

    this.viewer.on('zoom-updated', (_, level) => {
      this._project.updateCurrentZoom(level);
      this.urlZoom = level;
      this.debounceUrl();
    });

    this.viewer.on('panorama-loaded', () => {
      this.viewer?.rotate({
        latitude: this.getDefaultLat(),
        longitude: this.getDefaultLong(),
      });
      this.viewer?.zoom(this.getDefaultZoom());
      this.viewer?.setOption('caption', this.panorama?.name);
      this._tracker.trackEvent(
        'Panorama',
        'Open Panorama',
        this.panorama?.name
      );

      this.addFeatures();
      if (this.params?.['marker']) {
        const id = this.params['marker'].split('-');
        this.goToMarker(id[0], Number(id[1]));
      }
    });
  }

  debounceUrl() {
    clearTimeout(this.timeout);
    this.timeout = window.setTimeout(() => {
      this.updateUrl();
    }, 200);
  }

  closeEditorWindow() {
    this._project.toggleEditorWindow.emit(false);
  }

  goToMarker(type: string, index: number) {
    const markersPlugin = this.viewer?.getPlugin(MarkersPlugin);
    const marker = markersPlugin?.getMarker(`${type}-${index}`);
    markersPlugin?.gotoMarker(marker?.id || '', 1000);
    this.selectedMarker = marker;
    this.showModal = true;
    this._tracker.trackEvent('Panorama', 'Open Marker', marker?.id);
  }

  getRightClickMenuStyle() {
    return {
      position: 'fixed',
      left: `${this.rightClickMenuPositionX}px`,
      top: `${this.rightClickMenuPositionY}px`,
    };
  }

  displayMarkerContextMenu(marker: Marker) {
    this.isDisplayContextMenu = true;
    this.contextMenuType = 'editMarker';
    this.markerId = marker.id;
  }

  displayAddMarkerContextMenu() {
    this.contextMenuType = 'addMarker';
    this.isDisplayContextMenu = true;
  }

  handleMenuItemClick(event: { event: MouseEvent; data: string }) {
    switch (event.data) {
      case 'Delete':
        this.showDeleteModal = true;
        this._tracker.trackEvent('Panorama Context Menu', 'Click', 'Delete');
        break;
      case 'Edit':
        this._project.editingMarkerId = this.markerId;
        this._project.toggleEditorWindow.emit(true);
        this._tracker.trackEvent('Panorama Context Menu', 'Click', 'Edit');
        break;
      case 'Move':
        this.movingMarker = true;
        this._tracker.trackEvent('Panorama Context Menu', 'Click', 'Move');
        break;
      default:
        const type = event.data;
        this._project.toggleEditorWindow.emit(true);
        this._project.editorWindowType.emit(type.toLowerCase());
        break;
    }
  }

  @HostListener('document:click')
  documentClick(): void {
    this.isDisplayContextMenu = false;
    this.contextMenuType = '';
  }

  onMouseDown($event: MouseEvent) {
    this.isDisplayContextMenu = false;
    this.contextMenuType = '';
    this.rightClickMenuPositionX = $event.clientX;
    this.rightClickMenuPositionY = $event.clientY;
  }

  async moveMarker(data: ClickData) {
    const type = this.markerId.split('-')[0];
    const media = this._project.currentProject?.media;
    const markerIndex = Number(this.markerId.split('-')[1]);
    const projectId = this._project.currentProject?._id;
    const marker = getMediaById(this._project.currentProject, this.mediaId)
      .markers[markerIndex];

    if (type && markerIndex !== undefined && media && projectId) {
      marker.y = data.latitude;
      marker.x = data.longitude;

      await this._project.updateMarkers(
        projectId,
        this.mediaId,
        marker,
        markerIndex
      );

      this.movingMarker = false;
      this._project.projectUpdated.emit();
      this.clearModal();
    }
  }

  findMediaIndex(
    mediaArray: ProjectMedia<MediaType>[],
    markerIndex: number,
    type: string
  ) {
    const matchingIndicies: number[] = [];
    mediaArray.forEach((marker, index) => {
      if (marker.type === type) matchingIndicies.push(index);
    });
    return matchingIndicies[markerIndex];
  }

  clearModal() {
    this.markerId = '';
  }

  async getUrl(): Promise<string> {
    const panorama = this._project.currentProject?.media.find(
      (item) => item.uuid === this.mediaId
    );
    if (this._project.currentProject && panorama) {
      const url = await this._project.getMediaUrl(
        this._project.currentProject,
        panorama?.file?.url || ''
      );
      return url;
    } else {
      return '';
    }
  }

  getMediaUrl(marker: string | undefined) {
    return marker
      ? `projects/${this._project.currentProject?._id}/media/${marker}`
      : '.';
  }

  getDefaultLong() {
    if (this.params && this.params['long']) {
      return this.params['long'];
    } else {
      const longitude = this.panorama?.options?.defaultView
        ? this.panorama.options?.defaultView.longitude
        : 0;
      this.urlPosition.longitude = longitude;
      return longitude;
    }
  }

  getDefaultLat() {
    if (this.params && this.params['lat']) {
      return this.params['lat'];
    } else {
      const latitude = this.panorama?.options?.defaultView
        ? this.panorama.options?.defaultView.latitude
        : 0;
      this.urlPosition.latitude = latitude;
      return latitude;
    }
  }

  getDefaultZoom() {
    if (this.params && this.params['zoom']) {
      return this.params['zoom'];
    } else {
      const zoom = this.panorama?.options?.defaultZoom || 0;
      this.urlZoom = zoom;
      return zoom;
    }
  }

  getDefaultTilt() {
    return this.panorama?.options?.sphereCorrection
      ? this.panorama?.options?.sphereCorrection.tilt
      : 0;
  }

  getDefaultRoll() {
    return this.panorama?.options?.sphereCorrection
      ? this.panorama?.options?.sphereCorrection.roll
      : 0;
  }

  updateUrl() {
    const position = this.urlPosition;
    const zoom = this.urlZoom;
    this._router.navigate(['./'], {
      queryParams: {
        zoom: zoom,
        lat: position.latitude,
        long: position.longitude,
      },
      queryParamsHandling: 'merge',
      relativeTo: this._route,
      replaceUrl: true,
    });
  }

  async setPanorama() {
    const markersPlugin = this.viewer?.getPlugin(MarkersPlugin);
    markersPlugin?.clearMarkers();
    await this.viewer?.setPanorama(await this.getUrl(), { transition: 0 });
    this.viewer?.setOption('sphereCorrection', {
      tilt: this.getDefaultTilt(),
      roll: this.getDefaultRoll(),
    });
  }

  addFeatures() {
    if (!this._project.currentProject) return;

    const panorama = getMediaById(this._project.currentProject, this.mediaId);
    if (panorama.type === MEDIATYPES.PANORAMA) {
      this.panorama = panorama;
    }

    const markersPlugin = this.viewer?.getPlugin(MarkersPlugin);
    if (markersPlugin) {
      markersPlugin.clearMarkers();
      this.addMarkers(markersPlugin);
      this.addMarkerActions(markersPlugin);
    }
  }

  addMarkers(markersPlugin: MarkersPlugin) {
    if (this.panorama?.markers && this.panorama.markers.length > 0) {
      this.panorama.markers.forEach((marker, idx) => {
        markersPlugin.addMarker(this.generateMarker(idx, marker));
      });
    }
  }

  generateMarker(
    index: number,
    marker: MediaMarker & { files?: string[] }
  ): MarkerProperties {
    const markerProperties: MarkerProperties = {
      id: `marker-${index}`,
      latitude: marker.y,
      longitude: marker.x,
      tooltip: `${marker.caption} - ${marker.type}`,
      width: 64,
      height: 74,
      anchor: 'bottom center',
    };
    switch (marker.type) {
      case 'photo':
        Object.assign(markerProperties, this.generatePhotoMarkerProps(marker));
        break;
      case 'video':
        Object.assign(markerProperties, this.generateVideoMarkerProps(marker));
        break;
      case 'pdf':
        Object.assign(markerProperties, this.generatePdfMarkerProps(marker));
        break;
      case 'link':
        Object.assign(
          markerProperties,
          this.generateLinkMarkerProps(marker, index)
        );
        break;
      case 'text':
        Object.assign(markerProperties, this.generateTextMarkerProps(marker));
        break;
      default:
        markerProperties.imageLayer = '/assets/photo.png';
        break;
    }
    return markerProperties;
  }

  generateTextMarkerProps(text: MediaMarker): Partial<MarkerProperties> {
    return {
      imageLayer: '/assets/information.png',
      data: {
        type: 'text',
        heading: text.caption,
        content: text.content,
      },
    };
  }

  //TODO: better define props.data in return type
  generateLinkMarkerProps(
    link: MediaMarker,
    index: number
  ): Partial<MarkerProperties> {
    return {
      imageLayer: '/assets/link.png',
      id: `link-${index}`,
      tooltip: `Go to - ${
        this._project.currentProject && link.mediaId
          ? getMediaById(this._project.currentProject, link.mediaId)?.name
          : ''
      }`,
      data: {
        type: 'link',
        uuid: link?.mediaId ?? '',
      },
    };
  }

  //TODO: better define props.data in return type
  generatePhotoMarkerProps(photo: MediaMarker): Partial<MarkerProperties> {
    const mediaUrl =
      this._project.currentProject?.media.find(
        (item) => item.uuid === photo.mediaId
      )?.file?.url || '';

    return {
      imageLayer: '/assets/information.png',
      data: {
        type: 'photo',
        url: mediaUrl,
        heading: photo.caption,
        content: photo.content,
      },
    };
  }

  //TODO: better define props.data in return type
  generateVideoMarkerProps(video: MediaMarker): Partial<MarkerProperties> {
    const mediaUrl =
      this._project.currentProject?.media.find(
        (item) => item.uuid === video.mediaId
      )?.file?.url || '';

    return {
      imageLayer: '/assets/information.png',
      anchor: 'bottom center',
      data: {
        type: 'video',
        url: mediaUrl,
        heading: video.caption,
        mediaId: video.mediaId,
      },
    };
  }

  //TODO: better define props.data in return type
  generatePdfMarkerProps(pdf: MediaMarker): Partial<MarkerProperties> {
    const mediaUrl =
      this._project.currentProject?.media.find(
        (item) => item.uuid === pdf.mediaId
      )?.file?.url || '';

    return {
      imageLayer: '/assets/information.png',
      data: {
        type: 'pdf',
        url: mediaUrl,
        heading: pdf.caption,
      },
    };
  }

  addMarkerActions(markersPlugin: MarkersPlugin) {
    markersPlugin.on('select-marker', (e, marker) => {
      this.closeEditorWindow();
      this.selectedMarker = marker;
      this.markerId = marker.id;

      if (
        e.args[1].rightclick &&
        (this._auth.role === 'admin' || this._auth.role === 'user')
      ) {
        markersPlugin.hideMarkerTooltip(marker.id);
        this.isDisplayContextMenu = true;
        this.displayMarkerContextMenu(marker);
      } else if (marker.id.startsWith('link')) {
        this.panoramaSelected.emit(marker.data.uuid);
      } else {
        this._router.navigate(['./'], {
          queryParams: { marker: marker.id },
          queryParamsHandling: 'merge',
          relativeTo: this._route,
          replaceUrl: true,
        });
        this.showModal = true;
      }
    });
  }
}
