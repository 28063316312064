import { Pipe, PipeTransform } from '@angular/core';
import { Media, MediaType } from '../classes/Media';

@Pipe({
  name: 'mediaSort',
})
export class MediaSortPipe implements PipeTransform {
  transform(
    value: Media<MediaType>[],
    sortBy: string,
    sortDescending: boolean
  ): Media<MediaType>[] {
    switch (sortBy) {
      case 'name':
        value?.sort((a, b) => {
          if (sortDescending && a.name && b.name)
            return a.name.trimStart().localeCompare(b.name.trimStart());
          if (!sortDescending && a.name && b.name)
            return b.name.trimStart().localeCompare(a.name.trimStart());
          return 0;
        });
        break;
      case 'type':
        value?.sort((a, b) => {
          if (sortDescending)
            return a.type.toLowerCase() > b.type.toLowerCase() ? 1 : -1;
          if (!sortDescending)
            return a.type.toLowerCase() < b.type.toLowerCase() ? 1 : -1;
          return 0;
        });
        break;
      case 'geolocation':
        value?.sort((a, b) => {
          if (!!a.geolocation === !!b.geolocation) return 0;
          if (sortDescending) return !!a.geolocation ? -1 : 1;
          if (!sortDescending) return !!b.geolocation ? -1 : 1;
          return 0;
        });
        break;
      case 'dateTime':
        value?.sort((a, b) => {
          const dateA =
            a.manual_date || a.created_at || new Date(8640000000000000);
          const dateB =
            b.manual_date || b.created_at || new Date(8640000000000000);
          if (sortDescending)
            return new Date(dateA).getTime() - new Date(dateB).getTime();
          if (!sortDescending)
            return new Date(dateB).getTime() - new Date(dateA).getTime();
          return 0;
        });
        break;
      default:
        break;
    }
    return value;
  }
}
