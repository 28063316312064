<tr
  id="row-{{ media.uuid }}"
  *ngIf="media"
  [ngClass]="{
    'display-properties-panel': propertiesPanelId === media.uuid
  }"
  (mouseup)="onMouseUp($event)"
  (mousedown)="onMouseDown($event)"
>
  <td>
    <div class="item-name">
      <div class="select">
        <input
          type="checkbox"
          id="{{ media.uuid }}"
          [(ngModel)]="selected"
          (change)="handleSelection()"
          onmousedown="event.stopPropagation()"
        />
      </div>
      <div>
        <div
          class="textbox"
          [ngClass]="{
            'display-properties-panel': propertiesPanelId === media.uuid
          }"
          (keydown)="handleKeydown($event)"
          (focus)="setName()"
          (input)="updateName($event)"
          (blur)="edit()"
          id="id-{{ media.uuid }}"
        >
          {{ media.name }}
        </div>
      </div>
    </div>
  </td>
  <td id="type">{{ media.type[0].toUpperCase() + media.type.slice(1) }}</td>
  <td *ngIf="!media.geolocation" id="show-on-map">
    <label
      class="switch"
      tippy="Not Geolocated"
      placement="top"
      [delay]="[500, null]"
    >
      <span class="slider inactive round"></span>
    </label>
  </td>
  <td *ngIf="media.geolocation" id="show-on-map">
    <label
      class="switch"
      [tippy]="showOnMap ? 'Remove from map' : 'Show on map'"
      placement="top"
      [delay]="[500, null]"
    >
      <input
        onmousedown="event.stopPropagation()"
        type="checkbox"
        [(ngModel)]="showOnMap"
        (change)="updatShowOnMap()"
      />
      <span class="slider round" onmousedown="event.stopPropagation()"></span>
    </label>
  </td>
  <td id="time-date">
    <div *ngIf="!editDateTime">
      <div *ngIf="media.manual_date">
        {{ media.manual_date | date : 'MMM d, y - HH:mm' }}
      </div>
      <div *ngIf="media.created_at && !media.manual_date">
        {{ media.created_at | date : 'MMM d, y - HH:mm' }}
      </div>
      <div *ngIf="!media.created_at && !media.manual_date">No date or time</div>
    </div>
    <input
      *ngIf="editDateTime"
      class="datetime"
      autofocus
      id="date-time-{{ media.uuid }}"
      type="datetime-local"
      [(ngModel)]="updatedDateTime"
      (blur)="updateDate()"
    />
  </td>
  <td>
    <div class="icons">
      <div
        class="fa-icon"
        (click)="openDeleteMediaModal()"
        tippy="Delete"
        placement="right"
        [delay]="[500, null]"
      >
        <fa-icon [icon]="faTrash"></fa-icon>
      </div>
    </div>
  </td>
</tr>

<app-modal
  heading="Delete {{ media?.name }}?"
  *ngIf="showDeleteModal"
  [(show)]="showDeleteModal"
>
  <div class="question">
    <p>
      Are you sure you want to delete <b>{{ media?.name }}</b
      >?
    </p>
  </div>
  <div class="actions">
    <button type="button" (click)="cancelDelete()">Cancel</button>
    <button type="button" (click)="deleteMedia()">Delete</button>
  </div>
</app-modal>

<app-context-menu
  *ngIf="displayContextMenu"
  type="galleryListView"
  (contextMenuItemClick)="handleMenuItemClick($event)"
  [ngStyle]="getRightClickMenuStyle()"
></app-context-menu>
