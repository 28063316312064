import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Marker } from 'photo-sphere-viewer/dist/plugins/markers';
import { ProjectService } from 'src/app/projects/project.service';

@Component({
  selector: 'app-modal-markers-delete',
  templateUrl: './modal-markers-delete.component.html',
  styleUrls: ['./modal-markers-delete.component.scss'],
})
export class ModalMarkersDeleteComponent {
  @Input() marker?: Marker;
  @Input() show: boolean = false;
  @Input() key: string = '';
  @Output() showChange: EventEmitter<boolean> = new EventEmitter();

  constructor(private _project: ProjectService) {}

  closeModal() {
    this._project.markerDeleteFalse();
    this.showChange.emit(false);
  }

  async deleteMarker() {
    const projectId = this._project.currentProject?._id;
    const mediaId = this.key;
    const markerIndex = Number(
      this.marker?.id.slice(this.marker?.id.indexOf('-') + 1)
    );

    if (projectId && mediaId) {
      const result = await this._project.deleteMarker(
        projectId,
        mediaId,
        markerIndex
      );
      if (result.ok) {
        this._project.currentProject?.media
          .filter((item) => item.uuid === mediaId)[0]
          .markers.splice(markerIndex, 1);
        this._project.markerDeleteFalse();
        this._project.projectUpdated.emit();
        this.closeModal();
      }
    }
  }
}
