import { Pipe, PipeTransform } from '@angular/core';
import { Case } from '../classes/Case';

@Pipe({
  name: 'caseSearch',
})
export class CaseSearchPipe implements PipeTransform {
  transform(value: Case[], search: string): Case[] {
    const result = search.length
      ? value.filter(
          (v) =>
            v.name.toLowerCase().includes(search.toLowerCase()) ||
            v.code.toLowerCase().includes(search.toLowerCase())
        )
      : value;

    return result;
  }
}
