import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Feature as geojsonFeature, FeatureCollection } from '@turf/helpers';
import { ProjectService } from 'src/app/projects/project.service';

@Component({
  selector: 'app-editor-map-feature',
  templateUrl: './editor-map-feature.component.html',
  styleUrls: ['./editor-map-feature.component.scss'],
})
export class EditorMapFeatureComponent implements OnInit {
  @Input() featureId: string = '';
  @Input() geojsonFeatures: Array<{
    name: string;
    visible: boolean;
    featureCollection: FeatureCollection;
  }> = [];
  @Output() geojsonFeaturesChange = new EventEmitter<
    Array<{
      name: string;
      visible: boolean;
      featureCollection: FeatureCollection;
    }>
  >();
  @Output() closeWindow = new EventEmitter<string>();
  feature?: geojsonFeature;
  fillColor: string = '';
  strokeColor: string = '';

  constructor(private _project: ProjectService) {}

  ngOnInit(): void {
    this.getFeature();
    this.fillColor = this.convertRgbToHex('fill');
    this.strokeColor = this.convertRgbToHex('stroke');
  }

  getFeature() {
    let selectedFeature;
    this.geojsonFeatures.forEach((collection) => {
      collection.featureCollection.features.forEach((feature) => {
        if (feature.properties?.['id'] === this.featureId) {
          selectedFeature = feature;
        }
      });
    });
    if (selectedFeature) {
      this.feature = selectedFeature;
    }
  }

  saveFeature() {
    const id = this._project.currentProject?._id;
    if (id) {
      this._project
        .update(id, { map_features: this.geojsonFeatures })
        .subscribe(() => {
          this.geojsonFeaturesChange.emit(this.geojsonFeatures);
          this.closeWindow.emit('save');
        });
    }
  }

  updateFeature() {
    this.geojsonFeaturesChange.emit(this.geojsonFeatures);
  }

  convertRgbToHex(item: string) {
    const properties = this.feature?.properties;
    if (properties) {
      return (
        '#' +
        [properties[item][0], properties[item][1], properties[item][2]]
          .map((x) => {
            const hex = x.toString(16);
            return hex.length === 1 ? '0' + hex : hex;
          })
          .join('')
      );
    } else {
      throw new Error();
    }
  }

  convertHexToRGB(event: Event & { target: EventTarget | null }, item: string) {
    const target = event.target as HTMLInputElement;
    if (target) {
      const properties = this.feature?.properties;
      if (properties) {
        const color = target.value;
        properties[item][0] = parseInt(color.substring(1, 3), 16);
        properties[item][1] = parseInt(color.substring(3, 5), 16);
        properties[item][2] = parseInt(color.substring(5, 7), 16);
        this.updateFeature();
      }
    }
  }

  cancel() {
    this.closeWindow.emit('cancel');
  }
}
