import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  constructor(private _title: Title) {}

  setTitle(title?: string) {
    if (title?.length) {
      this._title.setTitle(`WAVEFRONT - ${title}`);
    } else {
      this._title.setTitle(`WAVEFRONT`);
    }
  }
}
