import { Feature, Point } from '@turf/helpers';
import { Position } from 'photo-sphere-viewer';

export const MEDIATYPES = {
  IMAGE: 'image',
  VIDEO: 'video',
  PDF: 'pdf',
  TEXT: 'text',
  PANORAMA: 'panorama',
} as const;

export type MediaType = (typeof MEDIATYPES)[keyof typeof MEDIATYPES];

export class Media<T extends MediaType> {
  type: T;
  uuid?: string;
  name?: string;
  width?: number;
  height?: number;
  caption?: string;
  content?: string;
  url?: string;
  geolocation?: Feature<Point>;
  show_on_map: boolean = true;
  file?: File;
  thumbnail?: string;
  markers: MediaMarker[] = [];
  options?: MediaOptions<T>;
  created_at?: Date;
  uploaded_at?: Date;
  modified_at?: Date;
  manual_date?: Date;
  metadata?: ProjectMediaMetaData[];

  constructor(type: T) {
    this.type = type;
  }
}

export class ProjectMediaMetaData {
  label?: string;
  values?: MediaMetadataValue[];
}

export class MediaMetadataValue {
  label?: string;
  data?: string | number | Date | boolean;
}

export class MediaMarker {
  constructor(public type: string, public x: number, public y: number) {}

  id?: string;
  width?: number;
  height?: number;
  caption?: string;
  content?: string;
  mediaId?: string;
}

class File {
  url: string = '';
  mimetype: string = '';
  originalName: string = '';
  length: number = 0;
}

class PanoramaOptions {
  public defaultView?: Position;
  public defaultZoom?: number;
  public sphereCorrection?: { tilt: number; roll: number };
}

class VideoOptions {
  public highlights?: {
    startTime: number;
    endTime: number;
    id: string;
    text: string;
  }[];
}

export type MediaOptions<T extends MediaType> =
  T extends typeof MEDIATYPES.PANORAMA
    ? PanoramaOptions
    : T extends typeof MEDIATYPES.VIDEO
    ? VideoOptions
    : undefined;
// : T extends typeof MEDIATYPES.VIDEO
// ? VideoOptions
// : T extends typeof MEDIATYPES.PDF
// ? PDFOptions
// Options;

//TODO: tidy this up once full page images and videos implemented and options for each known

// interface Options {
//   [key: string]: string;
// }
// interface ImageOptions extends Options {
//   imageName: string;
// }
// interface VideoOptions extends Options {
//   videoName: string;
// }

// interface PDFOptions extends Options {
//   pdfName: string;
// }
