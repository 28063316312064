import { Component, OnInit, Input, NgZone, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import OpenSeadragon, { Point, Viewer } from 'openseadragon';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit, OnChanges {
  @Input() url: string = '';
  @Input() modal: boolean = false;
  viewer?: Viewer;
  center?: Point;
  zoom?: number;

  constructor(
    private ngZone: NgZone,
    private _router: Router,
    private _route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this._route.queryParams.subscribe((params) => {
      this.zoom = params['zoom'];
      this.center = new Point(Number(params['x']), Number(params['y']));
    });
    this.setImage();
  }

  ngOnChanges(): void {
    this.changeImage();
  }

  changeImage(): void {
    this.viewer?.addTiledImage({
      tileSource: {
        type: 'image',
        url: this.url,
      },
      index: 0,
      replace: true,
    });
  }

  setImage(): void {
    this.viewer = this.ngZone.runOutsideAngular(() =>
      OpenSeadragon({
        id: 'openseadragonViewer',
        prefixUrl: '/assets/openseadragon/images/',
        defaultZoomLevel: 0.9,
        minZoomLevel: 0.5,
        maxZoomLevel: 5,
        showNavigationControl: false,
        visibilityRatio: 0.7,
        zoomPerClick: 1,
        animationTime: 1,
        tileSources: {
          type: 'image',
          url: this.url,
        },
      })
    );
    this.viewer?.viewport.fitHorizontally();

    this.viewer?.addHandler('animation-finish', () => {
      const zoom = this.viewer?.viewport.getZoom();
      const position = this.viewer?.viewport.getCenter(true);
      if (zoom) this.zoom = zoom;
      if (position)
        this.center = new Point(Number(position.x), Number(position.y));
      this.updateUrl();
    });

    this.viewer?.addHandler('open', () => {
      if (this.zoom !== undefined && this.center !== undefined) {
        this.viewer?.viewport.panTo(this.center);
        this.viewer?.viewport.zoomTo(this.zoom);
      }
    });
  }

  updateUrl(): void {
    const center = this.center;
    const zoom = this.zoom;
    if (center !== undefined && zoom !== undefined) {
      this._router.navigate(['./'], {
        queryParams: {
          zoom: zoom,
          x: center.x,
          y: center.y,
        },
        queryParamsHandling: 'merge',
        relativeTo: this._route,
        replaceUrl: true,
      });
    }
  }
}
