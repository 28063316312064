import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';
import { TitleService } from 'src/app/title.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login-screen',
  templateUrl: './login-screen.component.html',
  styleUrls: ['./login-screen.component.scss'],
})
export class LoginScreenComponent implements OnInit {
  showForm: boolean = false;
  heroCounter: number = 1;
  imageSrc: string = '../../../assets/hero-images/hero1.jpg';

  constructor(
    private _auth: AuthService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _title: TitleService
  ) {}

  ngOnInit(): void {
    this._title.setTitle('Login');
    this._route.queryParamMap.subscribe((qParams) => {
      if (qParams.has('jwt')) {
        this.login(String(qParams.get('jwt')), qParams.get('redirect') || '/');
      } else {
        this.showForm = true;
      }
    });

    setInterval(() => {
      this.heroCounter === 10 ? (this.heroCounter = 1) : this.heroCounter++;
      this.imageSrc = `../../../assets/hero-images/hero${this.heroCounter}.jpg`;
    }, 10000);
  }

  login(token: string, redirect: string) {
    this._auth
      .login(token)
      .pipe(
        catchError(() => {
          this.showForm = true;
          return throwError(() => new Error('Could not login.'));
        })
      )
      .subscribe(() => {
        this._router.navigateByUrl(redirect);
      });
  }
}
