import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MEDIATYPES } from 'src/app/classes/Media';
import { Project } from 'src/app/classes/Project';
import { ProjectService } from '../project.service';

@Component({
  selector: 'app-project-view-video',
  templateUrl: './project-view-video.component.html',
  styleUrls: ['./project-view-video.component.scss'],
})
export class ProjectViewVideoComponent implements OnInit {
  project?: Project;
  dataUrl: string = '';
  mediaId: string = '';
  thumbnailUrl: string = '';
  cueId: string | null = null;

  constructor(
    private _project: ProjectService,
    private _route: ActivatedRoute,
    private _router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this._route.paramMap.subscribe(async (paramMap) => {
      this.mediaId = paramMap.get('mediaId') ?? '';
      await this.setDataUrl();
    });

    this._route.queryParamMap.subscribe((qParams) => {
      this.cueId = qParams.get('cue') ?? '';
    });

    if (this._project.currentProject) this.setDataUrl();
  }

  async setDataUrl(): Promise<void> {
    if (this._project.currentProject) {
      const currentProject = this._project.currentProject;
      const media = currentProject.media.find(
        (media) => media.uuid === this.mediaId
      );

      if (media?.type === MEDIATYPES.VIDEO && media?.file?.url) {
        const url = media.file.url;
        const thumbnailUrl = media?.thumbnail ?? '';
        this.thumbnailUrl = await this._project.getMediaUrl(
          currentProject,
          thumbnailUrl
        );
        this.dataUrl = await this._project.getMediaUrl(currentProject, url);
      }
    }
  }

  cueIdUpdated(cueId: string | null) {
    this._router.navigate(['.'], {
      queryParams: { cue: cueId },
      queryParamsHandling: 'merge',
      relativeTo: this._route,
    });
  }
}
