export class Share {
  public emails: string[] = [];
  revoked: boolean = false;
  message: string = '';
  notification: boolean = true;
  url?: string;

  constructor(
    readonly project_id: string,
    readonly expiry: Date | string,
    readonly _id?: string
  ) {}
}
