<div [ngClass]="modal ? 'modalView' : 'videoView'">
  <vg-player (onPlayerReady)="onPlayerReady($event)" class="player">
    <vg-buffering></vg-buffering>

    <vg-controls>
      <vg-play-pause></vg-play-pause>

      <vg-scrub-bar>
        <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
        <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
        <vg-scrub-bar-cue-points
          [vgCuePoints]="$any(textTrack?.cues)"
        ></vg-scrub-bar-cue-points>
      </vg-scrub-bar>

      <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>

      <vg-mute></vg-mute>
      <vg-volume></vg-volume>

      <vg-fullscreen></vg-fullscreen>
    </vg-controls>
    <video
      [vgMedia]="$any(media)"
      #media
      id="singleVideo"
      preload="metadata"
      [src]="url"
      type="video/*"
    ></video>
  </vg-player>

  <button
    class="highlightModalButton"
    (click)="toggleHighlightModal()"
    *ngIf="!showHighlightModal"
  >
    Highlights
  </button>
  <div class="highlightModal" *ngIf="showHighlightModal">
    <div class="header">
      <h2>Highlights</h2>
      <fa-icon [icon]="faXmark" (click)="toggleHighlightModal()"></fa-icon>
    </div>

    <ul *ngIf="highlights.length > 0">
      <li
        *ngFor="let highlight of highlights"
        (click)="goToHighlight(highlight.id)"
      >
        <div
          [ngClass]="highlight.id === currentCueId ? 'currentHighlight' : ''"
        >
          <span>{{ highlight.startTime | toMmss }} - {{ highlight.text }}</span>
          <button (click)="removeHighlight(highlight.id)">
            <fa-icon [icon]="faXmark"></fa-icon>
          </button>
        </div>
      </li>
    </ul>

    <div class="addHighlight">
      <h3>Add new highlight:</h3>
      <div class="setTimestamp">
        <div class="rowItemsCenter">
          <button (click)="setHighlightStart()">Set start</button>
          <span>{{ this.highlightStart | toMmss }}</span>
        </div>
        <div class="rowItemsCenter">
          <button (click)="setHighlightEnd()">Set end</button>
          <span>{{ this.highlightEnd | toMmss }}</span>
        </div>
      </div>
      <div class="rowItemsCenter">
        <input
          type="text"
          [(ngModel)]="highlightName"
          placeholder="Highlight Label"
        />
        <button (click)="createNewHighlight()">
          <fa-icon [icon]="faPlus"></fa-icon>
        </button>
      </div>
    </div>
  </div>
</div>
