import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Feature, Point } from '@turf/helpers';
import { Media, MediaType } from 'src/app/classes/Media';
import { ProjectService } from 'src/app/projects/project.service';

@Component({
  selector: 'app-modal-geolocation',
  templateUrl: './modal-geolocation.component.html',
  styleUrls: ['./modal-geolocation.component.scss'],
})
export class ModalGeolocationComponent implements OnInit {
  @Input() heading: string = '';
  @Input() show: boolean = false;
  @Input() media?: Media<MediaType>;
  @Output() showChange: EventEmitter<boolean> = new EventEmitter();
  @Output() mediaChange: EventEmitter<Media<MediaType>> = new EventEmitter();
  latitude: string = '';
  latitudeDegrees: string = '';
  latitudeMinutes: string = '';
  latitudeSeconds: string = '';
  longitude: string = '';
  longitudeDegrees: string = '';
  longitudeMinutes: string = '';
  longitudeSeconds: string = '';
  hemisphereNorthSouth: string = 'north';
  hemisphereEastWest: string = 'east';
  showError: boolean = false;
  tab: string = 'degrees';

  constructor(private _project: ProjectService) {}

  ngOnInit(): void {
    this.heading = `Geolocate ${this.media?.name}`;
  }

  async updateMedia(): Promise<void> {
    const projectId = this._project.currentProject?._id;
    const media = this.media;

    if (!this.showError && projectId && media) {
      let latitude = Number(this.latitude);
      if (this.hemisphereNorthSouth === 'south') latitude = latitude * -1;
      let longitude = Number(this.longitude);
      if (this.hemisphereEastWest === 'west') longitude = longitude * -1;

      const geolocation: Feature<Point> = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: [longitude, latitude],
        },
      };

      media.geolocation = geolocation;

      await this._project.updateMedia(projectId, media);
      this.mediaChange.emit(media);
      this.closeModal();
    }
  }

  validate(): void {
    const regex = /^-?\d+\.?\d*$/;
    if (!this.longitude) {
      this.showError = !regex.test(this.latitude);
    } else if (!this.latitude) {
      this.showError = !regex.test(this.longitude);
    } else if (this.latitude && this.longitude) {
      this.showError = !(
        regex.test(this.latitude) && regex.test(this.longitude)
      );
    }
  }

  handleTabClick(tab: string): void {
    this.tab = tab;
    this.recalculateInputs(tab);
  }

  setLatitudeLongitude(): void {
    switch (this.tab) {
      case 'degrees':
        this.latitude = this.latitudeDegrees;
        this.longitude = this.longitudeDegrees;
        break;
      case 'minutes':
        this.latitude = (
          Number(this.latitudeDegrees) +
          Number(this.latitudeMinutes) / 60
        ).toString();
        this.longitude = (
          Number(this.longitudeDegrees) +
          Number(this.longitudeMinutes) / 60
        ).toString();
        break;
      case 'seconds':
        this.latitude = (
          Number(this.latitudeDegrees) +
          Number(this.latitudeMinutes) / 60 +
          Number(this.latitudeSeconds) / 3600
        ).toString();
        this.longitude = (
          Number(this.longitudeDegrees) +
          Number(this.longitudeMinutes) / 60 +
          Number(this.longitudeSeconds) / 3600
        ).toString();
        break;
      default:
        break;
    }
  }

  recalculateInputs(tab: string): void {
    let latitude: number, longitude: number;
    switch (tab) {
      case 'degrees':
        this.latitudeDegrees = this.latitude;
        this.longitudeDegrees = this.longitude;
        break;
      case 'minutes':
        latitude = Number(this.latitude);
        if (latitude) {
          this.latitudeDegrees = Math.floor(latitude).toString();
          this.latitudeMinutes = ((latitude - Math.floor(latitude)) * 60)
            .toFixed(4)
            .toString();
        }
        longitude = Number(this.longitude);
        if (longitude) {
          this.longitudeDegrees = Math.floor(longitude).toString();
          this.longitudeMinutes = ((longitude - Math.floor(longitude)) * 60)
            .toFixed(4)
            .toString();
        }
        break;
      case 'seconds':
        latitude = Number(this.latitude);
        if (latitude) {
          this.latitudeDegrees = Math.floor(latitude).toString();
          this.latitudeMinutes = Math.floor(
            (latitude - Math.floor(latitude)) * 60
          ).toString();
          const latitudeMinutes = (latitude - Math.floor(latitude)) * 60;
          this.latitudeSeconds = (
            (latitudeMinutes - Math.floor(latitudeMinutes)) *
            60
          )
            .toFixed(2)
            .toString();
        }
        longitude = Number(this.longitude);
        if (longitude) {
          this.longitudeDegrees = Math.floor(longitude).toString();
          this.longitudeMinutes = Math.floor(
            (longitude - Math.floor(longitude)) * 60
          ).toString();
          const longitudeMinutes = (longitude - Math.floor(longitude)) * 60;
          this.longitudeSeconds = (
            (longitudeMinutes - Math.floor(longitudeMinutes)) *
            60
          )
            .toFixed(2)
            .toString();
        }
        break;
      default:
        break;
    }
  }

  changeHemisphere(hemisphere: string): void {
    if (hemisphere === 'ns') {
      this.hemisphereNorthSouth =
        this.hemisphereNorthSouth === 'north' ? 'south' : 'north';
    } else {
      this.hemisphereEastWest =
        this.hemisphereEastWest === 'east' ? 'west' : 'east';
    }
  }

  closeModal(): void {
    this.showChange.emit(false);
  }
}
