import { Component, Input, OnChanges } from '@angular/core';
import { Project } from 'src/app/classes/Project';
import { marked } from 'marked';
import { AuthService } from 'src/app/auth/auth.service';
import { ProjectService } from '../project.service';

@Component({
  selector: 'app-project-view-introduction',
  templateUrl: './project-view-introduction.component.html',
  styleUrls: ['./project-view-introduction.component.scss'],
})
export class ProjectViewIntroductionComponent implements OnChanges {
  @Input() project?: Project;
  description: string = '';
  descriptionRaw: string = '';
  edit: boolean = false;
  readonly: boolean = true;

  constructor(private _auth: AuthService, private _project: ProjectService) {
    this.readonly = this._auth.role !== 'admin' && this._auth.role !== 'user';
  }

  ngOnChanges(): void {
    if (this.project) {
      this._project.setShowProjectDescription(this.project._id || '', false);
    }
    this.description = marked.parse(this.project?.description || '');
  }

  save(markdown: string): void {
    if (this.project) {
      this._project
        .update(this.project._id || '', { description: markdown })
        .subscribe(() => {
          if (this.project?.description) {
            this.project.description = markdown;
            this.edit = false;
          }
        });
    }
  }
}
