import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  APP_INITIALIZER,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import * as Sentry from '@sentry/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CasesComponent } from './cases/cases.component';
import { CaseListComponent } from './cases/case-list/case-list.component';
import { CaseListItemComponent } from './cases/case-list-item/case-list-item.component';
import { CaseFormComponent } from './cases/case-form/case-form.component';
import { ProjectsComponent } from './projects/projects.component';
import { ProjectListComponent } from './projects/project-list/project-list.component';
import { ProjectListItemComponent } from './projects/project-list-item/project-list-item.component';
import { ProjectFormComponent } from './projects/project-form/project-form.component';
import { ProjectShareFormComponent } from './projects/project-share-form/project-share-form.component';
import { LoginScreenComponent } from './auth/login-screen/login-screen.component';
import { LoginFormComponent } from './auth/login-form/login-form.component';
import { UsersComponent } from './users/users.component';
import { UserFormComponent } from './users/user-form/user-form.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { UserListItemComponent } from './users/user-list-item/user-list-item.component';
import { HeaderComponent } from './components/header/header.component';
import { HeaderMenuComponent } from './components/header-menu/header-menu.component';
import { LogoutScreenComponent } from './auth/logout-screen/logout-screen.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthInterceptor } from './auth/auth.interceptor';
import { PanoramaComponent } from './components/panorama/panorama.component';
import { ProjectViewComponent } from './projects/project-view/project-view.component';
import { PanoramaListComponent } from './components/panorama-list/panorama-list.component';
import { ProjectViewPanoramaComponent } from './projects/project-view-panorama/project-view-panorama.component';
import { ShareComponent } from './share/share.component';
import { ShareFormComponent } from './share/share-form/share-form.component';
import { ModalComponent } from './components/modal/modal.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EditorWindowComponent } from './components/editor-window/editor-window.component';
import { EditorWindowLinkComponent } from './components/editor-window-link/editor-window-link.component';
import { EditorWindowTextComponent } from './components/editor-window-text/editor-window-text.component';
import { MediaUploadFormComponent } from './components/media-upload-form/media-upload-form.component';
import { MediaUploadPreviewComponent } from './components/media-upload-preview/media-upload-preview.component';
import { ProjectViewIntroductionComponent } from './projects/project-view-introduction/project-view-introduction.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MarkdownComponent } from './components/markdown/markdown.component';
import { EditorWindowPhotoComponent } from './components/editor-window-photo/editor-window-photo.component';
import { ModalMarkersComponent } from './components/modal-markers/modal-markers.component';
import { ModalMarkersTextComponent } from './components/modal-markers-text/modal-markers-text.component';
import { ModalMarkersPhotoComponent } from './components/modal-markers-photo/modal-markers-photo.component';
import { ModalMarkersDeleteComponent } from './components/modal-markers-delete/modal-markers-delete.component';
import { EditorWindowPdfComponent } from './components/editor-window-pdf/editor-window-pdf.component';
import { ModalMarkersPdfComponent } from './components/modal-markers-pdf/modal-markers-pdf.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import {
  tooltipVariation,
  popperVariation,
  TippyModule,
} from '@ngneat/helipopper';
import { OwnerInputComponent } from './components/owner-input/owner-input.component';
import { OwnerProfileWidgetComponent } from './components/owner-profile-widget/owner-profile-widget.component';
import { OwnerProfileFullComponent } from './components/owner-profile-full/owner-profile-full.component';
import { UserSearchPipe } from './pipes/user-search.pipe';
import { EditorWindowVideoComponent } from './components/editor-window-video/editor-window-video.component';
import { ModalMarkersVideoComponent } from './components/modal-markers-video/modal-markers-video.component';
import { PanoramaSidebarComponent } from './components/panorama-sidebar/panorama-sidebar.component';
import { ContextMenuComponent } from './components/context-menu/context-menu.component';
import { LayoutComponent } from './layout/layout.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MapComponent } from './components/map/map.component';
import { MapInsetComponent } from './components/map-inset/map-inset.component';
import { ProjectViewMapComponent } from './projects/project-view-map/project-view-map.component';
import { MarkerListComponent } from './components/marker-list/marker-list.component';
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
import { NgxMatomoRouterModule } from '@ngx-matomo/router';
import { environment } from 'src/environments/environment';
import { EditHorizonComponent } from './components/edit-horizon/edit-horizon.component';
import { EditorWindowMapComponent } from './components/editor-window-map/editor-window-map.component';
import { EditorMapLayersComponent } from './components/editor-map-layers/editor-map-layers.component';
import { GetMediaByIdPipe } from './pipes/get-media-by-id.pipe';
import { GetMediaByTypePipe } from './pipes/get-media-by-type.pipe';
import { GetMediaWithGeolocationPipe } from './pipes/get-media-with-geolocation.pipe';
import { GetMarkersByTypePipe } from './pipes/get-markers-by-type.pipe';
import { EditorMapFeatureComponent } from './components/editor-map-feature/editor-map-feature.component';
import { CaseSearchPipe } from './pipes/case-search.pipe';
import { MediaUploadConfirmationComponent } from './components/media-upload-confirmation/media-upload-confirmation.component';
import { ProjectViewImageComponent } from './projects/project-view-image/project-view-image.component';
import { ImageComponent } from './components/image/image.component';
import { VideoComponent } from './components/video/video.component';
import { ProjectViewVideoComponent } from './projects/project-view-video/project-view-video.component';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { PdfComponent } from './components/pdf/pdf.component';
import { ProjectViewPdfComponent } from './projects/project-view-pdf/project-view-pdf.component';
import { ProjectViewGalleryComponent } from './projects/gallery/project-view-gallery/project-view-gallery.component';
import { GalleryListComponent } from './projects/gallery/gallery-list/gallery-list.component';
import { GalleryGridComponent } from './projects/gallery/gallery-grid/gallery-grid.component';
import { GalleryGridItemComponent } from './projects/gallery/gallery-grid-item/gallery-grid-item.component';
import { GalleryListItemComponent } from './projects/gallery/gallery-list-item/gallery-list-item.component';
import { GalleryStoryListComponent } from './projects/gallery/gallery-story-list/gallery-story-list.component';
import { GalleryStoryGridComponent } from './projects/gallery/gallery-story-grid/gallery-story-grid.component';
import { ToMmssPipe } from './pipes/to-mmss.pipe';
import { StoryEditorComponent } from './stories/story-editor/story-editor.component';
import { StoryComponent } from './stories/story.component';
import { StoryViewComponent } from './stories/story-view/story-view.component';
import { MediaSearchPipe } from './pipes/media-search.pipe';
import { MediaSortPipe } from './pipes/media-sort.pipe';
import { ModalGeolocationComponent } from './components/modal-geolocation/modal-geolocation.component';
import { MediaPropertiesPanelComponent } from './projects/gallery/media-properties-panel/media-properties-panel.component';

@NgModule({
  declarations: [
    AppComponent,
    CasesComponent,
    CaseListComponent,
    CaseListItemComponent,
    CaseFormComponent,
    ProjectsComponent,
    ProjectListComponent,
    ProjectListItemComponent,
    ProjectFormComponent,
    ProjectShareFormComponent,
    LoginScreenComponent,
    LoginFormComponent,
    UsersComponent,
    UserFormComponent,
    UserListComponent,
    UserListItemComponent,
    HeaderComponent,
    HeaderMenuComponent,
    LogoutScreenComponent,
    PanoramaComponent,
    ProjectViewComponent,
    PanoramaListComponent,
    ProjectViewPanoramaComponent,
    ShareComponent,
    ShareFormComponent,
    ModalComponent,
    EditorWindowComponent,
    EditorWindowLinkComponent,
    EditorWindowTextComponent,
    MediaUploadFormComponent,
    MediaUploadPreviewComponent,
    ProjectViewIntroductionComponent,
    MarkdownComponent,
    EditorWindowPhotoComponent,
    ModalMarkersComponent,
    ModalMarkersTextComponent,
    ModalMarkersPhotoComponent,
    ModalMarkersDeleteComponent,
    EditorWindowPdfComponent,
    ModalMarkersPdfComponent,
    OwnerInputComponent,
    OwnerProfileWidgetComponent,
    OwnerProfileFullComponent,
    UserSearchPipe,
    EditorWindowVideoComponent,
    ModalMarkersVideoComponent,
    PanoramaSidebarComponent,
    ContextMenuComponent,
    LayoutComponent,
    MapComponent,
    MapInsetComponent,
    ProjectViewMapComponent,
    MarkerListComponent,
    EditHorizonComponent,
    EditorWindowMapComponent,
    EditorMapLayersComponent,
    GetMediaByIdPipe,
    GetMediaByTypePipe,
    GetMediaWithGeolocationPipe,
    GetMarkersByTypePipe,
    EditorMapFeatureComponent,
    CaseSearchPipe,
    MediaUploadConfirmationComponent,
    ProjectViewImageComponent,
    ImageComponent,
    VideoComponent,
    ProjectViewVideoComponent,
    PdfComponent,
    ProjectViewPdfComponent,
    ProjectViewGalleryComponent,
    GalleryListComponent,
    GalleryGridComponent,
    GalleryGridItemComponent,
    GalleryListItemComponent,
    GalleryStoryListComponent,
    GalleryStoryGridComponent,
    StoryEditorComponent,
    ToMmssPipe,
    StoryComponent,
    StoryViewComponent,
    MediaSearchPipe,
    MediaSortPipe,
    ModalGeolocationComponent,
    MediaPropertiesPanelComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    CKEditorModule,
    PdfJsViewerModule,
    BrowserModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    TippyModule.forRoot({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
      },
    }),
    BrowserAnimationsModule,
    NgxSpinnerModule,
    NgxMatomoTrackerModule.forRoot({
      trackerUrl: 'https://stats.xwaves.co.uk',
      siteId: environment.trackingSiteId,
    }),
    NgxMatomoRouterModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
