<app-modal
  [(show)]="show"
  heading="{{ heading }}"
  (showChange)="closeModal()"
  type="small"
>
  <p>Enter a Latitude and Longitude.</p>
  <div class="format-selector">
    <div class="tabs">
      <div
        class="tab selector"
        [ngClass]="{
          'current-tab': tab === 'degrees'
        }"
        (click)="handleTabClick('degrees')"
        tippy="Degrees"
        placement="top"
        [delay]="[500, null]"
      >
        Deg
      </div>
      <div
        class="tab selector"
        [ngClass]="{
          'current-tab': tab === 'minutes'
        }"
        (click)="handleTabClick('minutes')"
        tippy="Minutes"
        placement="top"
        [delay]="[500, null]"
      >
        Min
      </div>
      <div
        class="tab selector"
        [ngClass]="{
          'current-tab': tab === 'seconds'
        }"
        (click)="handleTabClick('seconds')"
        tippy="Seconds"
        placement="top"
        [delay]="[500, null]"
      >
        Sec
      </div>
      <div class="tab empty"></div>
    </div>
  </div>
  <div class="latitude inputs">
    <div class="degrees input">
      <input
        [ngClass]="{
          'degrees-input': tab === 'degrees',
          'minutes-input': tab === 'minutes',
          'seconds-input': tab === 'seconds'
        }"
        type="text"
        id="latitude-degrees"
        (input)="setLatitudeLongitude()"
        (change)="validate()"
        [(ngModel)]="latitudeDegrees"
      />
    </div>
    <div class="minutes input">
      <input
        [ngClass]="{
          'degrees-input': tab === 'degrees',
          'minutes-input': tab === 'minutes',
          'seconds-input': tab === 'seconds'
        }"
        type="text"
        id="latitude-minutes"
        (input)="setLatitudeLongitude()"
        (change)="validate()"
        [(ngModel)]="latitudeMinutes"
      />
    </div>
    <div class="seconds input">
      <input
        [ngClass]="{
          'degrees-input': tab === 'degrees',
          'minutes-input': tab === 'minutes',
          'seconds-input': tab === 'seconds'
        }"
        type="text"
        id="latitude-seconds"
        (input)="setLatitudeLongitude()"
        (change)="validate()"
        (input)="setLatitudeLongitude()"
        [(ngModel)]="latitudeSeconds"
      />
    </div>
    <div class="hemisphere selector" (click)="changeHemisphere('ns')">
      <div class="north" *ngIf="hemisphereNorthSouth === 'north'">N</div>
      <div class="south" *ngIf="hemisphereNorthSouth === 'south'">S</div>
    </div>
  </div>
  <div class="longitude inputs">
    <div class="degrees input">
      <input
        [ngClass]="{
          'degrees-input': tab === 'degrees',
          'minutes-input': tab === 'minutes',
          'seconds-input': tab === 'seconds'
        }"
        type="text"
        id="longitude-seconds"
        (input)="setLatitudeLongitude()"
        (change)="validate()"
        [(ngModel)]="longitudeDegrees"
      />
    </div>
    <div class="minutes input">
      <input
        [ngClass]="{
          'degrees-input': tab === 'degrees',
          'minutes-input': tab === 'minutes',
          'seconds-input': tab === 'seconds'
        }"
        type="text"
        id="longitude-seconds"
        (change)="validate()"
        [(ngModel)]="longitudeMinutes"
      />
    </div>
    <div class="seconds input">
      <input
        [ngClass]="{
          'degrees-input': tab === 'degrees',
          'minutes-input': tab === 'minutes',
          'seconds-input': tab === 'seconds'
        }"
        type="text"
        id="longitude-seconds"
        (change)="validate()"
        [(ngModel)]="longitudeSeconds"
      />
    </div>
    <div class="hemisphere selector" (click)="changeHemisphere('ew')">
      <div class="east" *ngIf="hemisphereEastWest === 'east'">E</div>
      <div class="west" *ngIf="hemisphereEastWest === 'west'">W</div>
    </div>
  </div>
  <div class="error-placeholder">
    <p class="error" *ngIf="showError">
      Enter valid Lat and Long in decimal degrees.
    </p>
  </div>
  <div class="actions">
    <button type="button" (click)="closeModal()">Cancel</button>
    <button type="submit" (click)="updateMedia()">Save</button>
  </div>
</app-modal>
