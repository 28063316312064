import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
})
export class ContextMenuComponent implements OnInit {
  @Output() contextMenuItemClick: EventEmitter<{
    event: MouseEvent;
    data: string;
  }> = new EventEmitter<{ event: MouseEvent; data: string }>();
  @Input() type: string = '';
  @Input() id: string = '';
  @Input() allowGeolocation: boolean = false;
  contextMenuItems: string[] = [];

  ngOnInit(): void {
    switch (this.type) {
      case 'addMarker':
        this.contextMenuItems = ['Link', 'Text', 'Photo', 'Video', 'PDF'];
        break;
      case 'editMarker':
        this.contextMenuItems = ['Delete', 'Move', 'Edit'];
        if (this.id.startsWith('link')) this.contextMenuItems.pop();
        break;
      case 'map-marker':
        this.contextMenuItems = ['Move', 'Edit', 'Delete', 'Copy Coordinates'];
        break;
      case 'map-geojson':
        this.contextMenuItems = [
          'Move Feature',
          'Move Layer',
          'Show/Hide Title',
          'Edit Coordinates',
          'Edit Style',
          'Delete Feature',
          'Download Feature',
          'Copy Coordinates',
        ];
        break;
      case 'map':
        this.contextMenuItems = ['Copy Coordinates', 'Show Layers'];
        break;
      case 'galleryGridView':
        this.contextMenuItems = ['Edit Name', 'Edit Date or Time', 'Delete'];
        break;
      case 'galleryListView':
        this.contextMenuItems = ['Edit Name', 'Edit Date or Time'];
        break;
      default:
        break;
    }
    if (this.allowGeolocation) this.contextMenuItems.splice(2, 0, 'Geolocate');
  }

  onContextMenuClick(event: MouseEvent, data: string): void {
    this.contextMenuItems = [];
    this.contextMenuItemClick.emit({
      event,
      data,
    });
  }
}
