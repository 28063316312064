import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { Project } from 'src/app/classes/Project';
import { Share } from 'src/app/classes/Share';
import { environment } from 'src/environments/environment';
import { ShareService } from '../share.service';

@Component({
  selector: 'app-share-form',
  templateUrl: './share-form.component.html',
  styleUrls: ['./share-form.component.scss'],
})
export class ShareFormComponent implements OnChanges {
  @Output() shareSubmitted: EventEmitter<Share> = new EventEmitter();
  @Output() shareByLinkEvent: EventEmitter<Date | null> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();
  @Input() project!: Project;
  type: 'email' | 'link' = 'email';
  newEmail: string = '';
  emails: string[] = [];
  expiry: Date;
  maxDate: Date;
  minDate: Date;
  message: string = '';
  sendLink: boolean = true;
  displayError: boolean = false;
  emailError: boolean = false;
  shareUrl: string = '';
  showDateError: boolean = false;

  constructor(private _share: ShareService) {
    const defaultExpiry = new Date();
    defaultExpiry.setDate(defaultExpiry.getDate() + 24);
    this.expiry = defaultExpiry;
    this.maxDate = defaultExpiry;
    this.minDate = new Date();
  }

  ngOnChanges(): void {
    this.shareUrl = `${environment.webUri}/share/${this.project._id}`;
  }

  addEmail() {
    const emailRegex = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
    if (this.newEmail.match(emailRegex)) {
      this.emailError = false;
      this.emails.push(String(this.newEmail));
      this.newEmail = '';
    } else {
      this.emailError = true;
    }
  }

  share() {
    if (!this.showDateError) {
      if (this.emails.length > 0 && this.project._id) {
        const share = new Share(this.project._id, new Date(this.expiry));
        share.emails = this.emails;
        share.message = this.message;

        this._share.add(share).subscribe((newShare) => {
          this.shareSubmitted.emit(newShare);
        });
      } else {
        this.displayError = true;
      }
    }
  }

  cancelShare() {
    this.cancel.emit();
  }

  shareByLink() {
    this.shareByLinkEvent.emit(this.expiry);
    this.shareUrl = `${environment.webUri}/share/${this.project._id}`;
  }

  cancelShareByLink() {
    this.shareByLinkEvent.emit(null);
    this.shareUrl = ``;
  }

  validateDate() {
    if (new Date(this.expiry).getTime() > Date.now() + 1209600000) {
      this.showDateError = true;
    } else {
      this.showDateError = false;
    }
  }
}
