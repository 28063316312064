<!-- no stories added -->
<div
  *ngIf="
    _stories.storyComponentMode.secondary === 'view-list' &&
    (!_stories.stories || _stories.stories.length <= 0)
  "
>
  <p>No stories added</p>
</div>

<!-- stories list -->
<div
  *ngIf="_stories.storyComponentMode.secondary === 'view-list'"
  style="overflow: auto; flex: 1"
>
  <div
    *ngFor="let story of _stories.stories"
    style="margin-left: 1em; margin-bottom: 2em"
    class="story-panel"
  >
    <h2 style="padding-bottom: 1em">{{ story.name }}</h2>
    <p>{{ story.description }}</p>
    <button
      class="bold-btn"
      (click)="openActiveStory(story.storyId)"
      style="margin-right: 0"
    >
      Open Story
    </button>
  </div>
</div>

<!-- add new story button -->
<div
  style="width: 100%; justify-content: flex-end; padding: 1em"
  class="flex-row"
  *ngIf="_stories.storyComponentMode.secondary === 'view-list'"
>
  <button class="bold-btn" style="margin-top: auto" (click)="openAddNewStory()">
    Add New Story
  </button>
</div>

<!-- panel list content -->
<div
  *ngIf="_stories.storyComponentMode.secondary === 'view-active'"
  class="story-content"
>
  <!-- heading -->
  <div
    class="flex-row"
    style="gap: 0.5em; margin-bottom: 0.5em; color: grey"
    (click)="closeActiveStory()"
  >
    <fa-icon [icon]="faArrowLeft"></fa-icon>
    <span style="text-transform: uppercase">story list</span>
  </div>
  <h2 style="margin-bottom: 1em">{{ _stories.selectedStory?.name }}</h2>

  <!-- no panels available -->
  <div
    *ngIf="_stories.selectedStory && _stories.selectedStory.panels.length === 0"
    class="flex-col"
    style="flex: 1; align-items: center; justify-content: center"
  >
    <p>No panels available for this story</p>
  </div>

  <!-- panels -->
  <div
    class="panel-list"
    *ngIf="_stories.selectedStory && _stories.selectedStory.panels.length > 0"
    #PanelList
  >
    <div
      class="story-panel"
      *ngFor="let panel of _stories.selectedStory.panels; let i = index"
      [ngClass]="_stories.viewPanelIdx === i ? 'story-panel-active' : ''"
    >
      <fa-icon
        [icon]="faXmark"
        style="
          position: absolute;
          top: 0;
          right: 0;
          margin: 1em;
          cursor: pointer;
        "
        *ngIf="i === _stories.viewPanelIdx"
        (click)="_stories.resetViewPanelIdx()"
      ></fa-icon>
      <div class="flex-row" style="gap: 0.5em; align-items: baseline">
        <fa-icon [icon]="getPanelIcon(panel.mediaUrl)"></fa-icon>
        <h3 (click)="setActivePanel(i)">{{ panel.heading }}</h3>
      </div>
      <p *ngIf="i === _stories.viewPanelIdx" class="panel-content">
        {{ panel.content }}
      </p>
    </div>
  </div>
  <!-- panel list footer -->
  <div
    class="panel-list-footer"
    *ngIf="_stories.storyComponentMode.secondary === 'view-active'"
  >
    <p
      *ngIf="_stories.selectedStory && _stories.viewPanelIdx !== null"
      style="font-weight: bold"
    >
      {{ _stories.viewPanelIdx + 1 }}/{{ _stories.selectedStory.panels.length }}
    </p>
    <div
      class="flex-row"
      style="margin-left: auto"
      *ngIf="_stories.selectedStory && _stories.viewPanelIdx !== null"
    >
      <button (click)="showMedia()" class="bold-btn mobile-only">Show</button>
      <button
        class="bold-btn"
        (click)="_stories.decreaseViewPanelIdx()"
        *ngIf="
          _stories.selectedStory &&
          _stories.viewPanelIdx &&
          _stories.viewPanelIdx > 0
        "
      >
        Prev
      </button>
      <button
        class="bold-btn"
        (click)="_stories.increaseViewPanelIdx()"
        *ngIf="
          _stories.selectedStory &&
          _stories.viewPanelIdx !== null &&
          _stories.viewPanelIdx < _stories.selectedStory.panels.length - 1
        "
      >
        Next
      </button>
    </div>
  </div>
</div>
