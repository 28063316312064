<div *ngIf="edit; else noEdit">
  <ckeditor
    [editor]="Editor"
    [config]="config"
    [(ngModel)]="markdown"
    (change)="onEditorChange($event)"
  ></ckeditor>
  <button *ngIf="!autoSave" (click)="save()" class="save">Save</button>
</div>

<ng-template #noEdit>
  <button *ngIf="!readonly" (click)="activateEdit()" class="edit">Edit</button>
  <div [innerHTML]="parsed"></div>
</ng-template>
