import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ProjectService } from 'src/app/projects/project.service';

@Component({
  selector: 'app-editor-window-photo',
  templateUrl: './editor-window-photo.component.html',
  styleUrls: ['./editor-window-photo.component.scss'],
})
export class EditorWindowPhotoComponent implements OnInit {
  files: File[] = [];
  @Input() image!: File;
  @Output() newCaptionEvent = new EventEmitter<string>();
  @Output() newContentEvent = new EventEmitter<string>();
  @Output() newFilesEvent = new EventEmitter<File[]>();
  @Input() caption: string = '';
  @Input() content: string = '';
  @Input() currentFiles: Array<string> = [];
  url?: string;
  src?: SafeUrl;

  constructor(
    private _project: ProjectService,
    private _sanitize: DomSanitizer
  ) {}

  ngOnInit(): void {
    if (this.currentFiles.length) {
      this._project
        .getMediaBlob(
          `projects/${this._project.currentProject?._id}/media/${this.currentFiles[0]}`
        )
        .subscribe((res) => {
          this.src = this._sanitize.bypassSecurityTrustUrl(
            URL.createObjectURL(res)
          );
        });
    }
  }

  imagesChanged(event: Event & { target: EventTarget | null }) {
    const target = event.target as HTMLInputElement;
    const fileInput = target.files;

    const inputItem = fileInput?.item(0);
    if (fileInput && inputItem) {
      this.files.push(inputItem);
    }

    this.url = URL.createObjectURL(this.files[0]);
    this.src = this._sanitize.bypassSecurityTrustUrl(this.url);
    return this.newFilesEvent.emit(this.files);
  }

  onCaptionChange() {
    return this.newCaptionEvent.emit(this.caption);
  }

  onContentChange() {
    return this.newContentEvent.emit(this.content);
  }

  OnDestroy(): void {
    if (this.url) {
      URL.revokeObjectURL(this.url);
    }
  }
}
