<div class="text-section">
  <div class="caption">
    <p><label for="caption">Caption</label></p>
    <input
      type="text"
      name="caption"
      id="caption"
      placeholder="Caption"
      [(ngModel)]="caption"
      (change)="onCaptionChange()"
      maxlength="30"
    />
    <div class="error-placeholder">
      <p class="error" *ngIf="caption.length === 30">Maximum 30 characters</p>
    </div>
  </div>
  <div class="content">
    <p><label for="content">Content</label></p>
    <app-markdown
      [(markdown)]="content"
      (markdownChange)="onContentChange()"
      [readonly]="false"
      [edit]="true"
      [autoSave]="true"
    ></app-markdown>
  </div>
</div>
