import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsAdminGuard } from './auth/is-admin.guard';
import { IsGuestGuard } from './auth/is-guest.guard';
import { IsUserGuard } from './auth/is-user.guard';
import { LoginScreenComponent } from './auth/login-screen/login-screen.component';
import { LogoutScreenComponent } from './auth/logout-screen/logout-screen.component';
import { CasesComponent } from './cases/cases.component';
import { LayoutComponent } from './layout/layout.component';
import { ProjectViewGalleryComponent } from './projects/gallery/project-view-gallery/project-view-gallery.component';
import { ProjectViewImageComponent } from './projects/project-view-image/project-view-image.component';
import { ProjectViewMapComponent } from './projects/project-view-map/project-view-map.component';
import { ProjectViewPanoramaComponent } from './projects/project-view-panorama/project-view-panorama.component';
import { ProjectViewPdfComponent } from './projects/project-view-pdf/project-view-pdf.component';
import { ProjectViewVideoComponent } from './projects/project-view-video/project-view-video.component';
import { ProjectViewComponent } from './projects/project-view/project-view.component';
import { ProjectsComponent } from './projects/projects.component';
import { ShareComponent } from './share/share.component';
import { UsersComponent } from './users/users.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: 'cases', pathMatch: 'full' },
      {
        path: 'logout',
        component: LogoutScreenComponent,
        canActivate: [IsGuestGuard],
      },
      {
        path: 'cases',
        component: CasesComponent,
        canActivate: [IsUserGuard],
      },
      {
        path: 'cases/:caseId',
        component: ProjectsComponent,
        canActivate: [IsUserGuard],
      },
      { path: 'share/:projectId', component: ShareComponent },
      {
        path: 'users',
        component: UsersComponent,
        canActivate: [IsAdminGuard],
      },
    ],
  },
  { path: 'login', component: LoginScreenComponent },
  {
    path: 'cases/:caseId/:projectId',
    component: ProjectViewComponent,
    children: [
      {
        path: 'panorama/:mediaId',
        component: ProjectViewPanoramaComponent,
      },
      { path: 'map', component: ProjectViewMapComponent },
      { path: 'image/:mediaId', component: ProjectViewImageComponent },
      { path: 'video/:mediaId', component: ProjectViewVideoComponent },
      { path: 'pdf/:mediaId', component: ProjectViewPdfComponent },
      { path: 'gallery', component: ProjectViewGalleryComponent },
    ],
  },
  {
    path: 'share/new/:projectId',
    component: ShareComponent,
    canActivate: [IsUserGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
