import { Params } from '@angular/router';
import { FeatureCollection } from '@turf/helpers';
import { Media, MediaMarker, MediaType } from './Media';
import { Share } from './Share';

export function getMediaByType(
  project: Project | undefined,
  type: string
): ProjectMedia<MediaType>[] {
  return (
    project?.media.filter((value) => {
      return value.type === type;
    }) || []
  );
}

export function getMediaById(
  project: Project | undefined,
  id: string
): ProjectMedia<MediaType> {
  const media = project?.media.find((value) => {
    return value.uuid === id;
  });
  if (media) {
    return media;
  } else {
    throw new Error('Media not found');
  }
}

export function getMediaWithGeolocation(
  project: Project | undefined
): ProjectMedia<MediaType>[] {
  return (
    project?.media.filter((value) => {
      return value.geolocation ? true : false;
    }) || []
  );
}

export function getMarkersByType(
  media: ProjectMedia<MediaType>,
  type: string
): MediaMarker[] {
  return (
    media?.markers.filter((value) => {
      return value.type === type;
    }) || []
  );
}

// stops Media<"text"|"photo"|"pdf"|...> and gives Media<"text">|Media<"photo">|...
export type ProjectMedia<T> = T extends MediaType ? Media<T> : never;

export type Panel = {
  panelId: string;
  mediaUrl: string;
  heading: string;
  content: string;
  queryParams: Params | undefined;
};

export type Story = {
  storyId: string;
  name: string;
  description: string;
  panels: Panel[];
};

export class Project {
  constructor(
    public case_id: string,
    public name: string,
    public _id?: string
  ) {}

  public default_view: string | null = null;
  public archived: boolean = false;
  public media: ProjectMedia<MediaType>[] = [];
  public shares: Share[] = [];
  public shared: Date | null = null;
  public owner_name?: string;
  public owner_id?: string;
  public description?: string;
  public created_at?: Date;
  public updated_at?: Date;
  public map_features?: Array<{
    name: string;
    visible: boolean;
    featureCollection: FeatureCollection;
  }>;
  public stories?: Story[];
}
