<div class="horizon">
  <div class="horizon-slider">
    <p>Set the horizon tilt</p>
    <input
      type="range"
      min="-1.6"
      max="1.6"
      step="0.05"
      [value]="tilt"
      [(ngModel)]="tilt"
      class="slider"
      (ngModelChange)="updateHorizon()"
    />
  </div>
  <div class="horizon-slider">
    <p>Set the horizon roll</p>
    <input
      type="range"
      min="-3.15"
      max="3.15"
      step="0.05"
      [value]="roll"
      [(ngModel)]="roll"
      class="slider"
      (ngModelChange)="updateHorizon()"
    />
  </div>
  <div class="actions">
    <button type="button" (click)="closeEditModal()">Cancel</button>
    <button type="button" (click)="save()">Save</button>
  </div>
</div>
