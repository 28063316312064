<app-modal
  [(show)]="show"
  heading="{{ heading }}"
  (showChange)="closeModal()"
  type="large"
>
  <div class="placeholder" *ngIf="url">
    <div class="photo-section" *ngIf="dataUrl">
      <app-image [url]="dataUrl" [modal]="true"></app-image>
    </div>
    <div class="loading" *ngIf="!dataUrl">
      <img
        src="../../../assets/logos/logo-full-trans-small.png"
        alt="loading"
      />
    </div>
  </div>
  <div class="text-section" *ngIf="content">
    <p>{{ content }}</p>
  </div>
</app-modal>
