<div class="grid" *ngIf="mediaList">
  <div
    *ngFor="
      let media of mediaList
        | mediaSearch : searchText
        | mediaSort : sortBy : sortDescending
    "
    class="item"
    [ngClass]="{ 'display-properties-panel': propertiesPanelId === media.uuid }"
  >
    <app-gallery-grid-item
      [media]="media"
      [selected]="selection.includes(media?.uuid || '')"
      (selectedChange)="updateSelected($event)"
      (propertiesPanelChange)="updatePropertiesPanel($event)"
      [propertiesPanelId]="propertiesPanelId"
      [isEditingGridCard]="isEditingGridCard"
      (isEditingGridCardChange)="updateIsEditingGridCard($event)"
      (tabEditChange)="tabToEditNextGridCard($event)"
      [editNextGridCard]="editNextGridCard"
    ></app-gallery-grid-item>
  </div>
</div>
