import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { User } from '../classes/User';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private _api: ApiService, private _http: HttpClient) {}

  list() {
    return this._http.get<User[]>(this._api.getUri(`users`));
  }

  add(user: User): Observable<User> {
    return this._http
      .post<{ acknowledged: boolean; insertedId?: string }>(
        this._api.getUri('users'),
        user
      )
      .pipe(
        map((response) => {
          return new User(
            user.email,
            user.name,
            user.role,
            response.insertedId
          );
        })
      );
  }

  get(userId: string): Observable<User> {
    return this._http.get<User>(this._api.getUri('users/' + userId));
  }

  photo(userId: string, photo: string) {
    return this._http.get(
      this._api.getUri('users/' + userId + '/photo/' + photo),
      { responseType: 'blob' }
    );
  }
}
