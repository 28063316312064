import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { StoryService } from './story.service';

@Component({
  selector: 'app-story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.scss'],
})
export class StoryComponent implements OnInit, OnDestroy {
  @Output() closePanel: EventEmitter<void> = new EventEmitter();

  constructor(public _stories: StoryService) {}

  ngOnInit(): void {
    this._stories.storyOverlayActive = true;
    this._stories.setStories();
    this._stories.storiesUpdated.subscribe(() => {
      this._stories.setStories();
    });
  }

  ngOnDestroy(): void {
    this._stories.storyOverlayActive = false;
  }
  
  switchTab(tabName: string) {
    switch (tabName) {
      case 'view':
        this._stories.viewPanelIdx = null;
        this._stories.editPanelIdx = null;
        this._stories.storyComponentMode = {
          primary: 'view',
          secondary: 'view-list',
        };
        break;
      case 'edit':
        if (!this._stories.editPanelIdx) {
          this._stories.editPanelIdx = this._stories.viewPanelIdx;
        }

        this._stories.storyComponentMode = {
          primary: 'edit',
          secondary: 'edit-list',
        };
        break;
    }
  }

  handlePanelClose() {
    this.closePanel.emit();
  }
}
