<app-modal
  [(show)]="show"
  heading="Delete {{ marker?.data.heading }}?"
  (showChange)="closeModal()"
>
  <p>Are you sure you want to delete {{ marker?.data.heading }}?</p>
  <div class="actions">
    <button type="button" (click)="closeModal()">Cancel</button>
    <button type="submit" (click)="deleteMarker()">Delete</button>
  </div>
</app-modal>
