import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { AuthService } from './auth.service';
import { AuthLogin } from '../classes/AuthLogin';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private _auth: AuthService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const authReq = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this._auth.jwt}`,
        Pragma: 'no-cache',
        Expires: '0',
        'Cache-Control': 'no-cache',
      },
    });
    return next.handle(authReq).pipe(
      tap({
        next: (event) => {
          if (event instanceof HttpResponse) {
          }
          if (
            event instanceof HttpResponse &&
            event.headers.has('x-refresh-user')
          ) {
            try {
              const user = JSON.parse(
                event.headers.get('x-refresh-user') || '{}'
              ) as AuthLogin;
              this._auth.saveUserToStorage(user);
            } catch (err) {
              console.error('refreshToken', err);
            }
          }
        },
      })
    );
  }
}
