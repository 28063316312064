import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { ProjectService } from 'src/app/projects/project.service';
import { ProjectMedia } from 'src/app/classes/Project';
import { MediaType } from 'src/app/classes/Media';
import { ActivatedRoute, Router } from '@angular/router';
import { MediaUploadReturn } from 'src/app/classes/MediaUploadReturn';
@Component({
  selector: 'app-media-upload-form',
  templateUrl: './media-upload-form.component.html',
  styleUrls: ['./media-upload-form.component.scss'],
})
export class MediaUploadFormComponent {
  @Input() show: boolean = true;
  @Input() projectId!: string | null;
  @Output() showChange: EventEmitter<boolean> = new EventEmitter();
  @Output() mediaUploaded: EventEmitter<ProjectMedia<MediaType>[]> =
    new EventEmitter();
  files: File[] = [];
  uploadForm: FormGroup;
  faTrash = faTrash;
  uploading: boolean = false;
  currentUploadFile: string = '';
  currentUploadIndex: number = 0;

  constructor(
    public fb: FormBuilder,
    private _project: ProjectService,
    private _router: Router,
    private _route: ActivatedRoute
  ) {
    this.uploadForm = this.fb.group({
      images: [null],
    });
  }

  async imagesChanged(event: Event & { target: EventTarget | null }) {
    const target = event.target as HTMLInputElement;
    const fileInput = target.files;
    let index = 0;
    const fileCount = fileInput?.length ?? 0;
    do {
      const inputItem = fileInput?.item(index);
      if (inputItem) {
        this.files.push(inputItem);
      }
      index++;
    } while (index < fileCount);
  }

  async upload() {
    this.uploading = true;
    this.currentUploadIndex = 0;

    for (const file of this.files) {
      this.currentUploadFile = file.name;
      const newMedia = await this.uploadFile(this.projectId ?? '', file);
      this.mediaUploaded.emit(newMedia.accepted);
      this._project.projectUpdated.emit();
      this.currentUploadIndex++;
    }

    this.showChange.emit(false);
    this._router.navigate(['gallery'], { relativeTo: this._route });
  }

  uploadFile(projectId: string, file: File): Promise<MediaUploadReturn> {
    return new Promise((resolve) => {
      this._project.addMedia(projectId || '', [file]).subscribe((newMedia) => {
        resolve(newMedia);
      });
    });
  }

  cancel() {
    this.showChange.emit(false);
  }

  removeImage(index: number) {
    this.files.splice(index, 1);
  }
}
