import { Component, Input } from '@angular/core';
import { Case } from 'src/app/classes/Case';
import { CaseService } from '../case.service';

@Component({
  selector: 'app-case-list',
  templateUrl: './case-list.component.html',
  styleUrls: ['./case-list.component.scss'],
})
export class CaseListComponent {
  @Input() cases: Case[] = [];
  @Input() showingArchivedCases: boolean = false;
  @Input() searchText: string = '';

  constructor(private _case: CaseService) {}

  getCases() {
    this._case.list(false).subscribe((cases) => {
      this.cases = this.sortCases(cases);
    });
  }

  sortCases(cases: Case[]) {
    cases.sort((a, b) => {
      if (a.created_at && b.created_at) {
        return (
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      } else if (a.hasOwnProperty('created_at')) {
        return -1;
      } else if (b.hasOwnProperty('created_at')) {
        return 1;
      } else return 0;
    });
    return cases;
  }

  archiveCase(archiveCaseId: string) {
    const index = this.cases.findIndex((item) => item._id === archiveCaseId);
    this.cases[index].archived = true;

    if (!this.showingArchivedCases) {
      this.cases = this.cases.filter((item) => {
        return item._id !== archiveCaseId;
      });
    }
  }

  restoreCase(restoreCaseId: string) {
    const index = this.cases.findIndex((item) => item._id === restoreCaseId);
    this.cases[index].archived = false;
  }
  renameCase(event: { id: string; update: Case }) {
    const index = this.cases.findIndex((item) => item._id === event.id);
    this.cases[index].name = event.update.name;
    this.cases[index].code = event.update.code;
  }
}
