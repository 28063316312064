<app-modal
  [(show)]="show"
  heading="{{ heading }}"
  (showChange)="closeModal()"
  type="large"
>
  <div class="placeholder" *ngIf="url">
    <app-pdf class="pdf-viewer" [url]="dataUrl"></app-pdf>
    <div class="loading" *ngIf="!dataUrl">
      <img
        src="../../../assets/logos/logo-full-trans-small.png"
        alt="loading"
      />
    </div>
  </div>
</app-modal>
