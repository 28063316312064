import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private apiUri: string = environment.apiUri;

  public getUri(path: string) {
    return `${this.apiUri}/${path}`;
  }
}
