<ul class="menu">
  <li *ngFor="let case of cases | caseSearch : searchText">
    <app-case-list-item
      [case]="case"
      (restoreCaseEvent)="restoreCase($event)"
      (archiveCaseEvent)="archiveCase($event)"
      (renameCaseEvent)="renameCase($event)"
    >
    </app-case-list-item>
  </li>
</ul>
