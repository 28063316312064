import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ProjectService } from 'src/app/projects/project.service';
import { ActivatedRoute } from '@angular/router';
import { getMediaByType, ProjectMedia } from 'src/app/classes/Project';
import { MediaType } from 'src/app/classes/Media';

@Component({
  selector: 'app-editor-window-link',
  templateUrl: './editor-window-link.component.html',
  styleUrls: ['./editor-window-link.component.scss'],
})
export class EditorWindowLinkComponent implements OnInit {
  @Output() newSelectEvent = new EventEmitter<string>();
  projectPanoramas?: ProjectMedia<MediaType>[] = [];
  panoramaUuid: string = '';
  selectedPanoramaId?: string;

  constructor(
    private _project: ProjectService,
    private _route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.projectPanoramas = getMediaByType(
      this._project.currentProject,
      'panorama'
    );
    this._route.params.subscribe((params) => {
      this.panoramaUuid = params['mediaId'];
    });
  }

  selectPanorama(panoId?: string) {
    if (panoId) {
      this.selectedPanoramaId = panoId;
      this.newSelectEvent.emit(panoId);
    }
  }
}
