import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { ApiService } from '../api.service';
import { ProjectService } from '../projects/project.service';

@Injectable({
  providedIn: 'root',
})
export class StoryService {
  public storiesUpdated: EventEmitter<void> = new EventEmitter();
  public changeViewPanelIdx: EventEmitter<number> = new EventEmitter();
  public stories?: Story[];
  public selectedStoryId?: string;
  public selectedStory: Story | undefined = undefined;
  public viewPanelIdx: number | null = null;
  public editPanelIdx: number | null = null;
  public editPanelId: string | null = null;
  public storyOverlayActive: boolean = false;

  public storyComponentMode: StoryComponentMode = {
    primary: 'view',
    secondary: 'view-list',
  };

  constructor(
    private _api: ApiService,
    private _http: HttpClient,
    private _project: ProjectService
  ) {}

  getStories(projectId: string): Promise<Story[]> {
    return new Promise((resolve) => {
      this._http
        .get<Story[]>(this._api.getUri(`projects/${projectId}/stories`))
        .subscribe((res) => resolve(res));
    });
  }

  async createStory(projectId: string, stories: Story[]) {
    return new Promise((resolve) => {
      this._http
        .post<{
          addedIds: string[];
          updatedIds: string[];
          failedIds: string[];
        }>(this._api.getUri(`projects/${projectId}/stories`), stories)
        .subscribe((res) => resolve(res));
    }).then(() => this.storiesUpdated.emit());
  }

  async updateStory(
    projectId: string,
    storyId: string,
    storyUpdate: Partial<Story>
  ) {
    return new Promise((resolve) => {
      this._http
        .post<{ message: string }>(
          this._api.getUri(`projects/${projectId}/stories/${storyId}`),
          storyUpdate
        )
        .subscribe((res) => resolve(res));
    }).then(() => this.storiesUpdated.emit());
  }

  async deleteStory(projectId: string, storyId: string) {
    return new Promise((resolve) => {
      this._http
        .delete<{
          successIds: string[];
          failedIds: string[];
        }>(this._api.getUri(`projects/${projectId}/stories/${storyId}`))
        .subscribe((res) => resolve(res));
    }).then(() => this.storiesUpdated.emit());
  }

  getPanels(projectId: string, storyId: string) {
    return new Promise((resolve) => {
      this._http
        .get<Panel[]>(
          this._api.getUri(`projects/${projectId}/stories/${storyId}/panels`)
        )
        .subscribe((res) => resolve(res));
    });
  }

  async createPanels(panels: Panel[]) {
    const projectId = this._project.currentProject?._id;
    const storyId = this.selectedStory?.storyId;

    return new Promise((resolve) => {
      this._http
        .post<{
          addedIds: string[];
          updatedIds: string[];
          failedIds: string[];
        }>(
          this._api.getUri(`projects/${projectId}/stories/${storyId}/panels`),
          panels
        )
        .subscribe((res) => resolve(res));
    }).then(() => this.storiesUpdated.emit());
  }

  async updatePanel(panelId: string, panelUpdates: Partial<Panel>) {
    const projectId = this._project.currentProject?._id;
    const storyId = this.selectedStory?.storyId;
    return new Promise((resolve) => {
      this._http
        .post<{ updatedId: string; failedIds: string }>(
          this._api.getUri(
            `projects/${projectId}/stories/${storyId}/panels/${panelId}`
          ),
          panelUpdates
        )
        .subscribe((res) => resolve(res));
    }).then(() => this.storiesUpdated.emit());
  }

  async deletePanel(panelId: string) {
    const projectId = this._project.currentProject?._id;
    const storyId = this.selectedStory?.storyId;

    return new Promise((resolve) => {
      this._http
        .delete<{ successIds: string[]; failedIds: string[] }>(
          this._api.getUri(
            `projects/${projectId}/stories/${storyId}/panels/${panelId}`
          )
        )
        .subscribe((res) => resolve(res));
    }).then(() => this.storiesUpdated.emit());
  }

  async reorderPanel(
    projectId: string,
    storyId: string,
    panelId: string,
    newPosition: number
  ) {
    return new Promise((resolve) => {
      this._http
        .post<{ message: string }>(
          this._api.getUri(
            `projects/${projectId}/stories/${storyId}/panels/${panelId}/reorder/${newPosition}`
          ),
          {}
        )
        .subscribe((res) => resolve(res));
    }).then(() => this.storiesUpdated.emit());
  }

  async setStories() {
    const projectId = this._project.currentProject?._id;
    if (!projectId) throw new Error('No projectId');

    this.stories = await this.getStories(projectId);
    if (this.stories?.length > 0) {
      this.setSelected();
    }
  }

  selectedStoryChange(selectedStoryId: string) {
    this.selectedStoryId = selectedStoryId;
    this.setSelected();
  }

  setSelected() {
    const projectId = this._project.currentProject?._id;
    if (projectId) {
      if (!this.selectedStoryId) {
        this.selectedStoryId = this.stories?.[0].storyId;
      }
      this.selectedStory = this.stories?.find(
        (s) => s.storyId === this.selectedStoryId
      );
    }
  }

  increaseViewPanelIdx() {
    if (
      (this.selectedStory &&
        this.viewPanelIdx === this.selectedStory?.panels.length - 1) ||
      this.viewPanelIdx === null
    )
      return;

    this.viewPanelIdx += 1;
    this.changeViewPanelIdx.emit(this.viewPanelIdx);
  }

  decreaseViewPanelIdx() {
    if (this.viewPanelIdx === 0 || this.viewPanelIdx === null) return;

    this.viewPanelIdx -= 1;
    this.changeViewPanelIdx.emit(this.viewPanelIdx);
  }

  resetViewPanelIdx() {
    this.viewPanelIdx = null;
  }

  getPanelId(idx: number) {
    if (this.selectedStory) {
      return this.selectedStory.panels[idx].panelId;
    } else {
      return null;
    }
  }

  setStoryEditMode(mode: StoryEditMode) {
    this.storyComponentMode = { primary: 'edit', secondary: mode };
  }

  setStoryViewMode(mode: StoryViewMode) {
    this.storyComponentMode = { primary: 'view', secondary: mode };
  }
  openAddNewStory() {
    this.storyComponentMode = { primary: 'edit', secondary: 'edit-new-story' };
  }
}

export type Panel = {
  panelId: string;
  mediaUrl: string;
  heading: string;
  content: string;
  queryParams: Params | undefined;
};

export type Story = {
  storyId: string;
  name: string;
  description: string;
  panels: Panel[];
};

type StoryPanelModes = ['view', 'edit'];
export type StoryPanelMode = StoryPanelModes[number];

type StoryViewModes = ['view-list', 'view-active'];

type StoryViewMode = StoryViewModes[number];

type StoryEditModes = [
  'edit-list',
  'edit-story-details',
  'edit-new-story',
  'edit-confirm-delete-story',
  'edit-panel',
  'edit-add-panel'
];

type StoryEditMode = StoryEditModes[number];

export type StoryComponentMode =
  | {
      primary: Extract<StoryPanelMode, 'view'>;
      secondary: StoryViewMode;
    }
  | {
      primary: Extract<StoryPanelMode, 'edit'>;
      secondary: StoryEditMode;
    };
