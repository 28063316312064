import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
  faFont,
  faStreetView,
  faImage,
  faVideo,
  faFilePdf,
} from '@fortawesome/free-solid-svg-icons';
import { Position } from 'photo-sphere-viewer';
import { ProjectService } from 'src/app/projects/project.service';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MediaMarker } from 'src/app/classes/Media';
import { MediaUploadReturn } from 'src/app/classes/MediaUploadReturn';

@Component({
  selector: 'app-editor-window',
  templateUrl: './editor-window.component.html',
  styleUrls: ['./editor-window.component.scss'],
})
export class EditorWindowComponent implements OnInit {
  @Output() showingEditor = new EventEmitter<boolean>();
  @Output() markersAdded = new EventEmitter<MediaMarker[]>();
  showEditor: boolean = false;
  deletingMarker: boolean = false;
  editingMarkerId: string = '';
  editingMarkerIndex: number = 0;
  currentFiles: Array<string> = [];
  type: string = 'link';
  positionData?: Position;
  linkToMediaKey?: string;
  mediaId: string = '';
  caption: string = '';
  content: string = '';
  files: File[] = [];
  faFont = faFont;
  faStreetView = faStreetView;
  faImage = faImage;
  faVideo = faVideo;
  faFilePdf = faFilePdf;

  constructor(
    private _project: ProjectService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {}

  async ngOnInit(): Promise<void> {
    this._project.panoClickEvent.subscribe(
      (data) => (this.positionData = data)
    );
    this._project.markerDeleted.subscribe(
      (deleting) => (this.deletingMarker = deleting)
    );
    this._project.toggleEditorWindow.subscribe((show) => {
      this.showEditor = show;
      this.editingMarkerId = this._project.editingMarkerId;
      if (this.editingMarkerId) this.setEditingMarker(this.editingMarkerId);
    });
    this._project.editorWindowType.subscribe((type) => (this.type = type));
  }

  setEditingMarker(id: string) {
    const media = this._project.currentProject?.media.filter(
      (media) => media.uuid === this.mediaId
    )[0];
    const markerIndex = Number(id.slice(id.indexOf('-') + 1));

    if (media && markerIndex !== undefined) {
      this.editingMarkerIndex = markerIndex;
      const marker = media.markers[markerIndex];

      this.type = marker?.type ? marker.type : 'text';
      this.content = marker?.content ?? '';
      this.caption = marker?.caption ?? '';
      this.positionData = {
        latitude: marker?.y ?? 0,
        longitude: marker?.x ?? 0,
      };
    }
  }

  async save(): Promise<void> {
    this.spinner.show();
    this._project.projectUpdated.subscribe(() => this.spinner.hide());
    const media = this._project.currentProject?.media;
    const projectId = this._project.currentProject?._id;
    let mediaUploadReturn;

    if (this.files.length && projectId) {
      mediaUploadReturn = await this.addMedia(projectId, this.files);
      this._project.currentProject?.media.push(mediaUploadReturn.accepted[0]);
    }

    if (media && projectId) {
      const updatedMarker = media.filter(
        (item) => item.uuid === this.mediaId
      )[0].markers[this.editingMarkerIndex] as MediaMarker & {
        files: string[];
      };

      updatedMarker.caption = this.caption;

      if (this.type === 'text' || this.type === 'photo') {
        updatedMarker.content = this.content;
      }

      if (mediaUploadReturn)
        updatedMarker.mediaId = mediaUploadReturn.accepted[0].uuid;

      await this._project.updateMarkers(
        projectId,
        this.mediaId,
        updatedMarker,
        this.editingMarkerIndex
      );
      this.cancel();
      this._project.projectUpdated.emit();
    }
  }

  getMediaId(): Promise<string> {
    return new Promise((resolve) => {
      this.route.paramMap.subscribe((paramMap) => {
        resolve(paramMap.get('mediaId') || '');
      });
    });
  }

  toggleEditor() {
    if (this.deletingMarker) this.toggleDelete();
    this.showEditor = true;
    delete this.positionData;
    this.showingEditor.emit(true);
  }

  toggleDelete() {
    this.deletingMarker = !this.deletingMarker;
    this._project.setDeletingMarker(this.deletingMarker);
  }

  cancel() {
    this.type = 'link';
    this.showEditor = false;
    this._project.editingMarkerId = '';
    this.currentFiles = [];
    this.files = [];
    this.editingMarkerId = '';
    this.editingMarkerIndex = 0;
    this.caption = '';
    this.content = '';
    this.showingEditor.emit(false);
    delete this.positionData;
  }

  onItemChange(event: Event & { target: HTMLInputElement }) {
    this.type = event.target.value;
  }

  async add() {
    this._project.editingMarkerId = '';
    if (this.type === 'link') {
      this.addLink();
    } else if (this.type === 'text') {
      this.addText();
    } else if (['photo', 'pdf', 'video'].includes(this.type)) {
      await this.addMediaMarker(this.type);
    }
  }

  setLinkToMediaKey(id: string) {
    this.linkToMediaKey = id;
  }

  setCaption(caption: string) {
    this.caption = caption;
  }

  setContent(content: string) {
    this.content = content;
  }

  setFiles(files: File[]) {
    this.files = files;
  }

  async addLink() {
    if (
      this.positionData?.latitude &&
      this.positionData?.longitude &&
      this.linkToMediaKey !== undefined
    ) {
      const newMarker = {
        y: this.positionData?.latitude,
        x: this.positionData?.longitude,
        type: 'link',
        mediaId: this.linkToMediaKey,
      };
      return this.updateProject(newMarker);
    }
    return [];
  }

  async addText(): Promise<MediaMarker[]> {
    if (this.caption && this.positionData) {
      const newMarker = {
        content: this.content,
        y: this.positionData?.latitude,
        x: this.positionData?.longitude,
        caption: this.caption,
        type: 'text',
      };
      return this.updateProject(newMarker);
    }
    return [];
  }

  addMedia(projectId: string, files: File[]): Promise<MediaUploadReturn> {
    return new Promise((resolve) => {
      this._project.addMedia(projectId, files).subscribe((media) => {
        resolve(media);
      });
    });
  }

  async addMediaMarker(type: string): Promise<MediaMarker[]> {
    const files = this.files;
    const projectId = this._project.currentProject?._id || '';
    this.spinner.show();
    const mediaUploadReturn = await this.addMedia(projectId, files);

    if (mediaUploadReturn.accepted.length && this.positionData) {
      this._project.currentProject?.media.push(mediaUploadReturn.accepted[0]);
      const newMarker: MediaMarker = {
        content: this.content || '',
        y: this.positionData?.latitude,
        x: this.positionData?.longitude,
        caption: this.caption || '',
        mediaId: mediaUploadReturn.accepted[0].uuid,
        type: type,
      };
      return this.updateProject(newMarker);
    }
    this.spinner.hide();
    return [];
  }

  async updateProject(newMarker: MediaMarker): Promise<MediaMarker[]> {
    const projectId = this._project.currentProject?._id || '';
    this.spinner.show();
    this.mediaId = await this.getMediaId();
    const markers = await this._project.addMarkers(projectId, this.mediaId, [
      newMarker,
    ]);
    this.markersAdded.emit(markers);
    this.spinner.hide();
    this.cancel();
    return markers;
  }
}
