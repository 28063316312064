import { Component, EventEmitter, Output } from '@angular/core';
import { User } from 'src/app/classes/User';
import { UserService } from '../user.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent {
  @Output() userAdded: EventEmitter<User> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();
  userName: string = '';
  userEmail: string = '';
  userRole: 'user' | 'admin' = 'user';
  displayError: boolean = false;

  constructor(private _user: UserService) {}

  public saveUser() {
    if (this.userName && this.userEmail) {
      const newUser = new User(this.userEmail, this.userName, this.userRole);
      this._user.add(newUser).subscribe((addedUser) => {
        this.userAdded.emit(addedUser);
      });
    } else {
      this.displayError = true;
    }
  }

  closeModal() {
    this.userName = '';
    this.userEmail = '';
    this.userRole = 'user';
    this.cancel.emit();
  }
}
