import { Component, OnInit } from '@angular/core';
import { User } from '../classes/User';
import { UserService } from './user.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  users: User[] = [];
  showModal: boolean = false;

  constructor(private _user: UserService) {}

  ngOnInit(): void {
    this.getUsers();
  }

  getUsers() {
    this._user.list().subscribe((users) => (this.users = users));
  }

  userAdded(addedUser: User) {
    this.users.unshift(addedUser);
    this.showModal = false;
  }

  addUser() {
    this.showModal = true;
  }
}
