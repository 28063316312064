import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { getMediaById, ProjectMedia } from 'src/app/classes/Project';
import {
  faTrash,
  faPencil,
  faThumbTack,
  faGlobe,
  faList,
} from '@fortawesome/free-solid-svg-icons';
import { ProjectService } from 'src/app/projects/project.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { MediaType, MEDIATYPES } from 'src/app/classes/Media';

@Component({
  selector: 'app-panorama-list',
  templateUrl: './panorama-list.component.html',
  styleUrls: ['./panorama-list.component.scss'],
})
export class PanoramaListComponent {
  @Input() projectId: string | null = '';
  @Input() list?: ProjectMedia<MediaType>[] = [];
  @Output() listChange: EventEmitter<ProjectMedia<MediaType>[]> =
    new EventEmitter();
  @Input() showPanoramalist: boolean = true;
  @Output() showPanoramalistChange: EventEmitter<boolean> = new EventEmitter();
  timeout: number = 0;
  panoramaListPinned: boolean = false;
  currentMediaId?: string;
  setDefault: boolean = false;
  setDefaultView: boolean = false;
  newName: string = '';
  loggedIn: boolean;
  userRole: string;
  showUploadModal: boolean = false;
  showEditPanoramaModal: boolean = false;
  showDeletePanoramaModal: boolean = false;
  editingPanorama?: ProjectMedia<MediaType>;
  files: File[] = [];
  imageUrl: string[] = [];
  uploadForm: FormGroup;
  faTrash = faTrash;
  faPencil = faPencil;
  faThumbTack = faThumbTack;
  faGlobe = faGlobe;
  faList = faList;

  constructor(
    private _project: ProjectService,
    private _auth: AuthService,
    public fb: FormBuilder,
    private _router: Router,
    private _route: ActivatedRoute
  ) {
    this.uploadForm = this.fb.group({
      images: [null],
    });
    this.loggedIn = this._auth.email ? true : false;
    this.userRole = this._auth.role;
    this._auth.loggedIn.subscribe((isLoggedIn) => {
      this.loggedIn = isLoggedIn;
    });
    this._route.firstChild?.params.subscribe((params) => {
      const currentMediaId = params['mediaId'];
      if (currentMediaId) this.currentMediaId = currentMediaId;
    });
  }

  OnInit(): void {
    this._project.currentProjectChanged.subscribe(() => {
      this.list = this._project.currentProject?.media;
    });
  }

  setShowList(set: boolean) {
    if (set) {
      this.showPanoramalist = true;
      clearTimeout(this.timeout);
    } else if (
      !this.panoramaListPinned &&
      !this.showEditPanoramaModal &&
      !this.showDeletePanoramaModal
    ) {
      this.timeout = window.setTimeout(() => {
        this.showPanoramalist = false;
        this.showPanoramalistChange.emit(false);
      }, 1000);
    }
  }
  goToGalleryView(): void {
    this._router.navigate(['gallery'], { relativeTo: this._route });
    this.showPanoramalistChange.emit(false);
  }

  toggleListPinned() {
    this.panoramaListPinned = !this.panoramaListPinned;
  }

  panoramaUploaded(newPanorama: ProjectMedia<MediaType>[]) {
    const list = this.list || [];
    this.listChange.emit(list.concat(newPanorama));
  }

  cancel() {
    this.imageUrl = [];
    this.files = [];
    this.showUploadModal = false;
  }

  openEditPanoramaModal(id: string) {
    this.showEditPanoramaModal = true;
    this.editingPanorama =
      this._project.currentProject &&
      getMediaById(this._project.currentProject, id);
  }

  openEditHorizonModal() {
    this._project.showEditHorizonModal(true);
  }

  editPanorama(uuid: string) {
    const projectId = this._project.currentProject?._id;
    const media = this._project.currentProject?.media || [];
    const mediaIndex = media.findIndex((media) => media.uuid === uuid);
    const selectedMedia = media[mediaIndex];
    const currentPosition = this._project.currentPosition;
    const currentZoom = this._project.currentZoom;
    const newName = this.newName;

    if (selectedMedia && newName) {
      selectedMedia.name = newName;
    }

    if (this.setDefaultView && selectedMedia) {
      if (!selectedMedia.options) {
        selectedMedia.options = {};
      }
      if (selectedMedia.type === MEDIATYPES.PANORAMA) {
        selectedMedia.options.defaultView = currentPosition;
        selectedMedia.options.defaultZoom = currentZoom;
      }
    }

    if (projectId) {
      const partial = { media };
      this._project.update(projectId, partial).subscribe();
    }
    this.closeEditModal();
  }

  closeEditModal() {
    this.showEditPanoramaModal = false;
    delete this.editingPanorama;
    this.newName = '';
    this.setDefault = false;
    this.setDefaultView = false;
    if (!this.panoramaListPinned) {
      this.setShowList(false);
    }
  }

  openDeletePanoramaModal(id: string) {
    this.editingPanorama = getMediaById(this._project.currentProject, id);
    this.showDeletePanoramaModal = true;
  }

  deletePanorama(uuid: string) {
    const media = this._project.currentProject?.media || [];
    const mediaIndex = media.findIndex((media) => media.uuid === uuid);
    const projectId = this.projectId;

    media.forEach((item) => {
      if (item.type === 'panorama' && item.markers) {
        item.markers = item.markers.filter((marker) => marker.mediaId !== uuid);
      }
    });
    media.splice(mediaIndex, 1);

    if (projectId) {
      const partial = { media };
      this._project.update(projectId, partial).subscribe(() => {
        if (this._project.currentProject?.default_view) {
          this._router.navigate(
            ['.', this._project.currentProject?.default_view],
            {
              relativeTo: this._route,
            }
          );
        } else {
          this._router.navigate(['.', 'map'], {
            relativeTo: this._route,
          });
        }
      });
    }
    this.showDeletePanoramaModal = false;
  }

  cancelDelete() {
    delete this.editingPanorama;
    this.showDeletePanoramaModal = false;
  }
}
