<div class="project-info-header">
  <h2>{{ project?.name }}</h2>
</div>
<div>
  <app-markdown
    [markdown]="project?.description || 'Welcome to the project.'"
    (markdownChange)="save($event)"
    [(edit)]="edit"
    [readonly]="readonly"
  ></app-markdown>
</div>
