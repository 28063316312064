import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { faClose, faMaximize } from '@fortawesome/free-solid-svg-icons';
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [
    trigger('overlayInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('150ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('150ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class ModalComponent {
  @Input() heading: string = '';
  @Input() show: boolean = true;
  @Input() type: string = '';
  @Output() showChange: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('modalWindow') modalWindow!: ElementRef;
  faClose = faClose;
  faMaximize = faMaximize;
  isFullscreen: boolean = false;

  closeModal() {
    this.showChange.emit(false);
  }

  async fullscreen() {
    try {
      await this.modalWindow?.nativeElement.requestFullscreen();
      this.isFullscreen = true;
    } catch (err) {}
  }

  overlayCloseModal(event: Event) {
    //TODO resolve this assertion
    const target = event.target as EventTarget & { id: string };
    if (target.id === 'modal-overlay') {
      this.showChange.emit(false);
    }
  }
}
