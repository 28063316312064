<div class="panorama-sidebar" id="parent">
  <div class="logo" *ngIf="!readOnly" tippy="Back to Cases" placement="right">
    <a [routerLink]="['/']">
      <img src="/assets/logos/waves-icon-darkbg.svg" alt="Waves Group Logo" />
    </a>
  </div>
  <div class="logo" *ngIf="readOnly">
    <img src="/assets/logos/waves-icon-darkbg.svg" alt="Waves Group Logo" />
  </div>
  <button
    (click)="clickEvent('panoramas')"
    class="panorama-sidebar-button panorama-sidebar-button-top"
    tippy="Media"
    placement="right"
  >
    <fa-icon size="lg" [icon]="faLayerGroup"></fa-icon>
  </button>
  <button
    (click)="clickEvent('map')"
    class="panorama-sidebar-button"
    tippy="Map"
    placement="right"
  >
    <fa-icon size="lg" [icon]="faMap"></fa-icon>
  </button>
  <button
    (click)="clickEvent('share')"
    *ngIf="!readOnly"
    class="panorama-sidebar-button"
    tippy="Share"
    placement="right"
  >
    <fa-icon size="lg" [icon]="faShare"></fa-icon>
  </button>
  <button
    (click)="clickEvent('fullscreen')"
    class="panorama-sidebar-button"
    tippy="Fullscreen"
    placement="right"
  >
    <fa-icon size="lg" [icon]="faExpand"></fa-icon>
  </button>
  <button
    (click)="clickEvent('story')"
    class="panorama-sidebar-button panorama-sidebar-button-bottom"
    tippy="Story Mode"
    placement="right"
  >
    <fa-icon size="lg" [icon]="faBook"></fa-icon>
  </button>
</div>
