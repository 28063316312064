<div
  id="map"
  #map
  [style.width]="width"
  [style.height]="height"
  (pointerdown)="this.onMouseDown($event)"
></div>

<ngx-spinner
  bdColor="rgba(0, 0, 0, 0.5)"
  size="medium"
  color="#fff"
  type="ball-spin-clockwise"
  [fullScreen]="true"
  ><p style="color: white">Loading...</p></ngx-spinner
>

<div #tooltipContainer *ngIf="tooltipContent" id="tooltip" class="ol-tooltip">
  <div [innerHTML]="tooltipContent" id="tooltip-content"></div>
</div>

<div
  *ngIf="!inset && !showMapSettings"
  class="map-selector"
  tippy="Select maps"
  placement="left"
  [delay]="[500, null]"
  (click)="this.showMapSettings = true"
>
  <fa-icon class="fa-2x" [icon]="faLayerGroup"></fa-icon>
</div>
<div class="settings" *ngIf="!inset && showMapSettings">
  <app-editor-window-map
    [(showEditor)]="showMapSettings"
    [(graticules)]="showGraticules"
    (graticulesChange)="toggleGraticules($event)"
    [mapLayer]="mapType"
    (mapLayerChange)="changeMapLayer($event)"
  >
  </app-editor-window-map>
</div>

<div *ngIf="!inset && showEditMapLayers" class="map-layers">
  <app-editor-map-layers
    (drawTypeChange)="addDraw($event)"
    [(drawType)]="drawType"
    (showWindowChange)="showEditMapLayers = false"
    [(geojsonFeatures)]="geojsonFeatures"
    (geojsonFeaturesChange)="setGeoJson()"
    (mapCoordinates)="centerMap($event)"
    [(featureCollectionIndex)]="featureCollectionIndex"
  >
  </app-editor-map-layers>
</div>

<div *ngIf="!inset && displayEditFeature">
  <app-editor-map-feature
    [geojsonFeatures]="geojsonFeatures"
    (geojsonFeaturesChange)="setGeoJson()"
    (closeWindow)="closeEditStyleWindon($event)"
    [featureId]="featureId"
    [ngStyle]="getRightClickMenuStyle()"
  >
  </app-editor-map-feature>
</div>

<app-context-menu
  *ngIf="displayContextMenu"
  [ngStyle]="getRightClickMenuStyle()"
  class="context-menu"
  type="{{ contextMenuType }}"
  (contextMenuItemClick)="handleMenuItemClick($event)"
></app-context-menu>
<div class="wrapper">
  <div class="move-info" *ngIf="movingMarker">
    <p>Select a new postion.</p>
  </div>
</div>

<app-modal
  heading="Edit {{ this.selectedMarkerName }}"
  *ngIf="showEditMarkerModal"
  [(show)]="showEditMarkerModal"
>
  <form (ngSubmit)="editMarker()">
    <div class="form-section">
      <div class="name-input">
        <label for="newname">Name</label>
        <input
          type="text"
          name="newname"
          id="newname"
          placeholder="Enter new name"
          [(ngModel)]="newName"
          maxlength="25"
        />
        <p class="error" *ngIf="newName.length === 25">Maximum 25 characters</p>
      </div>
    </div>
    <div class="actions">
      <button type="button" (click)="cancelEdit()">Cancel</button>
      <button type="submit">Save</button>
    </div>
  </form>
</app-modal>

<app-modal
  heading="Delete {{ this.selectedMarkerName }}?"
  *ngIf="showDeleteMarkerModal"
  [(show)]="showDeleteMarkerModal"
>
  <div class="question">
    <p>
      Are you sure you want to delete <b>{{ this.selectedMarkerName }}</b
      >?
    </p>
  </div>
  <div class="actions">
    <button type="button" (click)="cancelDelete()">Cancel</button>
    <button type="button" (click)="deleteMarker()">Delete</button>
  </div>
</app-modal>
