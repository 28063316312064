<ul class="menu">
  <li *ngFor="let project of projects">
    <app-project-list-item
      [project]="project"
      (deleteProjectEvent)="deleteProject($event)"
      (restoreProjectEvent)="restoreProject($event)"
      (archiveProjectEvent)="archiveProject($event)"
      (editProjectEvent)="editProject($event)"
      (copiedProjectEvent)="copyProject($event)"
    >
    </app-project-list-item>
  </li>
</ul>
