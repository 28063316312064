import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Case } from 'src/app/classes/Case';
import { CaseService } from '../case.service';
import {
  faTrashCan,
  faBoxArchive,
  faBoxOpen,
  faPencil,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-case-list-item',
  templateUrl: './case-list-item.component.html',
  styleUrls: ['./case-list-item.component.scss'],
})
export class CaseListItemComponent {
  @Input() case?: Case;
  @Output() archiveCaseEvent = new EventEmitter<string>();
  @Output() restoreCaseEvent = new EventEmitter<string>();
  @Output() renameCaseEvent = new EventEmitter<{
    id: string;
    update: Case;
  }>();
  showModal: boolean = false;
  showEditModal: boolean = false;
  newName: string = '';
  newCaseNumber: string = '';
  faTrashCan = faTrashCan;
  faBoxArchive = faBoxArchive;
  faBoxOpen = faBoxOpen;
  faPencil = faPencil;

  constructor(private _case: CaseService) {}

  openModal() {
    this.showModal = true;
    this.showEditModal = false;
  }

  cancel() {
    this.showModal = false;
    this.showEditModal = false;
    this.newName = '';
    this.newCaseNumber = '';
  }

  archiveItem(id: string | undefined) {
    if (id)
      this._case.archive(id, true).subscribe(() => {
        this.archiveCaseEvent.emit(id);
      });
    this.showModal = false;
  }

  restoreItem(id: string | undefined) {
    if (id)
      this._case.archive(id, false).subscribe(() => {
        this.restoreCaseEvent.emit(id);
      });
    this.showModal = false;
  }

  editCase(updatedCase: Case) {
    const partial = {
      name: updatedCase.name,
      code: updatedCase.code,
    };

    if (updatedCase._id) {
      const id = updatedCase._id;
      const event = { id, update: partial };
      this._case.update(id, partial).subscribe(() => {
        this.renameCaseEvent.emit(event);
      });
    }
    this.cancel();
  }
}
