import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
  dsn: 'https://afbf0ade368d4004938cc2a134b7cd86@o1010945.ingest.sentry.io/4504293991579648',
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: [
        'localhost',
        'https://portal360.xwaves.co.uk',
        'https://portal360-dev.xwaves.co.uk',
      ],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  environment: environment.environment,
  tracesSampleRate: 0.3,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
