<ng-container>
  <div class="context-menu">
    <div
      class="menu-link"
      *ngFor="let menuItem of contextMenuItems; index as i"
      (click)="onContextMenuClick($event, menuItem)"
    >
      {{ menuItem }}
    </div>
  </div>
</ng-container>
