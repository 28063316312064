<div class="gallery-view-container" (mousedown)="onMouseDown($event)">
  <div class="toolbar">
    <div class="list-grid-toggle" (click)="toggleGalleryView('list')">
      <fa-icon
        [ngClass]="{ selected: galleryViewType === 'list' }"
        [icon]="faList"
        size="lg"
        tippy="List View"
        placement="top"
        [delay]="[500, null]"
      ></fa-icon>
    </div>
    <div class="list-grid-toggle" (click)="toggleGalleryView('grid')">
      <fa-icon
        [ngClass]="{ selected: galleryViewType === 'grid' }"
        [icon]="faGrid"
        size="lg"
        tippy="Grid View"
        placement="top"
        [delay]="[500, null]"
      ></fa-icon>
    </div>
    <div class="filter main-toolbar">
      <select
        name="share-type"
        [(ngModel)]="filter"
        (change)="setFilter()"
        class="dropdown"
        tippy="Filter"
        placement="top"
        [delay]="[500, null]"
      >
        <option value="" disabled selected hidden>Filter</option>
        <option value="panorama">Panorama</option>
        <option value="image">Image</option>
        <option value="video">Video</option>
        <option value="pdf">Pdf</option>
      </select>
    </div>
    <div class="clear-filter main-toolbar">
      <fa-icon
        (click)="clearFilter()"
        [icon]="faX"
        size="lg"
        tippy="Clear Filter"
        placement="top"
        [delay]="[500, null]"
      ></fa-icon>
    </div>
    <div class="search main-toolbar">
      <input
        [(ngModel)]="searchText"
        type="text"
        placeholder="Search Media..."
      />
    </div>
    <div class="clear-search main-toolbar">
      <fa-icon
        (click)="clearSearch()"
        [icon]="faX"
        size="lg"
        tippy="Clear Search"
        placement="top"
        [delay]="[500, null]"
      ></fa-icon>
    </div>
    <div class="selection" id="large-buttons">
      <button type="button" (click)="upload()">Upload Files</button>
      <button type="button" (click)="deleteSelection()">
        Delete Selection
      </button>
    </div>
    <div class="selection" id="small-buttons">
      <button type="button" (click)="upload()">
        <fa-icon class="small-icons" [icon]="faFileImport"></fa-icon>
      </button>
      <button type="button" (click)="deleteSelection()">
        <fa-icon class="small-icons" [icon]="faTrash"></fa-icon>
      </button>
    </div>
    <div class="clear-filter">
      <fa-icon
        (click)="clearSelection()"
        [icon]="faX"
        size="lg"
        tippy="Clear Selection"
        placement="top"
        [delay]="[500, null]"
      ></fa-icon>
    </div>
  </div>
  <div class="toolbar secondary-toolbar">
    <div class="filter">
      <select
        name="share-type"
        [(ngModel)]="filter"
        (change)="setFilter()"
        class="dropdown"
        tippy="Filter"
        placement="top"
        [delay]="[500, null]"
      >
        <option value="" disabled selected hidden>Filter</option>
        <option value="panorama">Panorama</option>
        <option value="image">Image</option>
        <option value="video">Video</option>
        <option value="pdf">Pdf</option>
      </select>
    </div>
    <div class="clear-filter">
      <fa-icon
        (click)="clearFilter()"
        [icon]="faX"
        size="lg"
        tippy="Clear Filter"
        placement="top"
        [delay]="[500, null]"
      ></fa-icon>
    </div>
    <div class="search">
      <input
        [(ngModel)]="searchText"
        type="text"
        placeholder="Search Media..."
      />
    </div>
    <div class="clear-search">
      <fa-icon
        (click)="clearSearch()"
        [icon]="faX"
        size="lg"
        tippy="Clear Search"
        placement="top"
        [delay]="[500, null]"
      ></fa-icon>
    </div>
  </div>
  <div class="gallery-and-properties">
    <div
      class="gallery-container"
      [ngClass]="{ 'show-properties': showProperties }"
    >
      <div class="gallery" [ngSwitch]="galleryViewType">
        <app-gallery-list
          *ngSwitchCase="'list'"
          [(mediaList)]="mediaList"
          [(selection)]="selection"
          (propertiesPanelChange)="openPropertiesPanel($event)"
          [propertiesPanelId]="propertiesPanelId"
          [searchText]="searchText"
          [(sortParameters)]="sortParameters"
        ></app-gallery-list>
        <app-gallery-grid
          *ngSwitchCase="'grid'"
          [(mediaList)]="mediaList"
          [(selection)]="selection"
          (propertiesPanelChange)="openPropertiesPanel($event)"
          [propertiesPanelId]="propertiesPanelId"
          [searchText]="searchText"
          [sortParameters]="sortParameters"
        ></app-gallery-grid>
      </div>
    </div>
    <div
      class="properties-container"
      [ngClass]="{ 'show-properties': showProperties }"
    >
      <app-media-properties-panel
        [showPanel]="showProperties"
        (showPanelChange)="showPropertiesChange($event)"
        [media]="propertiesPanelMedia"
      ></app-media-properties-panel>
    </div>
  </div>
</div>
