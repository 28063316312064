import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { Case } from 'src/app/classes/Case';
import { CaseService } from '../case.service';

@Component({
  selector: 'app-case-form',
  templateUrl: './case-form.component.html',
  styleUrls: ['./case-form.component.scss'],
})
export class CaseFormComponent implements OnInit {
  @Input() editing: boolean = false;
  @Input() case?: Case;
  @Output() caseAdded: EventEmitter<Case> = new EventEmitter();
  @Output() cancel: EventEmitter<void> = new EventEmitter();
  caseCategory: string = 'CW';
  caseNumber: string = '';
  caseName: string = '';
  displayError: boolean = false;
  isAdmin: boolean = false;
  isUser: boolean = false;

  constructor(private _case: CaseService, private _auth: AuthService) {}

  ngOnInit(): void {
    this.isAdmin = this._auth.role === 'admin';
    this.isUser = this._auth.role === 'user';
    if (this.case) {
      this.caseName = this.case.name;
      const caseCodeParts = this.case.code.split('-');
      this.caseCategory = caseCodeParts[0];
      this.caseNumber = caseCodeParts[1];
    }
  }

  save() {
    const caseCode = `${this.caseCategory}-${this.caseNumber}`;
    if (this.case) {
      this.case.name = this.caseName;
      this.case.code = caseCode;
      this.caseAdded.emit(this.case);
    } else {
      if (this.caseName && this.caseCategory && this.caseNumber) {
        const newCase = new Case(this.caseName, caseCode);
        this._case.add(newCase).subscribe((addedCase) => {
          this.caseAdded.emit(addedCase);
        });
      } else {
        this.displayError = true;
      }
    }
  }

  closeModal() {
    this.caseNumber = '';
    this.caseName = '';
    this.cancel.emit();
  }
}
