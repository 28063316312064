<form *ngIf="isAdmin || isUser" (ngSubmit)="save()">
  <div class="form-section">
    <div class="form-item">
      <div class="case-number">
        <label for="case-number">Number</label>
        <select
          class="case-category"
          name="case-category"
          [(ngModel)]="caseCategory"
        >
          <option>CW</option>
          <option>MW</option>
          <option>XX</option>
        </select>
        <span>-</span>
        <input
          name="case-number"
          [(ngModel)]="caseNumber"
          placeholder="0000"
          maxlength="6"
        />
      </div>
    </div>
    <div class="form-item">
      <label for="case-name">Name</label>
      <input name="case-name" [(ngModel)]="caseName" placeholder="Case Name" />
    </div>
  </div>
  <div class="placeholder">
    <div class="error" *ngIf="this.displayError">
      Enter a case name and number
    </div>
  </div>
  <div class="actions">
    <button type="button" (click)="closeModal()">Cancel</button>
    <button type="submit">{{ case?._id ? 'Save' : 'Add' }} Case</button>
  </div>
</form>
