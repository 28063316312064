<div #projectView>
  <app-modal
    [(show)]="showShareModal"
    heading="Share Project"
    *ngIf="showShareModal"
  >
    <app-share-form
      *ngIf="!shared"
      [project]="project!"
      (shareSubmitted)="projectShared($event)"
      (cancel)="showShareModal = false"
      (shareByLinkEvent)="shareByLink($event)"
    ></app-share-form>

    <div *ngIf="shared" class="form-section">
      <p>{{ project?.name || 'Project' }} is now available at</p>
      <p>
        <a [attr.href]="newShare?.url" target="_blank">{{ newShare?.url }}</a>
      </p>
      <p>until {{ newShare?.expiry | date }}</p>
      <div class="actions">
        <button (click)="continue()">Continue</button>
      </div>
    </div>
  </app-modal>

  <app-modal
    heading="Shares"
    *ngIf="showShareListModal"
    [(show)]="showShareListModal"
  >
    <div class="form-section">
      <table>
        <tr>
          <th>Expires</th>
          <th>Message</th>
        </tr>
        <tr *ngFor="let share of project?.shares">
          <td>{{ share.expiry | date }}</td>
          <td>{{ share.message }}</td>
        </tr>
      </table>
      <div class="actions">
        <button (click)="showShareListModal = false">Continue</button>
      </div>
    </div>
  </app-modal>

  <app-modal
    heading="Project Information"
    [(show)]="showDescription"
    *ngIf="showDescription"
  >
    <app-project-view-introduction
      [project]="project"
    ></app-project-view-introduction>
  </app-modal>

  <app-modal
    heading="Upload Media"
    *ngIf="showUploadModal"
    [(show)]="showUploadModal"
  >
    <app-media-upload-form
      [(show)]="showUploadModal"
      [projectId]="projectId"
      (mediaUploaded)="mediaUploaded($event)"
    ></app-media-upload-form>
  </app-modal>

  <div class="project-view">
    <div class="info">
      <app-panorama-sidebar
        (clicked)="sidebarClick($event)"
      ></app-panorama-sidebar>
      <div *ngIf="showPanoramaList" class="panorama-list">
        <app-panorama-list
          *ngIf="project"
          [(showPanoramalist)]="showPanoramaList"
          [(list)]="project.media"
          [projectId]="projectId"
        ></app-panorama-list>
      </div>
      <app-story
        *ngIf="showStoryModal"
        (closePanel)="toggleStoryModal()"
        [openMode]="openStoryMode"
      ></app-story>
    </div>
    <div class="panorama" (contextmenu)="preventContextMenu($event)">
      <div class="pano-header">
        <div class="project-title">
          <div class="name-and-info">
            <h3>{{ project?.name }}</h3>
            <button
              (click)="infoClickEvent()"
              class="info-button"
              tippy="Project Information"
              placement="right"
            >
              <fa-icon [icon]="faI"></fa-icon>
            </button>
          </div>
          <div *ngIf="projectOwner" class="project-title-owner">
            {{ projectOwner }}
          </div>
        </div>
      </div>
      <div (click)="showActionMenu = !showActionMenu" class="action-menu">
        <div class="action-menu-username">{{ userName }}</div>
        <div>
          <fa-icon
            [icon]="showActionMenu ? faCaretUp : faCaretDown"
            size="lg"
          ></fa-icon>
        </div>
      </div>
      <div class="action-menu-dropdown" *ngIf="showActionMenu">
        <a [routerLink]="['/logout']">Logout</a>
      </div>
      <router-outlet></router-outlet>
      <div
        class="story-control-mobile-container"
        *ngIf="
          _stories.selectedStory &&
          _stories.viewPanelIdx !== null &&
          _stories.selectedStory.panels.length > 0
        "
        style="color: whitesmoke"
      >
        <h2>
          {{
            this._stories.selectedStory.panels[_stories.viewPanelIdx].heading
          }}
        </h2>
        <p style="max-width: 80%" (click)="toggleStoryModal()">
          {{
            truncateString(
              this._stories.selectedStory.panels[_stories.viewPanelIdx].content,
              20
            )
          }}
        </p>
        <div class="flex-row" style="gap: 1em">
          <button
            class="bold-btn"
            (click)="_stories.decreaseViewPanelIdx()"
            *ngIf="_stories.viewPanelIdx !== 0"
          >
            prev
          </button>
          <button class="bold-btn" (click)="toggleStoryModal()">expand</button>
          <button
            class="bold-btn"
            (click)="_stories.increaseViewPanelIdx()"
            *ngIf="
              _stories.viewPanelIdx !== _stories.selectedStory.panels.length - 1
            "
          >
            next
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="mapEnabled && currentMedia" class="panorama-map-inset">
    <a class="panorama-map-fullscreen" [routerLink]="['map']"
      ><fa-icon [icon]="faMaximise"></fa-icon
    ></a>
    <app-map-inset
      [links]="mapMarkers"
      [highlight]="currentMedia"
    ></app-map-inset>
  </div>
</div>
