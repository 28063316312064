<ng2-pdfjs-viewer
  *ngIf="url"
  viewerId="pdfViewer"
  [pdfSrc]="url"
  [page]="page"
  (onPageChange)="onPageChange($event)"
  (onDocumentLoad)="documentLoaded()"
  class="pdf-viewer"
  #pdfviewer
>
</ng2-pdfjs-viewer>
