<form *ngIf="isAdmin || isUser" (ngSubmit)="saveProject()">
  <div class="form-section">
    <div class="form-item">
      <label for="project-name">Name</label>
      <input
        type="text"
        name="project-name"
        maxlength="25"
        [(ngModel)]="projectName"
      />
    </div>
    <div class="form-item">
      <label
        >Owner
        <span *ngIf="projectOwner" class="clear-owner" (click)="clearOwner()"
          >Clear</span
        ></label
      >
      <app-owner-input [(userId)]="projectOwner"></app-owner-input>
    </div>
  </div>
  <div class="placeholder">
    <div class="error" *ngIf="this.displayError">Enter a project name</div>
  </div>
  <div class="actions">
    <button type="button" (click)="closeModal()">Cancel</button>
    <button type="submit">{{ project?._id ? 'Save' : 'Add' }} Project</button>
  </div>
</form>
