import { Component, OnInit } from '@angular/core';
import { ProjectService } from 'src/app/projects/project.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  showProjectTitle: boolean = false;
  projectName: string | undefined = '';
  constructor(private _project: ProjectService, private router: Router) {}

  ngOnInit(): void {
    this._project.currentProjectChanged.subscribe((project) => {
      this.projectName = project.name;
    });
    this.checkRoute();
    this.router.events.subscribe(() => {
      this.showProjectTitle = this.checkRoute();
    });
  }

  checkRoute() {
    const route = this.router.url;
    const check = route.substring(
      route.lastIndexOf('/') - 8,
      route.lastIndexOf('/')
    );
    return check === 'panorama';
  }
}
