<div class="map-settings-window">
  <div class="editor">
    <div class="title">
      <h4>Map Settings</h4>
    </div>
    <form class="form-section">
      <h4>Select a Map Layer</h4>
      <div class="map-layers">
        <input
          type="radio"
          id="openStreetMap"
          name="mapLayer"
          value="openStreetMap"
          [(ngModel)]="mapLayer"
          (ngModelChange)="changeMapLayer('openStreetMap')"
        />
        <label class="label" for="openStreetMap">Open Street Map</label>
        <br />
        <input
          type="radio"
          id="bingSatellite"
          name="mapLayer"
          value="bingSatellite"
          [(ngModel)]="mapLayer"
          (ngModelChange)="changeMapLayer('bingSatellite')"
        />
        <label class="label" for="bingSatellite">Bing Satellite</label>
        <br />
        <input
          type="checkbox"
          id="graticules"
          name="graticules"
          [(ngModel)]="graticules"
          (ngModelChange)="toggleGraticules()"
        />
        <label class="label" for="graticules">Map Graticules</label>
      </div>
      <div class="actions">
        <button type="button" (click)="close()">Close</button>
      </div>
    </form>
  </div>
</div>
