import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  EventEmitter,
  Output,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  faCaretDown,
  faCaretUp,
  faExpandAlt,
  faInfo,
} from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'src/app/auth/auth.service';
import {
  getMediaWithGeolocation,
  Project,
  ProjectMedia,
} from 'src/app/classes/Project';
import { Share } from 'src/app/classes/Share';
import { TitleService } from 'src/app/title.service';
import { ProjectService } from '../project.service';
import { MatomoTracker } from '@ngx-matomo/tracker';

import { trigger, style, animate, transition } from '@angular/animations';
import { MediaType } from 'src/app/classes/Media';
import { environment } from 'src/environments/environment';
import { Feature, Point } from '@turf/helpers';
import { filter, map, mergeMap, tap } from 'rxjs';
import {
  StoryComponentMode,
  StoryService,
} from 'src/app/stories/story.service';

@Component({
  selector: 'app-project-view',
  templateUrl: './project-view.component.html',
  styleUrls: ['./project-view.component.scss'],
  animations: [
    trigger('overlayInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('150ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('150ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class ProjectViewComponent implements OnInit {
  @ViewChild('projectView') projectView!: ElementRef;
  @Output() clicked: EventEmitter<string> = new EventEmitter();
  projectId: string | null = null;
  project?: Project;
  projectOwner: string = '';
  showShareModal: boolean = false;
  newShare?: Share;
  shared: boolean = false;
  showShareListModal: boolean = false;
  showUploadModal: boolean = false;
  showStoryModal: boolean = false;
  userName: string;
  userRole: string;
  showDescription: boolean = false;
  showPanoramaList: boolean = false;
  showMarkerList: boolean = false;
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;
  faI = faInfo;
  showActionMenu: boolean = false;
  currentMedia: string = '';
  openStoryMode?: StoryComponentMode;

  //Inset Map properties
  mapEnabled: boolean;
  faMaximise = faExpandAlt;
  mapMarkers: Array<{
    text: string;
    icon: string;
    url: string;
    uuid: string;
    feature: Feature<Point>;
  }> = [];
  mapHighlight: string = '';

  constructor(
    private _auth: AuthService,
    private _project: ProjectService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _title: TitleService,
    private _tracker: MatomoTracker,
    public _stories: StoryService
  ) {
    this.mapEnabled = environment.maps;
    this.userRole = this._auth.role;
    this.userName = this._auth.name;
  }

  ngOnInit(): void {
    let forwardToDefault = false;

    if (!this._route.children.length) {
      forwardToDefault = true;
    }

    this.setCurrentMediaId();

    this._route.paramMap.subscribe((paramMap) => {
      if (paramMap.has('projectId')) {
        this.projectId = paramMap.get('projectId');
        this.showDescription =
          this.projectId !== null &&
          this._project.getShowProjectDescription(this.projectId) &&
          this.project?.description !== undefined;
        this.getProject(this.projectId || '', forwardToDefault);
      }
    });
    this._project.projectUpdated.subscribe(() => {
      this.project = this._project.currentProject;
    });
    this._project.openUploadModal.subscribe((open: boolean) => {
      this.showUploadModal = open;
    });
  }

  private setCurrentMediaId() {
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this._route),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        mergeMap((route) => route.paramMap)
      )
      .subscribe((paramMap) => {
        this.currentMedia = paramMap.get('mediaId') ?? '';
      });

    this._route.firstChild?.paramMap.subscribe((paramMap) => {
      this.currentMedia = paramMap.get('mediaId') ?? '';
    });
  }

  public sidebarClick(type: string) {
    switch (type) {
      case 'share':
        this.showShareModal = true;
        this._tracker.trackEvent('Project Sidebar', 'Click', 'Share');
        break;
      case 'panoramas':
        this.showMarkerList = false;
        this.showStoryModal = false;
        this.showPanoramaList = !this.showPanoramaList;
        this._tracker.trackEvent('Project Sidebar', 'Click', 'Media List');
        break;
      case 'map':
        this.showPanoramaList = false;
        this.showStoryModal = false;
        this._tracker.trackEvent('Project Sidebar', 'Click', 'Map');
        this._router.navigate(['map'], { relativeTo: this._route });
        break;
      case 'fullscreen':
        this.toggleFullscreen();
        break;
      case 'story':
        this.showMarkerList = false;
        this.showPanoramaList = false;
        this.showStoryModal = !this.showStoryModal;
    }
  }

  public mediaUploaded(newPanorama: ProjectMedia<MediaType>[]) {
    if (this._project.currentProject) {
      this._project.currentProject.media =
        this._project.currentProject.media.concat(newPanorama);
      this.project = this._project.currentProject;
    }
  }

  getProject(projectId: string, forwardToDefault: boolean = false) {
    this._project.get(projectId).subscribe((project) => {
      this._title.setTitle(project.name);
      this.projectOwner = project?.owner_name ?? '';
      this.project = project;
      this._project.set(project);
      this.addMapMarkers();

      if (forwardToDefault) {
        if (this.project.default_view) {
          this._router.navigate(['.', this.project.default_view], {
            relativeTo: this._route,
          });
        } else {
          this._router.navigate(['.', 'map'], {
            relativeTo: this._route,
          });
        }
      }
    });
  }

  projectShared(share: Share) {
    this.newShare = share;
    this.shared = true;
    this._tracker.trackEvent(
      'Project Share',
      'Email',
      'By Email',
      share.emails.length
    );
  }

  shareByLink(shareDate: Date | null) {
    this._project
      .update(this.project?._id as string, { shared: shareDate })
      .subscribe(() => {
        this.getProject(this.project?._id || '');
        this._tracker.trackEvent('Project Share', 'Link', 'By Link');
      });
  }

  preventContextMenu($event: MouseEvent) {
    $event.preventDefault();
  }

  continue() {
    this.showShareModal = false;
    this.shared = false;
  }

  async toggleFullscreen() {
    //determining if the screen is fullscreen
    if (window.innerHeight === screen.height) {
      await document.exitFullscreen();
    } else {
      await document.documentElement.requestFullscreen();
    }
  }

  infoClickEvent() {
    this.showDescription = true;
    this._tracker.trackEvent('Project Sidebar', 'Click', 'Info');
  }

  addMapMarkers() {
    const links: Array<{
      text: string;
      icon: string;
      url: string;
      uuid: string;
      feature: Feature<Point>;
    }> = [];
    if (this.project) {
      for (const media of getMediaWithGeolocation(this.project)) {
        if (media.geolocation) {
          links.push({
            text: media.name || 'Panorama',
            icon: '/assets/link.png',
            url: `/cases/${this.project.case_id}/${this.project._id}/${media.type}/${media.uuid}`,
            feature: media.geolocation,
            uuid: media?.uuid ?? '',
          });
        }
      }
    }

    this.mapMarkers = links;
    this.mapHighlight = this.currentMedia;
  }

  toggleStoryModal() {
    this.showStoryModal = !this.showStoryModal;
    this.openStoryMode = { primary: 'view', secondary: 'view-active' };
  }

  truncateString(str: string, numWords: number) {
    if (str.split(' ').length <= numWords) return str;
    const array = str.split(' ').splice(0, numWords).join(' ');
    return array.concat('...');
  }
}
