import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-editor-window-video',
  templateUrl: './editor-window-video.component.html',
  styleUrls: ['./editor-window-video.component.scss'],
})
export class EditorWindowVideoComponent {
  files: File[] = [];
  @Input() image!: File;
  @Output() newCaptionEvent = new EventEmitter<string>();
  @Output() newFilesEvent = new EventEmitter<File[]>();
  url?: string;
  src?: SafeUrl;
  @Input() caption: string = '';
  @Input() currentFiles: Array<string> = [];
  constructor(private _sanitize: DomSanitizer) {}

  videoChanged(event: Event & { target: EventTarget | null }) {
    const target = event.target as HTMLInputElement;
    const fileInput = target.files;

    const inputFile = fileInput?.item(0);
    if (fileInput && inputFile) {
      this.files.push(inputFile);
    }

    this.url = URL.createObjectURL(this.files[0]);
    this.src = this._sanitize.bypassSecurityTrustUrl(this.url);
    return this.newFilesEvent.emit(this.files);
  }

  onCaptionChange() {
    return this.newCaptionEvent.emit(this.caption);
  }

  OnDestroy(): void {
    if (this.url) {
      URL.revokeObjectURL(this.url);
    }
  }
}
