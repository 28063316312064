import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Feature, Point } from '@turf/helpers';
import { Media, MediaType } from 'src/app/classes/Media';
import { ProjectService } from 'src/app/projects/project.service';
import { faX } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-media-properties-panel',
  templateUrl: './media-properties-panel.component.html',
  styleUrls: ['./media-properties-panel.component.scss'],
})
export class MediaPropertiesPanelComponent implements OnInit, OnChanges {
  @Input() media?: Media<MediaType>;
  @Output() showPanelChange = new EventEmitter<boolean>();
  thumbnailUrl: string = '';
  editDateTime: boolean = false;
  updatedDateTime?: Date;
  updatedCaption: string = '';
  showOnMap: boolean = false;
  tab: string = 'project';
  tabIndex: number | null = null;
  faX = faX;
  mapMarkers: Array<{
    text: string;
    icon: string;
    url: string;
    uuid: string;
    feature: Feature<Point>;
  }> = [];

  constructor(private _project: ProjectService) {}

  async ngOnInit(): Promise<void> {
    if (this._project.currentProject && this.media?.thumbnail) {
      this.thumbnailUrl = await this._project.getMediaUrl(
        this._project.currentProject,
        this.media.thumbnail
      );
    }
    if (this.media?.geolocation) {
      this.showOnMap = this.media.show_on_map;
    }
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['media']) {
      this.tab = 'project';
      this.tabIndex = null;
    }

    if (this._project.currentProject && this.media?.thumbnail) {
      this.thumbnailUrl = await this._project.getMediaUrl(
        this._project.currentProject,
        this.media.thumbnail
      );
      this.addMapMarkers();
    }
    if (this.media?.geolocation) {
      this.showOnMap = this.media.show_on_map;
    }
  }

  updateDate(): void {
    this.editDateTime = false;
    if (this.updatedDateTime) {
      const date = new Date(new Date(this.updatedDateTime).toISOString());
      this.editDateTime = false;
      const projectId = this._project.currentProject?._id;
      if (projectId && this.media) {
        this.media.manual_date = date;
        this._project.updateMedia(projectId, this.media);
      }
    }
  }

  updatShowOnMap(): void {
    const projectId = this._project.currentProject?._id;
    if (projectId && this.media) {
      this.media.show_on_map = this.showOnMap;
      this._project.updateMedia(projectId, this.media);
    }
  }

  addMapMarkers(): void {
    const links: Array<{
      text: string;
      icon: string;
      url: string;
      uuid: string;
      feature: Feature<Point>;
    }> = [];
    const project = this._project.currentProject;
    if (this.media && this.media?.geolocation && project) {
      links.push({
        text: this.media.name || 'Panorama',
        icon: '/assets/link.png',
        url: `/cases/${project.case_id}/${project._id}/${this.media.type}/${this.media.uuid}`,
        feature: this.media.geolocation,
        uuid: this.media?.uuid ?? '',
      });
    }
    this.mapMarkers = links;
  }

  handleTabClick(tab: string | undefined, tabIndex: number | null): void {
    if (tab && this.media && this.media?.metadata) {
      this.tab = tab;
      this.tabIndex = tabIndex;
    }
  }

  updateCaption(event: Event & { target: EventTarget | null }): void {
    //TODO: this type needs resolving
    const target = event.target as HTMLInputElement;
    this.updatedCaption = target.innerHTML;
  }

  editCaption(): void {
    const projectId = this._project.currentProject?._id;
    if (projectId && this.media && this.updatedCaption) {
      this.media.caption = this.updatedCaption;
      this._project.updateMedia(projectId, this.media);
    }
  }

  closePanel(): void {
    this.showPanelChange.emit(false);
  }
}
