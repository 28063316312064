import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit {
  loggedIn: boolean;
  isAdmin: boolean = false;
  userRole: string;

  constructor(private _auth: AuthService) {
    this.loggedIn = this._auth.email ? true : false;
    this.userRole = this._auth.role;
    this._auth.loggedIn.subscribe((isLoggedIn) => {
      this.loggedIn = isLoggedIn;
    });
  }

  ngOnInit(): void {
    this.isAdmin = this._auth.role === 'admin';
  }
}
