import { Component, Input } from '@angular/core';
import { Project } from 'src/app/classes/Project';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
})
export class ProjectListComponent {
  @Input() projects: Project[] = [];
  @Input() deletedProjectId: string = '';
  @Input() showingArchivedProjects: boolean = false;

  deleteProject(deletedProjectId: string) {
    this.projects = this.projects.filter((project) => {
      return project._id !== deletedProjectId;
    });
  }

  archiveProject(archiveProjectId: string) {
    const index = this.projects.findIndex(
      (project) => project._id === archiveProjectId
    );
    this.projects[index].archived = true;
    if (!this.showingArchivedProjects) {
      this.projects = this.projects.filter((project) => {
        return project._id !== archiveProjectId;
      });
    }
  }

  restoreProject(restoreProjectId: string) {
    const index = this.projects.findIndex(
      (project) => project._id === restoreProjectId
    );
    this.projects[index].archived = false;
  }

  editProject(newProject: Project) {
    const index = this.projects.findIndex(
      (project) => project._id === newProject._id
    );
    this.projects[index].name = newProject.name;
  }

  copyProject(project: Project) {
    this.projects.push(project);
  }
}
