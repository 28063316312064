<form
  *ngIf="!uploading; else uploadingInProgress"
  [formGroup]="uploadForm"
  (ngSubmit)="upload()"
>
  <div class="form-section">
    <input
      type="file"
      accept="image/*,video/*,application/pdf"
      name="panoramas"
      [multiple]="true"
      id="panoramas"
      required="required"
      (change)="imagesChanged($event)"
    />
    <div class="preview-images">
      <ul class="image-card" *ngFor="let file of files; let i = index">
        <li>{{ file.name }}</li>
        <div class="remove-image" (click)="removeImage(i)">
          <div><fa-icon [icon]="faTrash"></fa-icon></div>
        </div>
      </ul>
    </div>
  </div>
  <div class="actions">
    <button type="submit">Upload</button>
  </div>
</form>

<ng-template class="form-section" #uploadingInProgress>
  <div>Upload in progress...</div>
  <div>
    File {{ currentUploadIndex + 1 }} of {{ files.length }}:
    {{ currentUploadFile }}
  </div>
</ng-template>
