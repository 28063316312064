import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toMmss',
})
export class ToMmssPipe implements PipeTransform {
  transform(seconds: number) {
    return new Date(seconds * 1000).toISOString().substring(14, 19);
  }
}
