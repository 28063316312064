<div class="form-section">
  <div>
    <select name="share-type" [(ngModel)]="type" class="dropdown">
      <option value="email">Share to specific email addresses</option>
      <option value="link">Share to anyone with the link</option>
    </select>

    <div *ngIf="type === 'link'">
      <div *ngIf="!project.shared">
        <p>This project is not currently shared by link.</p>
        <div class="link-placeholder"></div>
        <div class="actions">
          <button (click)="shareByLink()">Share Now</button>
        </div>
      </div>
      <div *ngIf="project.shared">
        <p>
          This project is currently being shared until
          {{ project.shared | date : 'longDate' }}.
        </p>
        <p>
          <a href="{{ shareUrl }}" target="_blank">{{ shareUrl }}</a>
        </p>
        <div class="actions">
          <button (click)="cancelShareByLink()">Disable Sharing</button>
        </div>
      </div>
    </div>

    <form *ngIf="type === 'email'" (ngSubmit)="share()">
      <div>
        <div class="form-item">
          <label for="share-email">Email </label>
          <input
            name="share-email"
            type="email"
            [(ngModel)]="newEmail"
            class="email"
          />
          <button (click)="addEmail()" type="button">Add</button>
        </div>
        <div class="error" *ngIf="this.emailError">Enter a vailid email</div>
        <div class="form-item">
          <p *ngIf="!emails.length">No emails have been added!</p>
          <ul>
            <li *ngFor="let email of emails">{{ email }}</li>
          </ul>
        </div>
        <div class="form-item">
          <label>Expiry </label>
          <input
            type="date"
            name="share-expiry"
            [(ngModel)]="expiry"
            class="date"
            max="{{ maxDate | date : 'yyyy-MM-dd' }}"
            min="{{ minDate | date : 'yyyy-MM-dd' }}"
            (change)="validateDate()"
          />
          <p *ngIf="showDateError" class="error">
            Sharing is allowed for a maximum of two weeks
          </p>
        </div>
        <div class="form-item message">
          <label>Message </label>
          <textarea
            name="share-message"
            [(ngModel)]="message"
            cols="30"
            rows="5"
          ></textarea>
        </div>
        <div class="form-item">
          <label
            ><input
              name="share-send-link"
              type="checkbox"
              [(ngModel)]="sendLink"
            />
            Send link to email addresses</label
          >
        </div>
        <div class="placeholder">
          <div class="error" *ngIf="this.displayError">
            Add an email address to share
          </div>
        </div>
        <div class="actions">
          <button type="button" (click)="cancelShare()">Cancel</button>
          <button type="submit">Share</button>
        </div>
      </div>
    </form>
  </div>
</div>
