import { Component, OnInit } from '@angular/core';
import { ProjectService } from 'src/app/projects/project.service';
import { ActivatedRoute } from '@angular/router';
import { MEDIATYPES } from 'src/app/classes/Media';

@Component({
  selector: 'app-edit-horizon',
  templateUrl: './edit-horizon.component.html',
  styleUrls: ['./edit-horizon.component.scss'],
})
export class EditHorizonComponent implements OnInit {
  mediaIndex: number = 0;
  mediaId: string = '';
  tilt: number = 0;
  roll: number = 0;

  constructor(
    private _project: ProjectService,
    private _route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this._route.params.subscribe((params) => {
      const mediaId = params['mediaId'];
      if (mediaId) {
        const media = this._project.currentProject?.media || [];
        this.mediaId = mediaId;
        this.mediaIndex = media.findIndex((media) => media.uuid === mediaId);
      }
    });
    const currentMedia = this._project.currentProject?.media[this.mediaIndex];
    if (currentMedia?.type === MEDIATYPES.PANORAMA && currentMedia.options) {
      this.tilt = currentMedia.options?.sphereCorrection?.tilt || 0;
      this.roll = currentMedia.options?.sphereCorrection?.roll || 0;
    }
  }

  updateHorizon() {
    const data = { tilt: this.tilt, roll: this.roll };
    this._project.horizonCorrection(data);
  }

  save() {
    if (!this._project.currentProject) return;
    const { _id: projectId, media } = this._project.currentProject;

    if (media && projectId) {
      const selectedMedia = media[this.mediaIndex];
      const updatedHorizon = { tilt: this.tilt, roll: this.roll };
      if (selectedMedia.type === MEDIATYPES.PANORAMA && selectedMedia.options) {
        selectedMedia.options.sphereCorrection = updatedHorizon;
      } else {
        selectedMedia.options = { sphereCorrection: updatedHorizon };
      }
      const partial = { media };
      this._project.update(projectId, partial).subscribe();
    }
    this.closeEditModal();
  }

  closeEditModal() {
    const currentMedia = this._project.currentProject?.media[this.mediaIndex];
    if (currentMedia?.type === MEDIATYPES.PANORAMA && currentMedia.options) {
      this.tilt = currentMedia.options?.sphereCorrection?.tilt || 0;
      this.roll = currentMedia.options?.sphereCorrection?.roll || 0;
    } else {
      this.tilt = 0;
      this.roll = 0;
    }
    const data = { tilt: this.tilt, roll: this.roll };
    this._project.horizonCorrection(data);
    this._project.showEditHorizonModal(false);
  }
}
