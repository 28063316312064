import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Media, MediaMarker, MEDIATYPES } from 'src/app/classes/Media';
import { getMediaById, Project } from 'src/app/classes/Project';
import { ProjectService } from '../project.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-project-view-panorama',
  templateUrl: './project-view-panorama.component.html',
  styleUrls: ['./project-view-panorama.component.scss'],
})
export class ProjectViewPanoramaComponent implements OnInit {
  mediaUuid: string = '';
  panorama?: Media<typeof MEDIATYPES.PANORAMA>;
  project?: Project;
  userName: string;
  userRole: string;
  showEditorWindow: boolean = false;

  constructor(
    private _route: ActivatedRoute,
    private _project: ProjectService,
    private _router: Router,
    private _auth: AuthService
  ) {
    this.userRole = this._auth.role;
    this.userName = this._auth.name;
  }

  ngOnInit(): void {
    this._route.paramMap.subscribe((paramMap) => {
      this.mediaUuid = paramMap.get('mediaId') || '';
      if (this.project) {
        const pano = getMediaById(this._project.currentProject, this.mediaUuid);
        if (pano.type === MEDIATYPES.PANORAMA) {
          this.panorama = pano;
        }
      }
    });

    this._route.queryParams.subscribe((queryParams) => {
      if (queryParams['project']) {
        this.project = this._project.currentProject;
      }
    });

    this._project.toggleEditorWindow.subscribe((show) => {
      this.showEditorWindow = show;
    });
  }

  addNewMarkers(markers: MediaMarker[]) {
    const mediaIndex = this._project.currentProject?.media.findIndex(
      (item) => item.uuid === this.mediaUuid
    );

    if (mediaIndex !== undefined && this._project.currentProject) {
      if (this._project.currentProject?.media[mediaIndex].markers) {
        this._project.currentProject?.media[mediaIndex].markers.push(
          markers[0]
        );
      } else {
        this._project.currentProject.media[mediaIndex].markers = markers;
      }

      this._project.projectUpdated.emit();
    }
  }

  panoramaSelected(panoramaUuid: string) {
    this._router.navigate(['../', panoramaUuid], {
      relativeTo: this._route,
    });
  }
}
