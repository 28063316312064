import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from '../api.service';
import { Share } from '../classes/Share';

@Injectable({
  providedIn: 'root',
})
export class ShareService {
  constructor(private _api: ApiService, private _http: HttpClient) {}

  add(share: Share): Observable<Share> {
    return this._http
      .post<{ acknowledged: boolean; insertedId: string }>(
        this._api.getUri(`shares/${share.project_id}`),
        share
      )
      .pipe(
        map((response) => {
          const newShare = new Share(
            share.project_id,
            share.expiry,
            response.insertedId
          );
          newShare.emails = share.emails;
          newShare.message = share.message;
          newShare.notification = share.notification;
          newShare.url = `${environment.webUri}/share/${share.project_id}`;
          return newShare;
        })
      );
  }

  status(projectId: string): Observable<{ ok: boolean; caseId: string }> {
    return this._http.get<{ ok: boolean; caseId: string }>(
      this._api.getUri(`shares/${projectId}/verify`)
    );
  }
}
