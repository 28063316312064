<div class="link-section">
  <div class="title">
    <h5>Select a panorama to link to</h5>
  </div>
  <div class="list">
    <ul>
      <ng-container *ngFor="let pano of projectPanoramas">
        <li *ngIf="pano.uuid !== this.panoramaUuid">
          <button
            class="select-button"
            [ngClass]="{
              selected: this.selectedPanoramaId === pano.uuid
            }"
            (click)="selectPanorama(pano.uuid)"
          >
            {{ pano.name }}
          </button>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
