import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { uuid4 } from '@sentry/utils';
import { IMediaElement, VgApiService } from '@videogular/ngx-videogular/core';
import { ProjectService } from 'src/app/projects/project.service';
import { faXmark, faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input() url: string = '';
  @Input() modal: boolean = false;
  @Input() vgMedia?: IMediaElement;
  @Input() videoId: string = '';
  @Input() currentCueId: string | null = null;
  @Output() currentCueIdChange: EventEmitter<string | null> =
    new EventEmitter();

  vgApi?: VgApiService;
  textTrack?: TextTrack;
  highlights: {
    startTime: number;
    endTime: number;
    id: string;
    text: string;
  }[] = [];
  highlightStart: number = 0;
  highlightEnd: number = 0;
  highlightName: string = '';
  projectId?: string;
  showHighlightModal: boolean = false;
  faXmark = faXmark;
  faPlus = faPlus;

  constructor(
    private _project: ProjectService,
    private _cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.projectId = this._project.currentProject?._id;
    if (this.projectId) {
      this._project
        .getVideoHighlights(this.projectId, this.videoId)
        .subscribe((value) => {
          value.highlights?.forEach((h) => {
            this.addHighlight(h);
          });

          this.highlights = value.highlights ?? [];

          if (this.currentCueId) {
            this.goToHighlight(this.currentCueId);
          }
        });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['currentCueId'] && this.vgApi?.state !== 'playing') {
      this.goToHighlight(changes['currentCueId'].currentValue);
    }
  }

  ngAfterViewChecked(): void {
    this._cdRef.detectChanges();
  }

  onPlayerReady(api: VgApiService) {
    this.vgApi = api;
    this.vgApi.addTextTrack('metadata');
    this.textTrack = this.vgApi?.textTracks[0];

    if (this.highlights.length > 0) {
      this.vgApi.seekTime(this.highlights[0].startTime);
    }
  }

  goToHighlight(cueId: string) {
    const highlight = this.highlights.find(
      (highlight) => highlight.id === cueId
    );

    if (highlight) {
      this.seekHighlightStart(highlight?.startTime);
    }
  }

  async createNewHighlight() {
    if (!this.highlightName) {
      throw new Error('No name, highlight not created');
    }
    const id = uuid4();
    this.addHighlight({
      startTime: this.highlightStart,
      endTime: this.highlightEnd,
      text: this.highlightName,
      id,
    });

    const newHighlight = {
      startTime: this.highlightStart,
      endTime: this.highlightEnd,
      text: this.highlightName,
      id,
    };
    this.highlights.push(newHighlight);
    if (this.projectId) {
      await this._project.addVideoHighlights(this.projectId, this.videoId, [
        newHighlight,
      ]);
    }
    this.highlights.sort((a, b) => a.startTime - b.startTime);

    this.highlightStart = 0;
    this.highlightEnd = 0;
    this.highlightName = '';
  }

  addHighlight(highlight: {
    startTime: number;
    endTime: number;
    text: string;
    id: string;
  }): void {
    const { startTime, endTime, id, text } = highlight;

    if (endTime === 0 || startTime === endTime) return;

    const cue = new VTTCue(startTime, endTime, text);
    cue.id = id;

    cue.addEventListener('enter', () => {
      this.onEnterCue(cue.id);
    });
    cue.addEventListener('exit', () => {
      this.onExitCue();
    });

    this.textTrack?.addCue(cue);
  }

  onEnterCue(cueId: string) {
    this.currentCueId = cueId;
    this.currentCueIdChange.emit(cueId);
  }

  onExitCue() {
    this.vgApi?.pause();
    this.currentCueId = '';
    this.currentCueIdChange.emit(null);
  }

  setHighlightStart() {
    let start = Math.floor(this.vgApi?.currentTime);
    if (start < 0) {
      start = 0;
    }

    this.highlightStart = start;
  }

  setHighlightEnd() {
    let end = Math.floor(this.vgApi?.currentTime);
    if (end >= this.vgApi?.duration) {
      end = this.vgApi?.duration;
    }

    this.highlightEnd = end;
  }

  seekHighlightStart(startTime: number) {
    this.vgApi?.seekTime(startTime);
  }

  removeHighlight(highlightId: string) {
    const idx = this.highlights.findIndex(
      (highlight) => highlight.id === highlightId
    );
    this.highlights.splice(idx, 1);
    const cue = this.textTrack?.cues?.getCueById(highlightId);
    if (cue && this.projectId) {
      cue && this.textTrack?.removeCue(cue);
      this._project.removeVideoHighlight(this.projectId, this.videoId, cue.id);
    }
  }

  toggleHighlightModal() {
    this.showHighlightModal = !this.showHighlightModal;
  }
}
