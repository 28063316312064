import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener,
  OnChanges,
} from '@angular/core';
import { Media, MediaType } from 'src/app/classes/Media';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { ProjectService } from 'src/app/projects/project.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-gallery-list-item',
  templateUrl: './gallery-list-item.component.html',
  styleUrls: ['./gallery-list-item.component.scss'],
})
export class GalleryListItemComponent implements OnInit, OnChanges {
  @Input() media?: Media<MediaType>;
  @Output() mediaChange: EventEmitter<Media<MediaType>> = new EventEmitter();
  @Output() selectedChange: EventEmitter<string> = new EventEmitter();
  @Input() propertiesPanelId?: string;
  @Output() propertiesPanelChange: EventEmitter<string> = new EventEmitter();
  @Input() isEditingGridCard: boolean = false;
  @Output() isEditingGridCardChange: EventEmitter<boolean> = new EventEmitter();
  @Input() selected: boolean = false;
  @Output() tabEditChange: EventEmitter<string> = new EventEmitter();
  @Input() editNextGridCard?: string;
  displayPropertiesPanel: boolean = false;
  showDeleteModal: boolean = false;
  faTrash = faTrash;
  rightClickMenuPositionX: number = 0;
  rightClickMenuPositionY: number = 0;
  displayContextMenu: boolean = false;
  editName: boolean = false;
  updatedName: string = '';
  editDateTime: boolean = false;
  updatedDateTime?: Date;
  showOnMap: boolean = false;
  @HostListener('document:click')
  documentClick(): void {
    this.displayContextMenu = false;
  }

  constructor(
    private _route: ActivatedRoute,
    private _project: ProjectService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    if (this.media?.geolocation) {
      this.showOnMap = this.media.show_on_map;
    }
    this._project.closeContextMenu.subscribe(() => {
      this.displayContextMenu = false;
    });
  }

  ngOnChanges(): void {
    if (this.editNextGridCard === this.media?.uuid) {
      setTimeout(() => {
        this.editName = true;
        this.isEditingGridCard = true;
        this.isEditingGridCardChange.emit(true);
        const textBox = document.getElementById(`id-${this.media?.uuid}`);
        textBox?.setAttribute('contentEditable', 'true');
        textBox?.focus();
      }, 100);
    }
  }

  openDeleteMediaModal(): void {
    this.showDeleteModal = true;
  }

  async deleteMedia(): Promise<void> {
    const projectId = this._project.currentProject?._id;
    const uuid = this.media?.uuid;

    if (projectId && uuid) {
      const updatedProject = await this._project.deleteMedia(projectId, uuid);
      this._project.currentProject = updatedProject;
      this._project.projectUpdated.emit();
      this._project.currentProjectChanged.emit(updatedProject);
    }
  }

  handleSelection(): void {
    this.selectedChange.emit(this.media?.uuid);
  }

  cancelDelete(): void {
    this.showDeleteModal = false;
  }

  onMouseUp($event: MouseEvent): void {
    this.displayContextMenu = false;
    if ($event.button === 2) {
      this.rightClickMenuPositionX = $event.clientX;
      this.rightClickMenuPositionY = $event.clientY;
      this.displayContextMenu = true;
    }
  }

  onMouseDown($event: MouseEvent): void {
    if ($event.button === 0 && $event.detail === 2) {
      this._router.navigate(['../', this.media?.type, this.media?.uuid], {
        queryParams: { project: true },
        relativeTo: this._route,
      });
    } else if (
      $event.button === 0 &&
      !this.editName &&
      !this.editDateTime &&
      !this.isEditingGridCard
    ) {
      this.tabEditChange.emit('');
      this.propertiesPanelChange.emit(this.media?.uuid);
      this.displayPropertiesPanel = true;
    }
  }

  handleKeydown($event: KeyboardEvent) {
    if ($event.code === 'Tab') {
      this.tabEditChange.emit(this.media?.uuid);
    } else if ($event.code === 'Enter') {
      $event?.preventDefault();
      this.edit();
    }
  }

  getRightClickMenuStyle(): {
    position: string;
    left: string;
    top: string;
    'z-index': number;
  } {
    return {
      position: 'absolute',
      left: `${this.rightClickMenuPositionX - 85}px`,
      top: `${this.rightClickMenuPositionY - 62}px`,
      'z-index': 2,
    };
  }

  handleMenuItemClick(event: { event: MouseEvent; data: string }): void {
    if (event.data === 'Delete') {
      this.deleteMedia();
    } else if (event.data === 'Edit Name') {
      this.editName = true;
      this.isEditingGridCard = true;
      this.isEditingGridCardChange.emit(true);
      const textBox = document.getElementById(`id-${this.media?.uuid}`);
      textBox?.setAttribute('contentEditable', 'true');
      textBox?.focus();
    } else if (event.data === 'Edit Date or Time') {
      this.editDateTime = true;
    }
  }

  setName(): void {
    this.updatedName = this.media?.name || '';
  }

  edit(): void {
    if (this.editName) {
      this.editName = false;
      this.isEditingGridCardChange.emit(false);
      if (this.editNextGridCard) {
        this.editNextGridCard = '';
      }
      const textBox = document.getElementById(`id-${this.media?.uuid}`);
      textBox?.setAttribute('contentEditable', 'false');
      const projectId = this._project.currentProject?._id;
      if (projectId && this.media) {
        this.media.name = this.updatedName;
        this._project.updateMedia(projectId, this.media);
      }
    }
  }

  updateName(event: Event & { target: EventTarget | null }): void {
    //TODO: this type needs resolving
    const target = event.target as HTMLInputElement;
    this.updatedName = target.innerHTML;
  }

  updateDate(): void {
    this.editDateTime = false;
    if (this.updatedDateTime) {
      const date = new Date(new Date(this.updatedDateTime).toISOString());
      this;
      this.editDateTime = false;
      const projectId = this._project.currentProject?._id;
      if (projectId && this.media) {
        this.media.manual_date = date;
        this._project.updateMedia(projectId, this.media);
      }
    }
  }

  updatShowOnMap(): void {
    const projectId = this._project.currentProject?._id;
    if (projectId && this.media) {
      this.media.show_on_map = this.showOnMap;
      this._project.updateMedia(projectId, this.media);
    }
  }
}
