import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { ProjectService } from 'src/app/projects/project.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-modal-markers-pdf',
  templateUrl: './modal-markers-pdf.component.html',
  styleUrls: ['./modal-markers-pdf.component.scss'],
})
export class ModalMarkersPdfComponent implements OnInit {
  @ViewChild('pdfviewer') pdfviewer: PdfJsViewerComponent | undefined;
  @Input() heading: string = '';
  @Input() show: boolean = false;
  @Input() url: string = '';
  @Output() showChange: EventEmitter<boolean> = new EventEmitter();
  dataUrl: string = '';

  constructor(private _project: ProjectService) {}

  async ngOnInit(): Promise<void> {
    if (this._project.currentProject) {
      this.dataUrl = `${environment.apiUri}${await this._project.getMediaUrl(this._project.currentProject, this.url, true)}`;
    }
  }

  closeModal() {
    this.showChange.emit(false);
  }
}
