import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-editor-window-map',
  templateUrl: './editor-window-map.component.html',
  styleUrls: ['./editor-window-map.component.scss'],
})
export class EditorWindowMapComponent {
  @Input() showEditor: boolean = false;
  @Output() showEditorChange = new EventEmitter<boolean>();
  @Input() mapLayer: string = '';
  @Output() mapLayerChange = new EventEmitter<string>();
  @Input() graticules: boolean = false;
  @Output() graticulesChange = new EventEmitter<boolean>();

  changeMapLayer(value: string) {
    this.mapLayerChange.emit(value);
  }

  toggleGraticules() {
    this.graticulesChange.emit(this.graticules);
  }

  close() {
    this.showEditorChange.emit(false);
  }
}
