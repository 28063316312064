export class User {
  job_title?: string = '';
  profile_photo?: string = '';
  constructor(
    public email: string,
    public name: string,
    public role: string,
    readonly _id?: string
  ) {}
}
