import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from 'src/app/projects/project.service';
import type { Panel } from 'src/app/classes/Project';
import { v4 as uuidv4 } from 'uuid';
import {
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faTrashCan,
  faPencil,
  faPlus,
  faCheck,
  faMinus,
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Story, StoryService } from '../story.service';

@Component({
  selector: 'app-story-editor',
  templateUrl: './story-editor.component.html',
  styleUrls: ['./story-editor.component.scss', '../story.component.scss'],
})
export class StoryEditorComponent implements OnInit {
  viewSet = false;

  storyForm?: FormGroup;
  panelForm?: FormGroup;

  faArrowUp = faArrowUp;
  faArrowDown = faArrowDown;
  faArrowLeft = faArrowLeft;
  faTrashCan = faTrashCan;
  faPencil = faPencil;
  faPlus = faPlus;
  faCheck = faCheck;
  faMinus = faMinus;
  faChevronRight = faChevronRight;
  faChevronDown = faChevronDown;

  componentMode: StoryEditComponentMode = 'default';

  constructor(
    private _project: ProjectService,
    public _stories: StoryService,
    private _router: Router,
    private _route: ActivatedRoute,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.storyForm = this.fb.group<
      FormModel<Pick<Story, 'name' | 'description'>>
    >({
      name: ['', [Validators.required]],
      description: ['', [Validators.required]],
    });

    this.panelForm?.get('mediaUrl')?.valueChanges.subscribe(() => {
      this.viewSet = true;
    });
    this.panelForm = this.fb.group<
      FormModel<Pick<Panel, 'heading' | 'content' | 'mediaUrl' | 'queryParams'>>
    >({
      heading: ['', [Validators.required]],
      content: ['', [Validators.required, Validators.maxLength(600)]],
      mediaUrl: ['', []],
      queryParams: [{}, []],
    });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (this._stories.editPanelIdx === null) return;
    switch (event.key) {
      case 'ArrowRight':
      case 'ArrowDown':
        this._stories.editPanelIdx += 1;
        break;
      case 'ArrowLeft':
      case 'ArrowUp':
        this._stories.editPanelIdx -= 1;
        break;
      //on enter key open edit panel
      case 'Enter':
        if (this._stories.storyComponentMode.secondary === 'edit-list') {
          if (
            this._stories.selectedStory?.panels?.[this._stories.editPanelIdx]
              .panelId
          ) {
            this.openEditPanel(
              this._stories.selectedStory?.panels?.[this._stories.editPanelIdx]
                .panelId
            );
          }
        } else if (
          (this._stories.storyComponentMode.secondary === 'edit-panel' ||
            this._stories.storyComponentMode.secondary === 'edit-add-panel') &&
          this.panelForm?.touched &&
          this.panelForm?.valid
        ) {
          this.applyPanelEdit();
        }
        break;
      //on enter key close edit panel, without saving
      case 'Escape':
        if (
          this._stories.storyComponentMode.secondary === 'edit-panel' ||
          this._stories.storyComponentMode.secondary === 'edit-add-panel'
        ) {
          this.closeEditPanel();
        }
    }
  }

  addNewPanel() {
    const newPanel: Panel = {
      panelId: uuidv4(),
      ...this.panelForm?.value,
    };
    this._stories.createPanels([newPanel]);
    this._stories.setStoryEditMode('edit-list');
    this.panelForm?.reset();
  }

  setCurrentView() {
    let mediaUrl = this._route.snapshot.firstChild?.url[0].path;

    // Handling map case where route is /map instead of /media/mediaId
    if (this._route.snapshot.firstChild?.url[1]) {
      mediaUrl += '/' + this._route.snapshot.firstChild.url[1].path;
    }
    const queryParams = this._route.snapshot.queryParams;
    this.panelForm?.get('mediaUrl')?.setValue(mediaUrl);
    this.panelForm?.get('queryParams')?.setValue(queryParams);

    this.panelForm?.get('mediaUrl')?.markAsDirty();
    this.panelForm?.get('queryParams')?.markAsDirty();
  }

  openPanel(storyIndex: number) {
    this.setExpandedPanel(storyIndex);
    this._router.navigate(
      [this._stories.selectedStory?.panels[storyIndex].mediaUrl],
      {
        relativeTo: this._route,
        queryParams:
          this._stories.selectedStory?.panels[storyIndex].queryParams,
      }
    );
  }

  movePanelUp(currentIndex: number) {
    const currentItem = this._stories.selectedStory?.panels[currentIndex];
    const projectId = this._project.currentProject?._id;
    if (currentItem && projectId && this._stories.selectedStoryId) {
      this._stories.reorderPanel(
        projectId,
        this._stories.selectedStoryId,
        currentItem.panelId,
        currentIndex - 1
      );
    }
  }

  movePanelDown(currentIndex: number) {
    const currentItem = this._stories.selectedStory?.panels[currentIndex];
    const projectId = this._project.currentProject?._id;
    if (currentItem && projectId && this._stories.selectedStoryId) {
      this._stories.reorderPanel(
        projectId,
        this._stories.selectedStoryId,
        currentItem.panelId,
        currentIndex + 1
      );
    }
  }

  removePanel(currentIndex: number) {
    this._stories.deletePanel(
      this._stories.selectedStory?.panels[currentIndex].panelId ?? ''
    );
  }

  setComponentMode(mode: StoryEditComponentMode) {
    this.componentMode = mode;
  }

  openEditPanel(panelId: string) {
    this._stories.setStoryEditMode('edit-panel');
    this._stories.editPanelId = panelId;

    const panel = this._stories.selectedStory?.panels.filter(
      (panel) => panel.panelId === this._stories.editPanelId
    )[0];

    if (!panel) return;
    this.panelForm?.setValue({
      heading: panel.heading,
      content: panel.content,
      mediaUrl: panel.mediaUrl,
      queryParams: panel.queryParams,
    });
  }
  closeEditPanel() {
    this._stories.setStoryEditMode('edit-list');
    this._stories.editPanelId = '';

    this.panelForm?.reset();
    this.viewSet = false;
  }

  applyPanelEdit() {
    const editPanelId = this._stories.editPanelId;
    if (editPanelId) {
      this._stories.updatePanel(editPanelId, this.panelForm?.value);
    }
    this.closeEditPanel();
  }

  setExpandedPanel(idx: number) {
    this._stories.editPanelIdx = idx;
  }

  submitAddNewStory() {
    const projectId = this._project.currentProject?._id;
    const newStoryId = uuidv4();

    if (!projectId) return;

    this._stories.createStory(projectId, [
      {
        name: this.storyForm?.value['name'],
        description: this.storyForm?.value['description'],
        storyId: newStoryId,
        panels: [],
      },
    ]);
    this._stories.selectedStoryId = newStoryId;
    this._stories.setSelected();
    this.closeEditStory();
  }
  closeEditStory() {
    this._stories.setStoryEditMode('edit-list');
    this.storyForm?.reset();
  }

  openEditSelectedStory() {
    if (!this._stories.selectedStory) return;
    this._stories.setStoryEditMode('edit-story-details');

    this.storyForm?.setValue({
      name: this._stories.selectedStory.name,
      description: this._stories.selectedStory.description,
    });
  }
  submitEditSelectedStory() {
    const prevStory = this._stories.selectedStory;
    const projectId = this._project.currentProject?._id;

    if (prevStory && projectId) {
      if (this.storyForm?.dirty) {
      }

      this._stories.updateStory(projectId, prevStory.storyId, {
        name: this.storyForm?.value['name'],
        description: this.storyForm?.value['description'],
      });
      this.closeEditStory();
    }
  }

  openConfirmDeleteStory() {
    this._stories.setStoryEditMode('edit-confirm-delete-story');
  }

  closeAddNewStory() {
    this.storyForm?.reset();
    this._stories.setStoryEditMode('edit-list');
  }

  submitDeleteStory() {
    const projectId = this._project.currentProject?._id;
    const storyId = this._stories.selectedStoryId;
    if (!projectId || !storyId) return;

    this._stories.deleteStory(projectId, storyId);
    this._stories.selectedStoryId = '';
    this._stories.setSelected();
    this.closeAddNewStory();
  }

  get storyFormName() {
    return this.storyForm?.get('name');
  }

  get storyFormDescription() {
    return this.storyForm?.get('description');
  }

  get panelFormHeading() {
    return this.panelForm?.get('heading');
  }

  get panelFormContent() {
    return this.panelForm?.get('content');
  }
}

type StoryEditComponentModes = [
  'add-story',
  'add-panel',
  'edit-panel',
  'default',
  'edit-story'
];

type StoryEditComponentMode = StoryEditComponentModes[number];
type FormModel<T> = { [P in keyof T]: [T[P], any?] };
