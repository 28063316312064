import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Marker } from 'photo-sphere-viewer/dist/plugins/markers';

@Component({
  selector: 'app-modal-markers',
  templateUrl: './modal-markers.component.html',
  styleUrls: ['./modal-markers.component.scss'],
})
export class ModalMarkersComponent implements OnInit {
  @Input() marker?: Marker;
  @Input() show: boolean = false;
  @Output() showChange: EventEmitter<boolean> = new EventEmitter();

  heading: string = '';
  modalType: string = '';
  content: string = '';
  url: string = '';
  id: string = '';
  mediaId: string = '';

  constructor(private _router: Router, private _route: ActivatedRoute) {}

  ngOnInit(): void {
    this.heading = this.marker?.data.heading || 'Marker';
    this.modalType = this.marker?.data.type || 'text';
    this.content = this.marker?.data.content || '';
    this.url = this.marker?.data.url || '';
    this.id = this.marker?.id || '';
    this.mediaId = this.marker?.data.mediaId;

    if (this.id.startsWith('link')) {
      this.heading = 'Link';
    }
  }

  closeModal() {
    this._router.navigate(['./'], {
      queryParams: { marker: null },
      queryParamsHandling: 'merge',
      relativeTo: this._route,
    });
    this.showChange.emit(false);
  }
}
