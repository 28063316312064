import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Feature, FeatureCollection, Point } from '@turf/helpers';
import { Position } from 'photo-sphere-viewer';
import { map, Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { AuthService } from '../auth/auth.service';
import { MapFeatures } from '../classes/MapFeatures';
import { Media, MediaMarker, MediaType } from '../classes/Media';
import { MediaUploadReturn } from '../classes/MediaUploadReturn';
import {
  getMediaWithGeolocation,
  Project,
  ProjectMedia,
} from '../classes/Project';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  public currentPosition: Position = { longitude: 0, latitude: 0 };
  public currentZoom: number = 0;
  public currentProject?: Project;
  public deletingMarker: boolean = false;
  public showEditor: boolean = false;
  public editingMarkerId: string = '';
  public currentProjectChanged: EventEmitter<Project> = new EventEmitter();
  public panoClickEvent: EventEmitter<Position> = new EventEmitter();
  public projectUpdated: EventEmitter<void> = new EventEmitter();
  public markerDeleted: EventEmitter<boolean> = new EventEmitter();
  public toggleEditorWindow: EventEmitter<boolean> = new EventEmitter();
  public editorWindowType: EventEmitter<string> = new EventEmitter();
  public toggleEditHorizonModal: EventEmitter<boolean> = new EventEmitter();
  public openUploadModal: EventEmitter<boolean> = new EventEmitter();
  public horizonCorrectionEvent: EventEmitter<{
    tilt: number;
    roll: number;
  }> = new EventEmitter();
  public closeContextMenu: EventEmitter<void> = new EventEmitter();

  constructor(
    private _auth: AuthService,
    private _api: ApiService,
    private _http: HttpClient
  ) {}

  set(project: Project): Project {
    const newProject = new Project(project.case_id, project.name, project._id);

    this.currentProject = Object.assign(newProject, project);
    this.currentProjectChanged.emit(this.currentProject);
    return newProject;
  }

  get(projectId: string): Observable<Project> {
    return this._http.get<Project>(this._api.getUri(`projects/${projectId}`));
  }

  list(caseId: string, archived: boolean = false) {
    return this._http.get<Project[]>(
      this._api.getUri(`projects/find/${caseId}`),
      { params: { archived } }
    );
  }

  add(
    caseId: string,
    projectName: string,
    projectOwner?: string
  ): Observable<Project> {
    const newProject = new Project(caseId, projectName);

    if (projectOwner) {
      newProject.owner_id = projectOwner;
    }

    return this._http
      .put<{ acknowledged: boolean; insertedId?: string }>(
        this._api.getUri('projects/' + caseId),
        newProject
      )
      .pipe(
        map((response) => {
          return new Project(caseId, projectName, response.insertedId);
        })
      );
  }

  copyProject(project: Project) {
    const projectId = project._id;
    return this._http.post<{ acknowledged: boolean; insertedId: string }>(
      this._api.getUri(`projects/${projectId}/copy`),
      project
    );
  }

  getMediaBlob(url: string) {
    return this._http.get(this._api.getUri(url), { responseType: 'blob' });
  }

  listMedia(project: Project, type: string): ProjectMedia<MediaType>[] {
    return project.media.filter((value) => {
      return value.type === type;
    });
  }

  getMedia(
    project: Project,
    uuid: string
  ): ProjectMedia<MediaType> | undefined {
    return project.media.find((value) => {
      return value.uuid === uuid;
    });
  }

  async getMediaUrl(
    project: Project,
    key: string,
    useProxy?: boolean
  ): Promise<string> {
    return new Promise((resolve) => {
      this._http
        .get<{ url: string; token: string }>(
          this._api.getUri(`projects/${project._id}/media-url`),
          {
            params: { key: key },
          }
        )
        .subscribe((result) => {
          resolve(useProxy ? result.token : result.url);
        });
    });
  }

  addMedia(projectId: string, files: File[]): Observable<MediaUploadReturn> {
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file);
    }
    return this._http.post<MediaUploadReturn>(
      this._api.getUri(`projects/${projectId}/media`),
      formData
    );
  }

  addMarkers(
    projectId: string,
    mediaId: string,
    markers: MediaMarker[]
  ): Promise<MediaMarker[]> {
    return new Promise((resolve) => {
      this._http
        .post<MediaMarker[]>(
          this._api.getUri(`projects/${projectId}/media/${mediaId}/add-marker`),
          markers
        )
        .subscribe((markers: MediaMarker[]) => {
          resolve(markers);
        });
    });
  }

  updateMarkers(
    projectId: string,
    mediaId: string,
    marker: MediaMarker,
    index: number
  ): Promise<MediaMarker> {
    return new Promise((resolve) => {
      this._http
        .post<MediaMarker>(
          this._api.getUri(
            `projects/${projectId}/media/${mediaId}/index/${index}/update-marker`
          ),
          marker
        )
        .subscribe((marker: MediaMarker) => {
          resolve(marker);
        });
    });
  }

  updateMapFeatures(
    projectId: string,
    mapFeatures: {
      featureCollection: FeatureCollection;
      name: string;
      visible: boolean;
    }[]
  ): Observable<MapFeatures[]> {
    return this._http.post<MapFeatures[]>(
      this._api.getUri(`projects/${projectId}/update-map-features`),
      mapFeatures
    );
  }

  deleteMarker(
    projectId: string,
    mediaId: string,
    index: number
  ): Promise<{ ok: boolean }> {
    return new Promise((resolve) => {
      this._http
        .delete<{ ok: boolean }>(
          this._api.getUri(
            `projects/${projectId}/media/${mediaId}/index/${index}/delete-marker`
          )
        )
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  deleteMedia(projectId: string, mediaId: string): Promise<Project> {
    return new Promise((resolve) => {
      this._http
        .delete<Project>(
          this._api.getUri(
            `projects/${projectId}/media/${mediaId}/delete-media`
          )
        )
        .subscribe((res: Project) => {
          resolve(res);
        });
    });
  }

  updateMedia(
    projectId: string,
    media: Media<MediaType>
  ): Promise<Media<MediaType>> {
    return new Promise((resolve) => {
      this._http
        .post<Media<MediaType>>(
          this._api.getUri(`projects/${projectId}/update-media`),
          media
        )
        .subscribe((media: Media<MediaType>) => {
          resolve(media);
        });
    });
  }

  update(projectId: string, partial: Partial<Project>) {
    return this._http.post(this._api.getUri('projects/' + projectId), {
      $set: partial,
    });
  }

  delete(projectId: string) {
    return this._http.delete(this._api.getUri(`projects/${projectId}`));
  }

  archive(projectId: string, archive: boolean) {
    const partial = { archived: archive };
    return this._http.post(this._api.getUri('projects/' + projectId), {
      $set: partial,
    });
  }

  panoClick(data: Position) {
    this.panoClickEvent.emit({
      latitude: data.latitude,
      longitude: data.longitude,
    });
  }

  showEditHorizonModal(show: boolean) {
    this.toggleEditHorizonModal.emit(show);
  }

  horizonCorrection(data: { tilt: number; roll: number }) {
    this.horizonCorrectionEvent.emit(data);
  }

  updateCurrentPosition(newPosition: Position) {
    this.currentPosition = newPosition;
  }

  updateCurrentZoom(zoomLevel: number) {
    this.currentZoom = zoomLevel;
  }

  setDeletingMarker(deleting: boolean) {
    this.deletingMarker = deleting;
  }

  markerDeleteFalse() {
    this.deletingMarker = false;
    this.markerDeleted.emit(this.deletingMarker);
  }

  toggleEditor() {
    this.showEditor = true;
    this.toggleEditorWindow.emit(this.showEditor);
  }

  selectEditorType(type: string) {
    this.editorWindowType.emit(type);
  }

  setShowProjectDescription(projectId: string, show: boolean) {
    this._auth.storage.setItem(`description-ignore-${projectId}`, String(show));
  }

  getShowProjectDescription(projectId: string): boolean {
    const ignore: boolean =
      this._auth.storage.getItem(`description-ignore-${projectId}`) !== 'false';
    return ignore;
  }

  generateMapMarkers(project?: Project): Array<{
    text: string;
    icon: string;
    uuid: string;
    url: string;
    feature: Feature<Point>;
  }> {
    const links: Array<{
      text: string;
      uuid: string;
      icon: string;
      url: string;
      feature: Feature<Point>;
    }> = [];

    if (project) {
      const mediaArray = getMediaWithGeolocation(project);
      for (const media of mediaArray) {
        if (media.geolocation?.type && media.show_on_map) {
          links.push({
            text: media.name || '',
            uuid: media.uuid || '',
            icon: '/assets/link.png',
            url: `/cases/${project.case_id}/${project._id}/${media.type}/${media.uuid}?project=true`,
            feature: media.geolocation,
          });
        }
      }
    }

    return links;
  }

  getVideoHighlights(projectId: string, videoId: string) {
    return this._http.get<{
      highlights: {
        startTime: number;
        endTime: number;
        id: string;
        text: string;
      }[];
    }>(
      this._api.getUri(
        `projects/${projectId}/media/${videoId}/video-highlights`
      )
    );
  }

  addVideoHighlights(
    projectId: string,
    videoId: string,
    highlights: {
      startTime: number;
      endTime: number;
      id: string;
      text: string;
    }[]
  ) {
    return new Promise((resolve) => {
      this._http
        .post<
          {
            startTime: number;
            endTime: number;
            id: string;
            text: string;
          }[]
        >(
          this._api.getUri(
            `projects/${projectId}/media/${videoId}/video-highlights`
          ),
          highlights
        )
        .subscribe((res) => resolve(res));
    });
  }

  removeVideoHighlight(
    projectId: string,
    videoId: string,
    highlightId: string
  ) {
    return new Promise((resolve) => {
      this._http
        .delete(
          this._api.getUri(
            `projects/${projectId}/media/${videoId}/video-highlights/${highlightId}`
          )
        )
        .subscribe((res) => resolve(res));
    });
  }
}
