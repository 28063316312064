<div class="menu-items">
  <ul class="menu">
    <li *ngIf="loggedIn && userRole !== 'guest'">
      <a [routerLink]="['/cases']" routerLinkActive="active">Cases</a>
    </li>
    <li *ngIf="loggedIn && isAdmin">
      <a [routerLink]="['/users']" routerLinkActive="active">Users</a>
    </li>
    <li *ngIf="!loggedIn">
      <a [routerLink]="['/login']" routerLinkActive="active">Login</a>
    </li>
    <li *ngIf="loggedIn">
      <a [routerLink]="['/logout']" routerLinkActive="active">Logout</a>
    </li>
  </ul>
</div>
