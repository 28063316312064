<form (ngSubmit)="saveUser()">
  <div class="form-section">
    <div class="form-item">
      <label for="user-name">Name</label>
      <input name="user-name" [(ngModel)]="userName" type="text" />
    </div>
    <div class="form-item">
      <label for="user-email">Email</label>
      <input name="user-email" [(ngModel)]="userEmail" type="email" />
    </div>
    <div class="form-item">
      <label for="user-role">Role</label>
      <select name="user-role" [(ngModel)]="userRole">
        <option value="user">User</option>
        <option value="admin">Admin</option>
      </select>
    </div>
  </div>
  <div class="placeholder">
    <div class="error" *ngIf="this.displayError">
      Enter a user name and email
    </div>
  </div>
  <div class="actions">
    <button type="button" (click)="closeModal()">Cancel</button>
    <button type="submit">Add User</button>
  </div>
</form>
