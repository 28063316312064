<div *ngIf="!success; else emailSent">
  <form (ngSubmit)="login()">
    <p>Please login to access this page.</p>
    <div class="email-input">
      <input
        name="login-email"
        type="email"
        [(ngModel)]="email"
        placeholder="Email"
      />
    </div>
    <!-- <input name="login-remember" type="checkbox" [(ngModel)]="remember" />
        <label for="login-remember">Remember Me</label> -->
    <!-- <br> -->
    <button class="login">Login</button>
  </form>
  <div *ngIf="error" class="error">{{ error }}</div>
</div>

<ng-template #emailSent>
  <div class="success">
    Email sent to {{ email }}. Please check your inbox for a login link.
  </div></ng-template
>
<app-modal
  *ngIf="success && showModal"
  heading="Login Success"
  (showChange)="closeModal()"
>
  <p>An email has been sent to {{ email }}</p>
  <p>
    Please check your inbox and follow the link to access
    <span class="wavefront">WAVEFRONT</span>
  </p>
</app-modal>
