import { Component, OnInit, Input } from '@angular/core';
import { ProjectService } from 'src/app/projects/project.service';
import {
  faList,
  faTableCells,
  faX,
  faTrash,
  faFileImport,
} from '@fortawesome/free-solid-svg-icons';
import { Media, MediaType } from 'src/app/classes/Media';

@Component({
  selector: 'app-project-view-gallery',
  templateUrl: './project-view-gallery.component.html',
  styleUrls: ['./project-view-gallery.component.scss'],
})
export class ProjectViewGalleryComponent implements OnInit {
  @Input() selection: string[] = [];
  @Input() propertiesPanel?: string;
  sortParameters?: { sortBy: string; sortDescending: boolean };
  galleryViewType: string = '';
  showProperties: boolean = false;
  mediaList: Media<MediaType>[] = [];
  propertiesPanelId?: string;
  propertiesPanelMedia?: Media<MediaType>;
  filter: string = '';
  searchText: string = '';
  faList = faList;
  faGrid = faTableCells;
  faX = faX;
  faTrash = faTrash;
  faFileImport = faFileImport;
  constructor(private _project: ProjectService) {}

  ngOnInit(): void {
    this.galleryViewType = localStorage.getItem('galleryViewType') || 'list';
    this.mediaList = this._project.currentProject?.media || [];
    this._project.currentProjectChanged.subscribe(() => {
      this.mediaList = this._project.currentProject?.media || [];
      if (this.filter) this.setFilter();
    });
    this._project.projectUpdated.subscribe(() => {
      this.mediaList = this._project.currentProject?.media || [];
      if (this.filter) this.setFilter();
    });
  }

  showPropertiesChange(bool: boolean): void {
    this.showProperties = bool;
    this.propertiesPanelId = '';
  }

  setFilter(): void {
    if (this._project.currentProject) {
      this.mediaList = this._project.currentProject?.media.filter(
        (item) => item.type === this.filter
      );
    }
  }

  clearFilter(): void {
    this.filter = '';
    this.mediaList = this._project.currentProject?.media || [];
  }

  toggleGalleryView(view: string): void {
    this.galleryViewType = view;
    localStorage.setItem('galleryViewType', this.galleryViewType);
  }

  deleteSelection(): void {
    this.selection.forEach(async (uuid) => {
      const projectId = this._project.currentProject?._id;

      if (projectId && uuid) {
        const updatedProject = await this._project.deleteMedia(projectId, uuid);
        this._project.currentProject = updatedProject;
        this._project.projectUpdated.emit();
        this._project.currentProjectChanged.emit(updatedProject);
      }
    });
    this.selection = [];
  }

  clearSelection(): void {
    this.selection = [];
  }

  clearSearch(): void {
    this.searchText = '';
  }

  onMouseDown(event: MouseEvent): void {
    if (event.button === 2) this._project.closeContextMenu.emit();
  }

  openPropertiesPanel(mediaId: string): void {
    this.propertiesPanelId = mediaId;
    this.propertiesPanelMedia = this.mediaList.find(
      (item) => item.uuid === mediaId
    );
    this.showProperties = true;
  }

  upload() {
    this._project.openUploadModal.emit(true);
  }
}
