<app-modal [(show)]="showModal" *ngIf="showModal" heading="Add Case">
  <app-case-form
    (caseAdded)="caseAdded($event)"
    (cancel)="showModal = false"
  ></app-case-form>
</app-modal>
<div class="container">
  <div class="topbar">
    <div class="heading">
      <h2>Cases</h2>
      <div>
        <button (click)="this.addCase()">New Case</button>
      </div>
    </div>
    <div class="search">
      <input
        [(ngModel)]="searchText"
        type="text"
        placeholder="Search Cases..."
      />
      <button (click)="clearSearch()">
        <fa-icon [icon]="faXmarkCircle" class="icon"></fa-icon>
      </button>
    </div>
    <div class="archive" *ngIf="!showingArchivedCases">
      <button (click)="this.getArchivedCases()">Show archived cases</button>
    </div>
    <div class="archive" *ngIf="showingArchivedCases">
      <button (click)="this.getCases()">Hide archived cases</button>
    </div>
  </div>
  <div class="divider-line"></div>
  <app-case-list
    [cases]="cases"
    [showingArchivedCases]="showingArchivedCases"
    searchText="{{ searchText }}"
  ></app-case-list>
</div>
