import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { ShareService } from './share.service';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
})
export class ShareComponent implements OnInit {
  projectId?: string;
  accessDenied: boolean = false;

  constructor(
    private _auth: AuthService,
    private _route: ActivatedRoute,
    private _share: ShareService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this._route.paramMap.subscribe((paramMap) => {
      if (paramMap.has('projectId')) {
        this.projectId = paramMap.get('projectId') || '';
        this._route.queryParamMap.subscribe((queryParams) => {
          const token = queryParams.get('jwt') || '';
          this._auth.jwt = token;
          this.getShareStatus();
        });
      }
    });
  }

  getShareStatus(): void {
    if (this.projectId) {
      this._share.status(this.projectId).subscribe((response) => {
        if (response.ok) {
          this._router.navigate(['/cases', response.caseId, this.projectId]);
        } else {
          console.error('Access not permitted');
          this.accessDenied = true;
        }
      });
    }
  }
}
