<app-modal-markers-text
  [(show)]="show"
  heading="{{ heading }}"
  *ngIf="modalType === 'text'"
  content="{{ content }}"
  (showChange)="closeModal()"
>
</app-modal-markers-text>
<app-modal-markers-photo
  [(show)]="show"
  heading="{{ heading }}"
  *ngIf="modalType === 'photo'"
  content="{{ content }}"
  url="{{ url }}"
  (showChange)="closeModal()"
>
</app-modal-markers-photo>
<app-modal-markers-video
  [(show)]="show"
  heading="{{ heading }}"
  *ngIf="modalType === 'video'"
  url="{{ url }}"
  (showChange)="closeModal()"
  [mediaId]="mediaId"
>
</app-modal-markers-video>
<app-modal-markers-pdf
  [(show)]="show"
  heading="{{ heading }}"
  *ngIf="modalType === 'pdf'"
  url="{{ url }}"
  (showChange)="closeModal()"
>
</app-modal-markers-pdf>
