<table id="list-view" *ngIf="mediaList">
  <thead>
    <tr>
      <th>
        <span
          class="sort"
          (click)="setSort('name')"
          tippy="Sort by Name"
          placement="top"
          [delay]="[500, null]"
          >Name
          <fa-icon
            *ngIf="sortBy === 'name' && sortDescending"
            [icon]="faSortDown"
          ></fa-icon
          ><fa-icon
            *ngIf="sortBy === 'name' && !sortDescending"
            [icon]="faSortUp"
          ></fa-icon>
        </span>
      </th>
      <th id="type">
        <span
          class="sort"
          (click)="setSort('type')"
          tippy="Sort by Type"
          placement="top"
          [delay]="[500, null]"
          >Type
          <fa-icon
            *ngIf="sortBy === 'type' && sortDescending"
            [icon]="faSortDown"
          ></fa-icon
          ><fa-icon
            *ngIf="sortBy === 'type' && !sortDescending"
            [icon]="faSortUp"
          ></fa-icon>
        </span>
      </th>
      <th id="show-on-map">
        <span
          class="sort"
          (click)="setSort('geolocation')"
          tippy="Sort by Geolocation"
          placement="top"
          [delay]="[500, null]"
          >Show on Map
          <fa-icon
            *ngIf="sortBy === 'geolocation' && sortDescending"
            [icon]="faSortDown"
          ></fa-icon
          ><fa-icon
            *ngIf="sortBy === 'geolocation' && !sortDescending"
            [icon]="faSortUp"
          ></fa-icon>
        </span>
      </th>
      <th id="time-date">
        <span
          class="sort"
          (click)="setSort('dateTime')"
          tippy="Sort by Date / Time"
          placement="top"
          [delay]="[500, null]"
          >Date / Time
          <fa-icon
            *ngIf="sortBy === 'dateTime' && sortDescending"
            [icon]="faSortDown"
          ></fa-icon
          ><fa-icon
            *ngIf="sortBy === 'dateTime' && !sortDescending"
            [icon]="faSortUp"
          ></fa-icon>
        </span>
      </th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody
    *ngFor="
      let media of mediaList
        | mediaSearch : searchText
        | mediaSort : sortBy : sortDescending
    "
  >
    <app-gallery-list-item
      [ngClass]="{
        'display-properties-panel': propertiesPanelId === media.uuid
      }"
      [media]="media"
      [selected]="selection.includes(media?.uuid || '')"
      (selectedChange)="updateSelected($event)"
      [propertiesPanelId]="propertiesPanelId"
      (propertiesPanelChange)="updatePropertiesPanel($event)"
      [isEditingGridCard]="isEditingGridCard"
      (isEditingGridCardChange)="updateIsEditingGridCard($event)"
      (tabEditChange)="tabToEditNextGridCard($event)"
      [editNextGridCard]="editNextGridCard"
    ></app-gallery-list-item>
  </tbody>
</table>
