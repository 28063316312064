<div class="form-section">
  <p *ngIf="!currentFiles.length">Select a photo</p>
  <p *ngIf="currentFiles.length">Select new photo</p>
  <input
    type="file"
    accept="image/*"
    name="photos"
    id="photos"
    required="required"
    (change)="imagesChanged($event)"
  />
  <div class="preview-images">
    <div class="image-card" *ngIf="src">
      <img [src]="src" />
    </div>
    <div class="image-card" *ngIf="!src">
      <img src="../../../assets/logos/logo-full.jpg" />
    </div>
    <div class="caption">
      <p><label for="caption">Caption</label></p>
      <input
        type="text"
        name="caption"
        id="caption"
        placeholder="Caption"
        [(ngModel)]="caption"
        (change)="onCaptionChange()"
        maxlength="30"
      />
      <p class="error" *ngIf="caption.length === 30">Maximum 30 characters</p>
    </div>
    <div class="content">
      <p><label for="content">Content</label></p>
      <textarea
        name="content"
        id="content"
        cols="30"
        rows="15"
        placeholder="Add a description"
        [(ngModel)]="content"
        (change)="onContentChange()"
      ></textarea>
    </div>
  </div>
</div>
