<div class="owner-container">
  <div (click)="showList = !showList" class="selectedUser">
    {{ user?.name || 'No user selected' }}
    <fa-icon
      class="caret"
      [icon]="showList ? faCaretUp : faCaretDown"
    ></fa-icon>
  </div>
  <div *ngIf="showList" class="users">
    <div class="search">
      <input
        name="searchUsers"
        type="text"
        placeholder="Search..."
        [(ngModel)]="search"
      />
    </div>
    <div class="user-list">
      <div
        class="user"
        [class.activeUser]="userItem._id === user?._id"
        *ngFor="let userItem of users | userSearch : search"
        (click)="selectUser(userItem)"
      >
        {{ userItem?.name }}
      </div>
    </div>
  </div>
</div>
