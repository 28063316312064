import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { User } from 'src/app/classes/User';
import { UserService } from 'src/app/users/user.service';

@Component({
  selector: 'app-owner-input',
  templateUrl: './owner-input.component.html',
  styleUrls: ['./owner-input.component.scss'],
})
export class OwnerInputComponent implements OnInit, OnChanges {
  users: User[] = [];
  search: string = '';
  showList: boolean = false;
  @Input() user?: User;
  @Output() userChange: EventEmitter<User> = new EventEmitter();
  @Input() userId?: string;
  @Output() userIdChange: EventEmitter<string> = new EventEmitter();
  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;

  constructor(private _users: UserService) {}

  ngOnInit(): void {
    this._users.list().subscribe((users) => {
      this.users = users;
      if (this.userId) {
        this.user = users.find((user) => user._id === this.userId);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['userId']) {
      this.user = this.users.find(
        (user) => user._id === changes['userId'].currentValue
      );
    }
    if (changes['user']) {
      this.userId = changes['user'].currentValue._id;
    }
  }

  selectUser(user: User) {
    this.user = user;
    this.showList = false;
    this.search = '';
    this.userChange.emit(user);
    this.userIdChange.emit(user._id);
  }
}
