<app-modal
  [(show)]="show"
  heading="{{ heading }}"
  (showChange)="closeModal()"
  type="large"
>
  <div class="placeholder" *ngIf="url">
    <app-video
      [url]="dataUrl"
      *ngIf="dataUrl"
      [modal]="true"
      controls
      [videoId]="mediaId"
    >
    </app-video>
    <div class="loading" *ngIf="!dataUrl">
      <img
        src="../../../assets/logos/logo-full-trans-small.png"
        alt="loading"
      />
    </div>
  </div>
</app-modal>
