<div class="editor">
  <div class="title">
    <h4>Edit style</h4>
  </div>
  <hr />
  <div class="fill" *ngIf="(feature?.geometry)!.type !== 'LineString'">
    <div class="item fill-color">
      <div>Fill Color</div>
      <input
        type="color"
        class="color-picker"
        [ngStyle]="{ 'background-color': fillColor }"
        [value]="convertRgbToHex('fill')"
        [(ngModel)]="fillColor"
        (change)="convertHexToRGB($event, 'fill')"
      />
    </div>
    <div class="item fill-opacity">
      <div class="value">Fill Opacity</div>
      <div class="opacity">
        <input
          type="number"
          [(ngModel)]="(feature?.properties)!['fill'][3]"
          min="0"
          max="1"
          step="0.05"
          (ngModelChange)="updateFeature()"
        />
      </div>
    </div>
  </div>
  <div class="stroke">
    <div class="item stroke-color">
      <div class="value">Stroke Color</div>
      <input
        type="color"
        class="color-picker"
        [ngStyle]="{ 'background-color': strokeColor }"
        [value]="convertRgbToHex('stroke')"
        [(ngModel)]="strokeColor"
        (change)="convertHexToRGB($event, 'stroke')"
      />
    </div>
    <div class="item stroke-opacity">
      <div class="value">Stroke Opacity</div>
      <div class="opacity">
        <input
          type="number"
          [(ngModel)]="(feature?.properties)!['stroke'][3]"
          min="0"
          max="1"
          step="0.05"
          (ngModelChange)="updateFeature()"
        />
      </div>
    </div>
    <div class="item stroke-width">
      <div class="value">Stroke width</div>
      <div class="width">
        <input
          type="number"
          [(ngModel)]="(feature?.properties)!['strokeWidth']"
          min="1"
          max="100"
          step="1"
          (ngModelChange)="updateFeature()"
        />
      </div>
    </div>
  </div>
  <div class="actions">
    <button type="button" (click)="cancel()">Cancel</button>
    <button type="button" (click)="saveFeature()">Save</button>
  </div>
</div>
