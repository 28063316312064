import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../classes/User';

@Pipe({
  name: 'userSearch',
})
export class UserSearchPipe implements PipeTransform {
  transform(value: User[], search: string): User[] {
    const result = search.length
      ? value.filter((user) => {
          return user.name.toLowerCase().includes(search.toLowerCase());
        })
      : value;
    return result;
  }
}
