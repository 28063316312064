import { Component, Input } from '@angular/core';
import {
  Feature as geojsonFeature,
  Point as geojsonPoint,
} from '@turf/helpers';

@Component({
  selector: 'app-map-inset',
  templateUrl: './map-inset.component.html',
  styleUrls: ['./map-inset.component.scss'],
})
export class MapInsetComponent {
  @Input() links: Array<{
    text: string;
    icon: string;
    uuid: string;
    url: string;
    feature: geojsonFeature<geojsonPoint>;
  }> = [];
  @Input() highlight: string = '';
}
