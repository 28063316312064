import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CaseService } from '../cases/case.service';
import { Case } from '../classes/Case';
import { Project } from '../classes/Project';
import { TitleService } from '../title.service';
import { ProjectService } from './project.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit {
  case?: Case;
  projects: Project[] = [];
  caseId: string = '';
  showAddProjectModal: boolean = false;
  showingArchivedProjects: boolean = false;

  constructor(
    private _project: ProjectService,
    private _case: CaseService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _title: TitleService
  ) {}

  ngOnInit(): void {
    this._route.paramMap.subscribe((paramMap) => {
      this.caseId = paramMap.get('caseId') || '';
      this.getProjects(this.caseId);
      this.getCase(this.caseId);
    });
  }

  getCase(caseId: string) {
    this._case.get(caseId).subscribe((currentCase) => {
      this.case = currentCase;
      this._title.setTitle(this.case.name);
    });
  }

  getProjects(caseId: string) {
    this.showingArchivedProjects = false;
    if (caseId) {
      this._project
        .list(caseId, false)
        .subscribe((projects) => (this.projects = projects));
    }
  }

  getArchivedProjects(caseId: string) {
    this.showingArchivedProjects = true;
    if (caseId) {
      this._project
        .list(caseId, true)
        .subscribe((projects) => (this.projects = projects));
    }
  }

  projectAdded(project: Project) {
    if (project._id) {
      this._router.navigate([project._id], { relativeTo: this._route });
    }
  }

  addProject() {
    this.showAddProjectModal = true;
  }
}
