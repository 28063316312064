import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-markers-text',
  templateUrl: './modal-markers-text.component.html',
  styleUrls: ['./modal-markers-text.component.scss'],
})
export class ModalMarkersTextComponent {
  @Input() heading: string = '';
  @Input() show: boolean = false;
  @Input() content: string = '';
  @Output() showChange: EventEmitter<boolean> = new EventEmitter();

  closeModal() {
    this.showChange.emit(false);
  }
}
