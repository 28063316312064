import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getMediaById, Project } from 'src/app/classes/Project';
import { environment } from 'src/environments/environment';
import { ProjectService } from '../project.service';

@Component({
  selector: 'app-project-view-pdf',
  templateUrl: './project-view-pdf.component.html',
  styleUrls: ['./project-view-pdf.component.scss'],
})
export class ProjectViewPdfComponent implements OnInit {
  pdfId: string = '';
  project?: Project;
  url?: string;
  pdf?: Blob;
  page: number = 1;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _project: ProjectService
  ) {
    if (this._project.currentProject) {
      this.project = this._project.currentProject;
    }
  }

  ngOnInit(): void {
    this._route.paramMap.subscribe((params) => {
      this.pdfId = params.get('mediaId') ?? '';
      if (this.project) {
        this.getMediaUrl();
      }
    });

    if (this._route.snapshot.queryParamMap.has('page')) {
      this.page = Number(this._route.snapshot.queryParamMap.get('page'));
    }
  }

  pageChange(pageNumber: number) {
    this._router.navigate(['.'], {
      queryParams: { page: pageNumber },
      queryParamsHandling: 'merge',
      relativeTo: this._route,
    });
  }

  async getMediaUrl() {
    const media = getMediaById(this.project, this.pdfId);
    try {
      if (this.project) {
        this.url = `${environment.apiUri}${await this._project.getMediaUrl(
          this.project,
          media.file?.url || '',
          true
        )}`;
      }
    } catch (err) {
      console.error('getMediaUrl:pdf', err);
    }
  }
}
