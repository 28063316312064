import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Case } from '../classes/Case';
import { TitleService } from '../title.service';
import { CaseService } from './case.service';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-cases',
  templateUrl: './cases.component.html',
  styleUrls: ['./cases.component.scss'],
  providers: [],
})
export class CasesComponent implements OnInit {
  cases: Case[] = [];
  showModal: boolean = false;
  showingArchivedCases: boolean = false;
  faXmarkCircle = faXmarkCircle;

  constructor(
    private _case: CaseService,
    private _router: Router,
    private _title: TitleService
  ) {}

  ngOnInit(): void {
    this._title.setTitle('Cases');
    this.getCases();
  }

  getCases() {
    this.showingArchivedCases = false;
    this._case.list(false).subscribe((cases) => {
      this.cases = this.sortCases(cases);
    });
  }

  sortCases(cases: Case[]) {
    cases.sort((a, b) => {
      if (a.created_at && b.created_at) {
        return (
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      } else if (a.hasOwnProperty('created_at')) {
        return -1;
      } else if (b.hasOwnProperty('created_at')) {
        return 1;
      } else return 0;
    });
    return cases;
  }

  getArchivedCases() {
    this.showingArchivedCases = true;
    this._case.list(true).subscribe((cases) => {
      this.cases = this.sortCases(cases);
    });
  }

  addCase() {
    this.showModal = true;
  }

  caseAdded(newCase: Case): void {
    this._router.navigate(['/cases', newCase._id]);
  }
  searchText = '';

  clearSearch() {
    this.searchText = '';
  }
}
