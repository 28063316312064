import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Feature, featureCollection, FeatureCollection } from '@turf/helpers';
import { ProjectService } from 'src/app/projects/project.service';
import {
  faTrash,
  faPencil,
  faLocationDot,
  faDrawPolygon,
  faArrowPointer,
  faRoute,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { Coordinate } from 'ol/coordinate';
import GeoJSON from 'ol/format/GeoJSON';
import { getCenter } from 'ol/extent';

@Component({
  selector: 'app-editor-map-layers',
  templateUrl: './editor-map-layers.component.html',
  styleUrls: ['./editor-map-layers.component.scss'],
})
export class EditorMapLayersComponent implements OnInit {
  @Input() drawType: string = 'Cancel';
  @Output() drawTypeChange = new EventEmitter<string>();
  @Output() showWindowChange = new EventEmitter<boolean>();
  @Output() featureCollectionIndexChange = new EventEmitter<number>();
  @Input() geojsonFeatures: Array<{
    name: string;
    visible: boolean;
    featureCollection: FeatureCollection;
  }> = [];
  @Output() geojsonFeaturesChange = new EventEmitter<
    Array<{
      name: string;
      visible: boolean;
      featureCollection: FeatureCollection;
    }>
  >();
  @Output() mapCoordinates = new EventEmitter<Coordinate>();
  mapFeatures?: Array<{
    name: string;
    visible: boolean;
    featureCollection: FeatureCollection;
  }> = [];
  selectedLayerIndex: number = 0;
  selectedFeatureIndex: number = -1;
  editLayer: boolean = false;
  editFeature: boolean = false;
  updatedLayerName: string = '';
  updatedFeatureName: string = '';
  faTrash = faTrash;
  faPencil = faPencil;
  faLocationDot = faLocationDot;
  faDrawPolygon = faDrawPolygon;
  faArrowPointer = faArrowPointer;
  faRoute = faRoute;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faDownload = faDownload;

  constructor(private _project: ProjectService) {}

  ngOnInit(): void {
    this.selectedLayerIndex = 0;
  }

  OnChanges(changes: SimpleChanges): void {
    if (changes['drawType'] && changes['drawType'].currentValue === 'Cancel') {
      this.drawType = 'Cancel';
    }
  }

  selectDrawType(type: string) {
    this.drawType = type;
    this.drawTypeChange.emit(type);
  }

  selectLayer(layerIndex: number) {
    this.selectedLayerIndex = layerIndex;
    this.selectedFeatureIndex = -1;
    this.updatedLayerName = this.geojsonFeatures[this.selectedLayerIndex].name;
    this.editFeature = false;
    this.featureCollectionIndexChange.emit(layerIndex);
  }

  selectFeature(index: number, center: boolean) {
    this.selectedFeatureIndex = index;
    this.editLayer = false;
    const feature =
      this.geojsonFeatures[this.selectedLayerIndex].featureCollection.features[
        index
      ];
    this.updatedFeatureName = feature.properties?.['title'] ?? '';

    if (center) {
      const feature = this.geojsonFeatures[this.selectedLayerIndex]
        .featureCollection.features[this.selectedFeatureIndex] as Feature;
      const geoJsonFeature = new GeoJSON().readFeature(feature);

      const featureExtent = geoJsonFeature.getGeometry()?.getExtent();
      if (featureExtent) {
        const coordinates = getCenter(featureExtent);
        this.mapCoordinates.emit(coordinates);
      }
    }
  }

  addLayer() {
    const newLayer = {
      name: 'Name',
      visible: true,
      featureCollection: featureCollection([]),
    };
    this.geojsonFeatures?.push(newLayer);
    this.updateProject();
  }

  renameLayer(index: number) {
    this.selectedLayerIndex = index;
    this.editLayer = !this.editLayer;
    this.editFeature = false;
  }

  renameFeature(index: number) {
    this.selectedFeatureIndex = index;
    this.editFeature = !this.editFeature;
    this.editLayer = false;
  }

  updateLayerName(event: Event & { target: EventTarget | null }) {
    //TODO: this type needs resolving
    const target = event.target as HTMLInputElement;
    this.updatedLayerName = target.innerHTML;
  }

  updateFeatureName(event: Event & { target: EventTarget | null }) {
    //TODO: this type needs resolving
    const target = event.target as HTMLInputElement;
    this.updatedFeatureName = target.innerHTML;
  }

  edit() {
    const geojsonFeature = this.geojsonFeatures[this.selectedLayerIndex];
    if (this.editLayer) {
      this.editLayer = false;
      if (geojsonFeature.name !== this.updatedLayerName) {
        geojsonFeature.name = this.updatedLayerName;
        this.updateProject();
      }
    } else if (this.editFeature) {
      this.editFeature = false;
      const selectedFeature =
        geojsonFeature.featureCollection.features[this.selectedFeatureIndex];
      if (
        selectedFeature.properties?.['title'] &&
        selectedFeature.properties?.['title'] !== this.updatedFeatureName
      ) {
        selectedFeature.properties['title'] = this.updatedFeatureName;
        this.updateProject();
      }
    }
  }

  setLayerName() {
    this.updatedLayerName = this.geojsonFeatures[this.selectedLayerIndex].name;
  }

  setFeatureName() {
    this.updatedFeatureName =
      this.geojsonFeatures[this.selectedLayerIndex].featureCollection.features[
        this.selectedFeatureIndex
      ].properties?.['title'] ?? '';
  }

  deleteLayer(index: number) {
    this.geojsonFeatures.splice(index, 1);
    this.updateProject();
  }

  deleteFeature(index: number) {
    this.geojsonFeatures[
      this.selectedLayerIndex
    ].featureCollection.features.splice(index, 1);
    this.updateProject();
  }

  showHideFeature(index: number, show: boolean) {
    const feature =
      this.geojsonFeatures[this.selectedLayerIndex].featureCollection.features[
        index
      ];
    if (feature.properties) {
      feature.properties['visible'] = show;
    }
    this.updateProject();
  }

  showHideLayer(index: number, show: boolean) {
    this.geojsonFeatures[index]['visible'] = show;
    this.updateProject();
  }

  downloadFeatureCollection(index: number) {
    const feature = this.geojsonFeatures[index].featureCollection;
    const geojsonFeature = JSON.stringify(feature);
    const fileName = `${this.geojsonFeatures[index].name}.geojson`;
    this.download(geojsonFeature, fileName);
  }

  downloadFeature(index: number) {
    const feature =
      this.geojsonFeatures[this.selectedLayerIndex].featureCollection.features[
        index
      ];
    const geojsonFeature = JSON.stringify(feature);
    const fileName = `${feature.properties?.['title']}.geojson`;
    this.download(geojsonFeature, fileName);
  }

  downloadAllFeatures() {
    const allFeatures: FeatureCollection = featureCollection([]);
    this.geojsonFeatures.forEach((layer) => {
      layer.featureCollection.features.forEach((feature) => {
        allFeatures.features.push(feature);
      });
    });
    const allGeojsonFeatures = JSON.stringify(allFeatures);
    const fileName = `${this._project.currentProject?.name} - all features.geojson`;
    this.download(allGeojsonFeatures, fileName);
  }

  download(data: string, filename: string) {
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/json;charset=UTF-8,' + encodeURIComponent(data)
    );
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  updateProject() {
    const projectId = this._project.currentProject?._id;
    if (projectId) {
      this._project
        .updateMapFeatures(projectId, this.geojsonFeatures)
        .subscribe(() => {
          this.geojsonFeaturesChange.emit(this.geojsonFeatures);
          this.updatedFeatureName = '';
          this.updatedLayerName = '';
        });
    }
  }

  close() {
    this.drawTypeChange.emit('Cancel');
    this.showWindowChange.emit(false);
  }
}
