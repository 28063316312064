import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TitleService } from 'src/app/title.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-logout-screen',
  templateUrl: './logout-screen.component.html',
  styleUrls: ['./logout-screen.component.scss'],
})
export class LogoutScreenComponent implements OnInit {
  constructor(
    private _auth: AuthService,
    private _router: Router,
    private _title: TitleService
  ) {}

  ngOnInit(): void {
    this._title.setTitle('Login');
    this._auth.logout();
    setTimeout(() => {
      this._router.navigate(['/login']);
    }, 5000);
  }
}
