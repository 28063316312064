<div class="editor-window">
  <div class="editor" *ngIf="this.showEditor">
    <div class="title">
      <h4>Add a {{ this.type }} info spot</h4>
    </div>
    <div class="form-section">
      <div *ngIf="this.type === 'link'">
        <app-editor-window-link
          (newSelectEvent)="setLinkToMediaKey($event)"
        ></app-editor-window-link>
      </div>
      <div *ngIf="this.type === 'text'">
        <app-editor-window-text
          (newCaptionEvent)="setCaption($event)"
          (newContentEvent)="setContent($event)"
          caption="{{ caption }}"
          content="{{ content }}"
        ></app-editor-window-text>
      </div>
      <div *ngIf="this.type === 'photo'">
        <app-editor-window-photo
          (newCaptionEvent)="setCaption($event)"
          (newContentEvent)="setContent($event)"
          (newFilesEvent)="setFiles($event)"
          caption="{{ caption }}"
          content="{{ content }}"
          [currentFiles]="currentFiles"
        ></app-editor-window-photo>
      </div>
      <div *ngIf="this.type === 'video'">
        <app-editor-window-video
          (newCaptionEvent)="setCaption($event)"
          (newFilesEvent)="setFiles($event)"
          caption="{{ caption }}"
          [currentFiles]="currentFiles"
        ></app-editor-window-video>
      </div>
      <div *ngIf="this.type === 'pdf'">
        <app-editor-window-pdf
          (newCaptionEvent)="setCaption($event)"
          (newFilesEvent)="setFiles($event)"
          caption="{{ caption }}"
          [currentFiles]="currentFiles"
        ></app-editor-window-pdf>
      </div>
    </div>
    <div class="actions">
      <button type="button" (click)="cancel()">Cancel</button>
      <button type="submit" *ngIf="!editingMarkerId" (click)="add()">
        Add
      </button>
      <button type="submit" *ngIf="editingMarkerId" (click)="save()">
        Save
      </button>
    </div>
  </div>
</div>
