import { Injectable } from '@angular/core';
import { featureCollection } from '@turf/helpers';
import { Collection, Feature, Map } from 'ol';
import { DragAndDrop, Draw, Modify, Select, Translate } from 'ol/interaction';
import { MapFeatures } from '../classes/MapFeatures';
import { ProjectService } from '../projects/project.service';
import GeoJSON from 'ol/format/GeoJSON';
import { Geometry as olGeometry } from 'ol/geom';
import { Fill, Stroke, Style, Text } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import { GeoJsonGeometryTypes } from 'geojson';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

@Injectable({
  providedIn: 'root',
})
export class MapFeaturesService {
  constructor(private _project: ProjectService) {}

  findFeature(
    map_features: MapFeatures[],
    id: string
  ): { layerIndex: number; featureIndex: number } {
    let layerIndex = -1;
    let featureIndex = -1;

    map_features.find((mapFeatureLayer, findLayerIndex) => {
      const findFeatureIndex =
        mapFeatureLayer.featureCollection.features.findIndex((feature) => {
          return feature.properties ? id === feature.properties['id'] : false;
        });

      if (findFeatureIndex !== -1) {
        layerIndex = findLayerIndex;
        featureIndex = findFeatureIndex;
        return true;
      }
      return false;
    });

    return { layerIndex, featureIndex };
  }

  updateFeatureProperties(
    map_features: MapFeatures[],
    id: string,
    properties: { [key: string]: string | number | boolean }
  ): MapFeatures[] {
    const featureLocation = this.findFeature(map_features, id);
    let featureProperties =
      map_features[featureLocation.layerIndex].featureCollection.features[
        featureLocation.featureIndex
      ].properties ?? {};

    for (const prop in properties) {
      featureProperties[prop] = properties[prop];
    }

    if (this._project.currentProject) {
      this._project
        .update(this._project.currentProject._id ?? '', { map_features })
        .subscribe();
    }

    return map_features;
  }

  editFeatureCoordinates(
    map: Map,
    map_features: MapFeatures[],
    feature: Feature
  ) {
    map
      ?.getInteractions()
      .getArray()
      .forEach((interaction) => {
        if (interaction instanceof Select || interaction instanceof Modify) {
          map?.removeInteraction(interaction);
        }
      });

    if (!feature) throw new Error('no feature selected');
    const select = new Select({
      features: new Collection([feature]),
    });
    map?.addInteraction(select);
    const modify = new Modify({
      features: new Collection([feature]),
    });
    map?.addInteraction(modify);

    let modifiedFeature: Feature<olGeometry>;
    select.on('select', () => {
      if (modifiedFeature) {
        const newFeature = JSON.parse(
          new GeoJSON({
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857',
          }).writeFeature(modifiedFeature)
        );

        const featureLocation = this.findFeature(
          map_features,
          String(modifiedFeature.getId())
        );

        map_features[featureLocation.layerIndex].featureCollection.features[
          featureLocation.featureIndex
        ] = newFeature;

        const projectId = this._project.currentProject?._id;
        if (projectId) {
          this._project.updateMapFeatures(projectId, map_features).subscribe();
        }
      }
      map?.removeInteraction(select);
      map?.removeInteraction(modify);
    });

    modify.on('modifyend', (e) => {
      //TODO: come back to this type FeatureLink = Feature<Geometry> | ... so should be safe
      modifiedFeature = e.features.getArray()[0] as Feature<olGeometry>;
    });
  }

  moveGeojsonFeature(map: Map, map_features: MapFeatures[], feature: Feature) {
    if (!feature) throw new Error('no feature selected');
    const select = new Select({
      features: new Collection([feature]),
    });
    map?.addInteraction(select);
    const translate = new Translate({
      features: new Collection([feature]),
    });
    translate.on('translateend', (e) => {
      const newFeature = JSON.parse(
        new GeoJSON({
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857',
        }).writeFeature(e.features.getArray()[0])
      );

      map_features.forEach((collection) => {
        collection.featureCollection.features =
          collection.featureCollection.features.map((feature) => {
            if (feature.properties?.['id'] === newFeature.properties?.['id']) {
              return newFeature;
            } else {
              return feature;
            }
          });
      });

      const projectId = this._project.currentProject?._id;
      if (projectId) {
        this._project.updateMapFeatures(projectId, map_features).subscribe();
      }
      map.removeInteraction(select);
      map.removeInteraction(translate);
    });
    map.addInteraction(translate);
  }

  moveGeojsonLayer(
    map: Map,
    map_features: MapFeatures[],
    feature: Feature,
    geoJsonLayer: VectorLayer<VectorSource>
  ) {
    const { layerIndex } = this.findFeature(
      map_features,
      String(feature.getId())
    );

    if (layerIndex < 0 || layerIndex >= map_features.length)
      throw new Error('Invalid layer index');

    const featureIds: string[] = map_features[
      layerIndex
    ].featureCollection.features.map((feature) => feature.properties?.['id']);

    if (!featureIds || featureIds.length === 0)
      throw new Error('no features in selected layer');

    const olFeatures: Feature<olGeometry>[] = [];

    featureIds.forEach((id) => {
      const olFeature = geoJsonLayer.getSource()?.getFeatureById(id);
      if (olFeature) olFeatures.push(olFeature);
    });

    const select = new Select({
      features: new Collection(olFeatures),
    });

    map?.addInteraction(select);

    const translate = new Translate({
      features: new Collection(olFeatures),
    });

    translate.on('translateend', (e) => {
      const newFeatures = e.features.getArray().map((feature) =>
        JSON.parse(
          new GeoJSON({
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857',
          }).writeFeature(feature)
        )
      );

      map_features[layerIndex].featureCollection.features = newFeatures;

      const projectId = this._project.currentProject?._id;

      if (projectId) {
        this._project.updateMapFeatures(projectId, map_features).subscribe();
      }

      map.removeInteraction(select);
      map.removeInteraction(translate);
    });
    map.addInteraction(translate);
  }

  deleteFeature(map_features: MapFeatures[], feature: Feature) {
    map_features.forEach((collection) => {
      collection.featureCollection.features.forEach((itFeature, index) => {
        if (itFeature.properties?.['id'] === feature?.getProperties()['id']) {
          collection.featureCollection.features.splice(index, 1);
        }
      });
    });

    const projectId = this._project.currentProject?._id;
    if (projectId) {
      this._project.updateMapFeatures(projectId, map_features).subscribe();
    }
  }

  generateGeojsonStyle(feature: Feature): Style {
    return new Style({
      fill: new Fill({
        color: feature.getProperties()['fill'] || [255, 105, 0, 0.5],
      }),
      stroke: new Stroke({
        color: feature.getProperties()['stroke'] || [255, 105, 0, 1],
        width: feature.getProperties()['strokeWidth'] || 2,
      }),
      image: new CircleStyle({
        radius: 5,
        fill: new Fill({
          color: feature.getProperties()['fill'] || [255, 105, 0, 1],
        }),
        stroke: new Stroke({
          color: feature.getProperties()['stroke'] || [255, 105, 0, 1],
          width: feature.getProperties()['strokeWidth'] || 2,
        }),
      }),
      text: new Text({
        text: feature.getProperties()['showTitle']
          ? feature.getProperties()['title']
          : undefined,
        textAlign:
          feature.getGeometry()?.getType() === 'Point' ? 'left' : 'center',
        offsetX: feature.getGeometry()?.getType() === 'Point' ? 10 : 0,
        scale: 1.5,
      }),
    });
  }

  downloadGeojsonFeature(selectedFeature: Feature) {
    if (!selectedFeature) throw new Error('no selected feature');
    const feature = new GeoJSON({
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857',
    }).writeFeature(selectedFeature);
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/json;charset=UTF-8,' + encodeURIComponent(feature)
    );
    element.setAttribute(
      'download',
      `${selectedFeature?.getProperties()['title']}.geojson`
    );
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  addDragAndDrop(
    map: Map,
    map_features: MapFeatures[]
  ): Promise<MapFeatures[]> {
    return new Promise((resolve, reject) => {
      map
        ?.getInteractions()
        .getArray()
        .forEach((interaction) => {
          if (interaction instanceof DragAndDrop) {
            map?.removeInteraction(interaction);
          }
        });

      const dragAndDrop = new DragAndDrop({
        formatConstructors: [GeoJSON],
      });

      dragAndDrop.on('addfeatures', (e) => {
        try {
          const newLayer = {
            name: 'New Layer',
            visible: true,
            featureCollection: featureCollection([]),
          };

          e.features?.forEach((feature) => {
            const newFeature = JSON.parse(
              new GeoJSON({
                dataProjection: 'EPSG:4326',
                featureProjection: 'EPSG:3857',
              }).writeFeature(feature as Feature)
            );
            if (newFeature.properties.id) delete newFeature.properties.id;

            let name: string;
            if (newFeature.properties.title) {
              name = newFeature.properties.title;
            } else if (newFeature.geometry.type === 'LineString') {
              name = 'New Line';
            } else {
              name = `New ${newFeature.geometry.type}`;
            }

            newFeature.properties = {
              title: name,
              description: newFeature.properties.description || '',
              markerSize: newFeature.properties.markerSize || 'medium',
              markerSymbol: newFeature.properties.markerSymbol || '',
              markerColor: newFeature.properties.markerColor || [
                255, 105, 0, 1,
              ],
              stroke: newFeature.properties.stroke || [255, 105, 0, 1],
              strokeWidth: newFeature.properties.strokeWidth || 2,
              fill: newFeature.properties.fill || [255, 105, 0, 0.5],
              visible: newFeature.properties.visible,
            };

            newLayer.featureCollection.features.push(newFeature);
          });

          map_features.push(newLayer);
          const projectId = this._project.currentProject?._id;
          if (projectId) {
            this._project
              .updateMapFeatures(projectId, map_features)
              .subscribe((mapFeatures) => {
                resolve(mapFeatures);
              });
          } else {
            resolve([]);
          }
        } catch (err) {
          reject(err);
        }
      });
      map?.addInteraction(dragAndDrop);
    });
  }

  addDraw(
    map: Map,
    map_features: MapFeatures[],
    map_feature_layer: VectorLayer<VectorSource>,
    collectionIndex: number,
    drawType: string
  ): Promise<void> {
    return new Promise((resolve) => {
      map
        ?.getInteractions()
        .getArray()
        .forEach((interaction) => {
          if (interaction instanceof Draw) {
            map?.removeInteraction(interaction);
          }
        });

      if (drawType === 'Cancel') {
        resolve();
        return;
      }

      const drawStyle = new Style({
        fill: new Fill({
          color: 'rgba(255, 105, 0, 0.5)',
        }),
        stroke: new Stroke({
          color: 'rgba(255, 105, 0, 1)',
          width: 2,
        }),
        image: new CircleStyle({
          radius: 3,
          stroke: new Stroke({
            color: 'rgba(255, 105, 0, 1)',
          }),
          fill: new Fill({
            color: 'rgba(255, 105, 0, 1)',
          }),
        }),
      });

      const type = drawType as GeoJsonGeometryTypes;
      const source = map_feature_layer.getSource();
      if (source && type) {
        const draw = new Draw({
          type: type,
          source: source,
          style: drawStyle,
        });
        map.addInteraction(draw);

        draw.on('drawend', (drawEvent) => {
          const mapFeatures = map_features;
          const newFeature = JSON.parse(
            new GeoJSON({
              dataProjection: 'EPSG:4326',
              featureProjection: 'EPSG:3857',
            }).writeFeature(drawEvent.feature)
          );
          newFeature.properties = {
            title: `New ${type !== 'LineString' ? type : 'Line'}`,
            description: '',
            markerSize: 'medium',
            markerSymbol: '',
            markerColor: [255, 105, 0, 1],
            stroke: [255, 105, 0, 1],
            strokeWidth: 2,
            fill: [255, 105, 0, 0.5],
            visible: true,
          };
          mapFeatures[collectionIndex].featureCollection.features.push(
            newFeature
          );

          const projectId = this._project.currentProject?._id;
          if (projectId) {
            this._project
              .updateMapFeatures(projectId, mapFeatures)
              .subscribe((mapFeatures) => {
                map_features = mapFeatures;
                map?.removeInteraction(draw);
                resolve();
              });
          } else {
            resolve();
          }
        });
      }
    });
  }

  setGeoJson(
    map_features: MapFeatures[],
    map_feature_source: VectorSource,
    map_feature_layer: VectorLayer<VectorSource>,
    highlight?: string
  ) {
    if (map_features) {
      const geoJsonFormatter = new GeoJSON({
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857',
      });
      map_feature_source.clear();
      map_features.forEach((layer) => {
        const features = geoJsonFormatter.readFeatures(layer.featureCollection);
        if (layer.visible) {
          features.forEach((feature) => {
            if (feature.getProperties()['visible']) {
              feature.setId(feature.getProperties()['id']);
              feature.setStyle(this.generateGeojsonStyle(feature));
              map_feature_source.addFeature(feature);
            }
          });
        }
      });
      map_feature_layer.setSource(map_feature_source);
    }
  }
}
