<app-modal [(show)]="showModal" *ngIf="showModal" heading="Add User">
  <app-user-form
    (userAdded)="userAdded($event)"
    (cancel)="showModal = false"
  ></app-user-form>
</app-modal>
<div class="container">
  <div class="heading">
    <h2>Users</h2>
    <div>
      <button (click)="this.addUser()">New User</button>
    </div>
  </div>
  <div class="divider-line"></div>
  <app-user-list [users]="users"></app-user-list>
</div>
