import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-editor-window-pdf',
  templateUrl: './editor-window-pdf.component.html',
  styleUrls: ['./editor-window-pdf.component.scss'],
})
export class EditorWindowPdfComponent {
  files: File[] = [];
  @Input() file!: File;
  @Output() newCaptionEvent = new EventEmitter<string>();
  @Output() newFilesEvent = new EventEmitter<File[]>();
  @Input() caption: string = '';
  @Input() currentFiles: Array<string> = [];

  fileChanged(event: Event & { target: EventTarget | null }) {
    const target = event.target as HTMLInputElement;
    const fileInput = target.files;
    const inputItem = fileInput?.item(0);
    if (inputItem) {
      this.files.push(inputItem);
    }
    return this.newFilesEvent.emit(this.files);
  }

  onCaptionChange() {
    return this.newCaptionEvent.emit(this.caption);
  }
}
