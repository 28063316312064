<app-panorama
  *ngIf="project && mediaUuid"
  [panorama]="panorama"
  (panoramaSelected)="panoramaSelected($event)"
></app-panorama>
<div
  class="editor"
  [class]="{ 'full-height': showEditorWindow, hidden: !showEditorWindow }"
  *ngIf="userRole && userRole !== 'guest'"
>
  <app-editor-window
    (showingEditor)="showEditorWindow = $event"
    (markersAdded)="addNewMarkers($event)"
  ></app-editor-window>
</div>
