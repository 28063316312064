<div class="story-container">
  <div
    class="story-header"
    *ngIf="_stories.storyComponentMode.secondary !== 'view-active'"
  >
    <h2
      style="padding: 0.5em 0; cursor: pointer"
      (click)="_stories.setStoryViewMode('view-list')"
    >
      Stories
    </h2>
    <div
      class="flex-row"
      style="justify-content: space-between; width: 100%; gap: 2em"
    >
      <div
        class="flex-row"
        style="
          margin-left: auto;
          border-radius: 2em;
          background-color: #ddd;
          align-items: center;
          padding: 0.25em 0.5em;
          position: relative;
        "
      >
        <li
          class="tab"
          [ngClass]="
            _stories.storyComponentMode.primary === 'view' ? 'active-tab' : ''
          "
          (click)="switchTab('view')"
        >
          View
        </li>
        <li
          class="tab"
          [ngClass]="
            _stories.storyComponentMode.primary === 'edit' ? 'active-tab' : ''
          "
          (click)="switchTab('edit')"
        >
          Edit
        </li>
      </div>
    </div>
  </div>

  <app-story-view
    *ngIf="_stories.storyComponentMode.primary === 'view'"
    class="story-content"
    (togglePanel)="handlePanelClose()"
  ></app-story-view>
  <app-story-editor
    *ngIf="_stories.storyComponentMode.primary === 'edit'"
    class="story-content"
  ></app-story-editor>
</div>
