<app-modal
  heading="Edit {{ this.editingPanorama?.name }}"
  *ngIf="showEditPanoramaModal"
  [(show)]="showEditPanoramaModal"
>
  <form (ngSubmit)="editPanorama(this.editingPanorama?.uuid || '')">
    <div class="form-section">
      <div class="name-input">
        <label for="newname">Name</label>
        <input
          type="text"
          name="newname"
          id="newname"
          placeholder="Enter new name"
          [(ngModel)]="newName"
          maxlength="25"
        />
        <p class="error" *ngIf="newName.length === 25">Maximum 25 characters</p>
      </div>
      <div class="set-default">
        <input
          type="checkbox"
          name="set-default"
          id="set-default"
          class="checkbox"
          [(ngModel)]="setDefault"
        />
        <label for="set-default"
          >Set as the default panorama in this project?</label
        >
      </div>
      <div class="set-default">
        <input
          type="checkbox"
          name="set-default-view"
          id="set-default-view"
          class="checkbox"
          [(ngModel)]="setDefaultView"
        />
        <label for="set-default-view"
          >Set the current view and zoom as the default view and zoom?</label
        >
      </div>
    </div>
    <div class="actions">
      <button type="button" (click)="closeEditModal()">Cancel</button>
      <button type="submit">Save</button>
    </div>
  </form>
</app-modal>

<app-modal
  heading="Delete {{ this.editingPanorama?.name }}?"
  *ngIf="showDeletePanoramaModal"
  [(show)]="showDeletePanoramaModal"
>
  <div class="question">
    <p>
      Are you sure you want to delete
      <b>{{ this.editingPanorama?.name }}</b
      >?
    </p>
  </div>
  <div class="actions">
    <button type="button" (click)="cancelDelete()">Cancel</button>
    <button
      type="button"
      (click)="deletePanorama(this.editingPanorama?.uuid || '')"
    >
      Delete
    </button>
  </div>
</app-modal>

<div
  class="panorama-list"
  (mouseenter)="setShowList(true)"
  (mouseleave)="setShowList(false)"
>
  <div class="heading">
    <div>
      <h3>Media</h3>
    </div>
    <div>
      <div class="icons">
        <button class="gallery-button" (click)="goToGalleryView()">
          Media Gallery
        </button>
        <div
          class="fa-icon"
          (click)="toggleListPinned()"
          [ngClass]="{ pinned: panoramaListPinned }"
          tippy="Pin"
          placement="top"
          [delay]="[500, null]"
        >
          <fa-icon [icon]="faThumbTack"></fa-icon>
        </div>
      </div>
    </div>
  </div>
  <div [ngClass]="{ show: showPanoramalist, hide: !showPanoramalist }">
    <ul>
      <li *ngFor="let media of list; let i = index">
        <div class="list-item" (click)="currentMediaId = media.uuid!">
          <a
            [routerLink]="[media.type, media.uuid]"
            [queryParams]="{ project: true }"
            routerLinkActive="active"
            class="panorama-name"
            >{{ media.name }}</a
          >
          <div class="icons">
            <div
              class="fa-icon"
              (click)="openEditHorizonModal()"
              *ngIf="
                loggedIn &&
                userRole !== 'guest' &&
                media.uuid === currentMediaId &&
                media.type === 'panorama'
              "
              tippy="Edit Horizon"
              placement="bottom"
              [delay]="[500, null]"
            >
              <fa-icon [icon]="faGlobe"></fa-icon>
            </div>
            <div
              class="fa-icon"
              (click)="openEditPanoramaModal(media.uuid || '')"
              *ngIf="
                loggedIn &&
                userRole !== 'guest' &&
                media.uuid === currentMediaId
              "
              tippy="Edit"
              placement="bottom"
              [delay]="[500, null]"
            >
              <fa-icon [icon]="faPencil"></fa-icon>
            </div>
            <div
              class="fa-icon"
              (click)="openDeletePanoramaModal(media.uuid || '')"
              *ngIf="
                loggedIn &&
                userRole !== 'guest' &&
                media.uuid === currentMediaId
              "
              tippy="Delete"
              placement="bottom"
              [delay]="[500, null]"
            >
              <fa-icon [icon]="faTrash"></fa-icon>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
