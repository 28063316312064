export class Case {
  public archived?: boolean;
  public owner_id?: string;

  constructor(
    public name: string,
    public code: string,
    readonly _id?: string
  ) {}
  public created_at?: Date;
  public updated_at?: Date;
}
