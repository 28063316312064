import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';

@Component({
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.scss'],
})
export class PdfComponent implements OnChanges {
  @Input() url: string | Blob = '';
  @Input() page: number = 1;
  @Output() pageChange: EventEmitter<number> = new EventEmitter();
  @ViewChild('pdfviewer') pdfViewer?: PdfJsViewerComponent;

  ngOnChanges(changes:SimpleChanges) {
    if(changes['url'] && this.pdfViewer) {
        this.pdfViewer.pdfSrc = changes['url'].currentValue;
        this.pdfViewer.refresh();
      }
  }

  onPageChange(page: number) {
    this.pageChange.emit(page);
  }

  documentLoaded() {
    if (this.pdfViewer) {
      this.pdfViewer.page = this.page;
      console.log(this.pdfViewer.page, this.page, this.pdfViewer);
    }
  }
}
