<app-modal
  [(show)]="showAddProjectModal"
  heading="Add Project"
  *ngIf="showAddProjectModal"
>
  <app-project-form
    [caseId]="caseId"
    (projectAdded)="projectAdded($event)"
    (cancel)="showAddProjectModal = false"
  ></app-project-form>
</app-modal>

<div class="container">
  <div class="heading">
    <h2>{{ case?.name || 'Projects' }}</h2>
    <div>
      <button (click)="this.addProject()">New Project</button>
    </div>
    <div class="archive" *ngIf="!showingArchivedProjects">
      <button (click)="this.getArchivedProjects(caseId)">
        Show archived projects
      </button>
    </div>
    <div class="archive" *ngIf="showingArchivedProjects">
      <button (click)="this.getProjects(caseId)">Hide archived projects</button>
    </div>
  </div>
  <div class="divider-line"></div>
  <div>
    <app-project-list
      [projects]="projects"
      [showingArchivedProjects]="showingArchivedProjects"
    ></app-project-list>
  </div>
</div>
